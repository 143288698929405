import { PlayCircleIcon } from "@heroicons/react/20/solid"
import { Link } from "react-router-dom"
import { Card } from "~/ui-rtk/components/ui/common"

export type TLearningZoneCard = {
  title: string
  url: string
  contentType: "video" | "article"
  type: string
}

type TLearningZoneSectionCardProps = {
  card: TLearningZoneCard
}

export default function LearningZoneSectionCard({
  card,
}: TLearningZoneSectionCardProps) {
  return (
    <Link to={card.url} target="_blank" className="w-75">
      <Card
        rounded
        className="bg-basic-blue/20 border border-basic-blue w-75 h-70 flex items-center justify-center"
      >
        {card.contentType === "video" && (
          <PlayCircleIcon className="w-15 h-15 opacity-75" />
        )}
      </Card>
      <div className="mt-3">{card.title}</div>
    </Link>
  )
}
