import { renderSourceIcon } from "~/ui-rtk/components/ui/charts/TableVisualization/components/Cell/utils"
import {
  TMetric,
  type TSource as TTableVSource,
} from "~/ui-rtk/constants/table-visualization-types"
import { SOURCE_IDS } from "~/ui-rtk/constants/sources"
import {
  safeDivide,
  toCoef,
  toNumber,
  toCurrency,
} from "~/ui-rtk/utils/number-utils"
import { createStatsItem, getSource } from "./utils"
import { useCallback } from "react"
import { TSource } from "~/ui-rtk/hooks/sources"

export type TStatsTotalHeader =
  | "Source"
  | "Spend"
  | "Brand Value"
  | "Brand ROAS"
  | "DR Revenue"
  | "DR ROAS"
  | "Total Value"
  | "Total ROAS"

export type TStatsMetaHeader =
  | "Source"
  | "Spend"
  | "Likes/Reactions"
  | "Comments"
  | "Shares"
  | "Saves"
  | "DR Revenue"
  | "DR ROAS"

export type TStatsTiktokHeader =
  | "Likes/Reactions"
  | "Comments"
  | "Shares"
  | "Empty"

export type TStatsHeader = TStatsTotalHeader | TStatsMetaHeader

export type TStatsTotalItem = Record<TStatsTotalHeader, any>
export type TStatsMetaItem = Record<TStatsMetaHeader, any>
export type TStatsTiktokItem = Record<TStatsTiktokHeader, any>

export type TSourceId = keyof typeof SOURCE_IDS

export type TStatsItem = TStatsTotalItem | TStatsMetaItem

export const useStats = (metric: TMetric) => {
  const brandValueOverSpendKey = `brand_value_over_spend`
  const sourceName = metric.media_channel.toUpperCase()
  const purchases = +metric.purchases
  const brandValue = +(metric.brand_value ?? metric.brand_value_agg)
  const spendValue = +metric.spend

  const totalStats: Array<TStatsTotalItem> = []
  const metaStats: Array<TStatsMetaItem> = []
  const tiktokStats: Array<TStatsTiktokItem> = []

  const getMetricValue = (key: string, defaultValue = 0) =>
    metric[key] ?? defaultValue

  const createMetaStatsItem = (source: TSource, adType: string) => ({
    Source: renderSourceIcon({
      icon: source.icon,
      name: source.name,
    } as TTableVSource),
    Spend: toCurrency(+getMetricValue(`${adType}_spend`)),
    "Likes/Reactions": toNumber(
      +getMetricValue(
        source?.id === "FACEBOOK" ? `${adType}_reactions` : `${adType}_likes`,
      ),
    ),
    Comments: toNumber(+getMetricValue(`${adType}_comments`)),
    Shares: toNumber(+getMetricValue(`${adType}_shares`)),
    Saves: toNumber(+getMetricValue(`${adType}_saves`)),
    "DR Revenue": toCurrency(+getMetricValue(`${adType}_purchases`)),
    "DR ROAS": toCoef(
      safeDivide(
        +getMetricValue(`${adType}_purchases`),
        +getMetricValue(`${adType}_spend`),
      ),
    ),
  })

  const createTikTokStatItem = () => ({
    Empty: "",
    "Likes/Reactions": toNumber(+getMetricValue(`tiktok_ads_likes`)),
    Comments: toNumber(+getMetricValue(`tiktok_ads_comments`)),
    Shares: toNumber(+getMetricValue(`tiktok_ads_shares`)),
  })

  const handleTikTokStats = useCallback(() => {
    tiktokStats.push(createTikTokStatItem())
  }, [tiktokStats])

  const handleMetaStats = useCallback(() => {
    const facebookAdsItem = createMetaStatsItem(
      getSource(SOURCE_IDS.FACEBOOK),
      "facebook_ads",
    )

    const instagramAdsItem = createMetaStatsItem(
      getSource(SOURCE_IDS.INSTAGRAM),
      "instagram_ads",
    )

    metaStats.push(facebookAdsItem, instagramAdsItem)
  }, [metaStats])

  switch (sourceName) {
    case SOURCE_IDS.META: {
      totalStats.push(
        createStatsItem(
          getSource(SOURCE_IDS.META),
          spendValue,
          brandValue,
          purchases,
          getMetricValue(brandValueOverSpendKey),
        ),
      )
      handleMetaStats()
      break
    }
    case SOURCE_IDS.TIKTOK_ADS: {
      totalStats.push(
        createStatsItem(
          getSource(SOURCE_IDS.TIKTOK_ADS),
          spendValue,
          brandValue,
          purchases,
          getMetricValue(brandValueOverSpendKey),
        ),
      )
      handleTikTokStats()
      break
    }
  }

  return {
    totalStats,
    metaStats,
    tiktokStats,
  }
}
