import SourceItems from "../../features/connectors/ConnectorsTable/components/SourceItems/SourceItems"
import { useState } from "react"
import { Card, Dialog } from "../../ui/common"
import { H3 } from "../../ui/typography"
import useMobileView from "~/ui-rtk/hooks/mobile-view"
import { Button } from "../../ui/controls"
import { ConnectorGroupItem } from "~/ui-rtk/pages/Onboarding/ConnectSources/types"

const SESSION_STORAGE_KEY = "SHOW_RECONNECT_BANNER"

export default function ReconnectBanner({
  connectors,
}: {
  connectors: ConnectorGroupItem[]
}) {
  const [show, setShow] = useState(
    sessionStorage.getItem(SESSION_STORAGE_KEY) !== "false",
  )
  const isMobile = useMobileView()

  const handleLater = () => {
    setShow(false)
    sessionStorage.setItem(SESSION_STORAGE_KEY, "false")
  }

  if (connectors?.length <= 1 || isMobile) {
    return null
  }

  return (
    <Dialog
      isOpen={show}
      handleClose={() => setShow(false)}
      width={800}
      closeOnClickOutside={true}
    >
      <Card
        rounded
        className="border bg-background-dark-shade border-basic-blue"
      >
        <H3 className="mb-2">
          This view does not have the latest data. please reconnect the sources
          below and you will see the most recent data from all sources in 3 days
        </H3>
        <SourceItems connectors={connectors} />
        <Button
          className="button ring-0 w-full mt-3"
          variant={{ variant: "solid", color: "pink" }}
          onClick={handleLater}
        >
          Later
        </Button>
      </Card>
    </Dialog>
  )
}
