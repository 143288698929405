import { Tab } from "@headlessui/react"
import React, { ReactNode, useState } from "react"
import { Image } from "~/ui-rtk/components/ui/common/Image"
import { cn } from "~/ui-rtk/utils/tailwind-utils"

export type TTabsGroupOption = {
  label: string
  icon?: {
    src: string
    alt?: string
  }
  disabled?: boolean
}

type TTabsGroupProps = {
  options: TTabsGroupOption[]
  index?: number
  onChange: (value: number) => void
  children?: (option: TTabsGroupOption, idx: number) => ReactNode | null
}

export default function TabsGroup({
  options,
  index: indexProps,
  onChange: onChangeFromProps,
  children,
}: TTabsGroupProps) {
  const isControlled = typeof indexProps !== "undefined"

  const [internalIndex, setInternalIndex] = useState<number>(indexProps ?? 0)

  const activeIndex = isControlled ? indexProps : internalIndex

  const onChange = (e: number) => {
    if (onChangeFromProps) {
      onChangeFromProps(e)
    }

    if (!isControlled) {
      setInternalIndex(e)
    }
  }

  return (
    <div className="border-b ui-rtk-settings-tabs border-b-basic-blue">
      <Tab.Group selectedIndex={activeIndex} onChange={onChange}>
        <Tab.List>
          {options.map((tab, idx) => (
            <Tab
              disabled={tab.disabled}
              key={idx}
              className="focus-visible:outline-none"
            >
              <div
                className={cn(
                  "flex focus-visible:outline-none justify-between pt-3 pb-2.5 px-0 rounded-t-md border-b-4 hover:bg-basic-dark-blue hover:border-b-basic-blue",
                  {
                    "border-b.5 border-b-white font-bold": activeIndex === idx,
                    "border-b-transparent": activeIndex !== idx,
                    "ms-4": idx !== 0,
                    "me-4": options.length > idx + 1,
                  },
                )}
              >
                {children ? (
                  children(tab, idx)
                ) : (
                  <React.Fragment>
                    {tab.icon ? (
                      <Image
                        src={tab.icon.src}
                        alt={tab.icon.alt ?? tab.label}
                        classes={{
                          wrapper: "me-2",
                        }}
                      />
                    ) : null}
                    {tab.label}
                  </React.Fragment>
                )}
              </div>
            </Tab>
          ))}
        </Tab.List>
      </Tab.Group>
    </div>
  )
}
