import React, { useCallback, useRef, useState } from "react"
import { cn } from "~/ui-rtk/utils/tailwind-utils"
import "./caption-preview.scss"

type TCaptionCellProps = {
  text: string
  link?: string
  maxLength?: number
  alwaysShow?: boolean
  exact?: boolean
  align?: "right" | "left"
  fullCaptionClass?: string
  cellClass?: string
}

export const CaptionPreview: React.FC<TCaptionCellProps> = ({
  text,
  link,
  maxLength = 50,
  alwaysShow = false,
  exact = false,
  align = "right",
  fullCaptionClass = "",
  cellClass = "",
}) => {
  const [isFullCaption, setIsFullCaption] = useState(false)
  const hoverTimeout = useRef<NodeJS.Timeout | null>(null)

  const showFullCaption = () => {
    setIsFullCaption(true)
  }

  const wrapString = (str: string) =>
    exact ? <pre className="caption-preview">{str}</pre> : str

  const hideFullCaption = () => {
    if (hoverTimeout.current) {
      clearTimeout(hoverTimeout.current)
      hoverTimeout.current = null
    }
    setIsFullCaption(false)
  }

  const handleMouseEnter = () => {
    hoverTimeout.current = setTimeout(showFullCaption, 700)
  }

  const handleMouseLeave = () => {
    hideFullCaption()
  }

  const alignClass = align === "right" ? "right-0" : "left-0"

  const render = useCallback(() => {
    if (!alwaysShow && text?.length <= maxLength) {
      return wrapString(text)
    }

    return (
      <div
        className={cn("relative w-full", cellClass)}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {isFullCaption ? (
          <div
            className={`absolute flex flex-col bg-basic-blue rounded-lg z-50 top-1/2 py-3 px-4 gap-2 ${alignClass} ${fullCaptionClass}`}
          >
            <p className="max-h-75 overflow-y-auto">{wrapString(text)}</p>
            {link && (
              <a
                href={link}
                target="_blank"
                className="text-right text-brand underline"
              >
                Read more
              </a>
            )}
          </div>
        ) : null}
        <p className="w-full overflow-x-hidden text-ellipsis">
          {text && wrapString(text.slice(0, maxLength))}
          {text?.length > maxLength ? "..." : ""}
        </p>
      </div>
    )
  }, [text, link, maxLength, isFullCaption])

  return render()
}
