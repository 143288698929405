import { type Query as TCubeQuery } from "@cubejs-client/core"
import { useCubeQuery } from "~/ui-rtk/hooks/cube"
import { CUBE_QUERY_KEYS_MAP } from "../components/features/details/constants"

export type TCampaignAdSetAdDetailsData = {
  brandValue: number
  total: number
  purchases: number
  spend?: number
}
export type TCampaignAdSetAdDetailsItem = {
  current: TCampaignAdSetAdDetailsData
  all: TCampaignAdSetAdDetailsData
  totalItems: number
  date: string
}

export function prepareAllItemsDetails(metrics: any[]) {
  return metrics.reduce(
    (acc, metric) => ({
      brand_value:
        acc.brand_value + +(metric.brand_value ?? metric.brand_value_agg),
      spend: acc.spend + +metric.spend,
      purchases: acc.purchases + +metric.purchases,
    }),
    {
      brand_value: 0,
      spend: 0,
      purchases: 0,
    },
  )
}

export function useAllCampaignsAdSetsAdsDetails(allQuery: TCubeQuery) {
  const { resultSet: allSet, isLoading } = useCubeQuery(allQuery)

  return {
    isLoading,
    [CUBE_QUERY_KEYS_MAP.ALL]: allSet,
  }
}

export default function useCampaignAdSetAdDetails({
  [CUBE_QUERY_KEYS_MAP.ALL]: allQuery,
}: Record<
  (typeof CUBE_QUERY_KEYS_MAP)[keyof typeof CUBE_QUERY_KEYS_MAP],
  TCubeQuery
>) {
  const { resultSet: allSet, isLoading } = useCubeQuery(allQuery)

  return {
    isLoading,
    [CUBE_QUERY_KEYS_MAP.ALL]: allSet,
  }
}
