import { useCallback, useMemo } from "react"
import { useStableNavigate } from "~/ui-rtk/utils/StableNavigateContext"
import { toast } from "react-toastify"
import * as yup from "yup"
import { UpdateCompanyDto } from "~/ui-rtk/api/companyApi"
import { onboardingRoutes } from "~/ui-rtk/constants/routes"

import { hasErrorMessage } from "~/ui-rtk/utils/http-utils"
import { CompanySetupStatus } from "~/ui-rtk/api/types"
import useCompanyUpdater from "~/ui-rtk/hooks/company-updater"
import useCurrentCompany from "~/ui-rtk/hooks/current-company"

// ToDo: check if needed
export const validationSchema = yup.object().shape({
  primaryBrandKeyword: yup
    .string()
    .required("Primary brand keyword is required"),
  brandKeywords: yup
    .array()
    .of(yup.string())
    .min(1, "At least one keyword is required"),
  categoryKeywords: yup
    .array()
    .of(yup.string())
    .min(1, "At least one keyword is required"),
  competitorsKeywords: yup
    .array()
    .of(yup.string())
    .min(1, "At least one keyword is required"),
  competitorsInstagramHandlesKeywords: yup
    .array()
    .of(yup.string())
    .min(1, "At least one keyword is required"),
})

export type KeywordsFormValues = yup.InferType<typeof validationSchema>

export const useConnect = () => {
  const navigate = useStableNavigate()
  const currentCompany = useCurrentCompany()
  const { keywords } = (currentCompany?.metadata ?? {}) as { keywords: any }

  const { updateCompany, inProgress: isSubmitting } = useCompanyUpdater()

  const initialValues: KeywordsFormValues = useMemo(
    () => ({
      primaryBrandKeyword: keywords?.google?.searchAlias,
      brandKeywords: keywords?.google?.brands ?? [],
      competitorsKeywords: keywords?.google?.competitors ?? [],
      categoryKeywords: keywords?.google?.categories ?? [],
      competitorsInstagramHandlesKeywords:
        keywords?.instagram?.competitors ?? [],
    }),
    [currentCompany],
  )

  const submit = useCallback(async (_values: KeywordsFormValues) => {
    if (!currentCompany) {
      return
    }

    const updateCompanyDto: UpdateCompanyDto = {
      setupStatus: CompanySetupStatus.COMPLETE,
      metadata: {
        ...(currentCompany.metadata ?? {}),
        keywords: {
          ...(currentCompany?.metadata?.keywords ?? {}),
          google: {
            ...((currentCompany?.metadata as any)?.keywords?.google ?? {}),
            searchAlias: _values.primaryBrandKeyword,
            brands: _values.brandKeywords,
            competitors: _values.competitorsKeywords,
            categories: _values.categoryKeywords,
          },
          instagram: {
            ...((currentCompany?.metadata as any)?.keywords?.instagram ?? {}),
            competitors: _values.competitorsInstagramHandlesKeywords,
          },
        },
      },
    }

    try {
      const updated = await updateCompany(updateCompanyDto)
      if (updated) {
        toast.success("Keywords were updated", {
          toastId: "onboarding-keywords-update-success",
        })
        void navigate(onboardingRoutes.DATA_PENDING)
      }
    } catch (error) {
      if (hasErrorMessage(error)) {
        toast.error(error.data.message, {
          toastId: "onboarding-keywords-update-error",
        })
      }
    }
  }, [])

  return {
    isSubmitting,
    initialValues,
    validationSchema,
    updateKeywords: submit,
  }
}
