import { ElementType } from "react"
import { AggregationType, VISUALIZATION_TYPE } from "~/ui-rtk/constants/charts"
import { TBrandMediaOptimizationTable } from "~/ui-rtk/pages/BrandMedia/Optimization/connect"
import { TCubeFilterOptions } from "~/ui-rtk/shared/types/charts"
import Chart from "../Chart"
import SortedItems from "./SortedItems"
import { TDrawerConfig } from "~/ui-rtk/constants/drawer"
import { TBrandMediaSocialPostsTable } from "~/ui-rtk/pages/BrandMedia/OrganicSocialPosts/connect"

type TBestOrWorstItemsProps = {
  widget: TBrandMediaOptimizationTable | TBrandMediaSocialPostsTable
  cubeFilters?: TCubeFilterOptions
  isDesc?: boolean
  dateRange: [Date, Date]
  granularity: AggregationType
  sortByMetric: string
  itemComponent: ElementType
  onOpenDrawer?: (config: Partial<TDrawerConfig>) => void
  disableGranularity?: boolean
}

export default function SortedItemsLoader({
  widget,
  cubeFilters,
  isDesc = true,
  dateRange,
  granularity,
  sortByMetric,
  itemComponent,
  onOpenDrawer,
  disableGranularity,
}: TBestOrWorstItemsProps) {
  return (
    <div>
      <Chart
        chartId={widget.widget}
        className="h-75"
        widgetType={VISUALIZATION_TYPE.SortedItems}
        component={SortedItems}
        dateRange={dateRange}
        granularity={granularity}
        sortBy={sortByMetric}
        hideAggSelector={true}
        cubeFilters={cubeFilters}
        sortDesc={isDesc}
        manualSort={true}
        limit={5}
        enablePagination
        itemComponent={itemComponent}
        openDrawer={onOpenDrawer}
        disableGranularity={disableGranularity}
      />
    </div>
  )
}
