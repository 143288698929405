import { TDashboardItemsSet } from "~/ui-rtk/constants/dashboard"
import useSources from "~/ui-rtk/hooks/sources"
import { getContentWidgetIds } from "~/ui-rtk/utils/content"
import { useVisualizationsMap } from "~/ui-rtk/hooks/visualizations"
import { brandRoutes } from "~/ui-rtk/constants/routes"

const content = {
  id: "clrtjgjl7000kgl54esksbn0k",
  slug: brandRoutes.REVENUE.SHOPIFY,
  icon: null,
  title: "Shopify",
  parent: {
    slug: brandRoutes.REVENUE.ROOT,
    title: "Revenue",
  },
  metadata: {
    visibleToRoles: ["SUPER_ADMIN"],
  },
  order: 1.9,
  content: {
    items: [
      {
        className: "h-120",
        grid: 1,
        items: [
          {
            widget: "shopify_revenue_break_down_stacked_chart",
          },
        ],
      },
      {
        className: "h-10",
        grid: 1,
        items: [
          {
            widget: "SPACER",
          },
        ],
      },
      {
        className: "min-h-64",
        grid: 1,
        items: [
          {
            widget: "shopify_revenue_with_discount_table",
          },
        ],
      },
    ],
    title: "Shopify",
    periodPicker: {
      visible: true,
    },
    commonGranularityPicker: true,
  },
  items: [],
}

export default function useConnect() {
  const { sources, isLoading: isSourcesDataLoading } = useSources()

  const widgetIds = getContentWidgetIds(
    content.content.items as TDashboardItemsSet,
  )
  const { visualizations } = useVisualizationsMap(widgetIds)

  const props = {
    commonGranularityPicker: content.content.commonGranularityPicker,
  }

  return {
    content,
    sources,
    visualizations,
    props,
    isLoading: isSourcesDataLoading,
  }
}
