import React, { MouseEvent } from "react"
import { useLocation } from "react-router-dom"
import { useStableNavigate } from "~/ui-rtk/utils/StableNavigateContext"
import { SessionRole } from "~/api/dto/auth/session-role"

import { cn } from "~/ui-rtk/utils/tailwind-utils"

import type { NavItem } from "../types"
import usePostHogFeatureFlags from "~/ui-rtk/hooks/posthog-feaures"

export type TSidebarNestedItemProps = NavItem & {
  onClick?: () => void
  sessionRole: SessionRole
}

export const Subitem: React.FC<TSidebarNestedItemProps> = ({
  path,
  label,
  availableTo,
  onClick,
  sessionRole,
  featureFlag,
  isHidden,
}) => {
  const navigate = useStableNavigate()
  const location = useLocation()
  const isActive = location.pathname === path

  const handleClick = (event: MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()

    navigate(path)
    onClick?.()
  }

  if (featureFlag && !usePostHogFeatureFlags(featureFlag)) {
    return null
  }

  if (!availableTo?.includes(sessionRole)) {
    return null
  }

  if (isHidden && isHidden()) {
    return null
  }

  return (
    <li
      onClick={handleClick}
      className={cn(
        "px-3 py-2 hover:bg-gray/[.1] hover:text-platinum rounded cursor-pointer whitespace-nowrap",
        isActive ? "text-platinum bg-gray/[.1]" : "text-gray",
      )}
    >
      {label}
    </li>
  )
}
