import { useMemo, useState } from "react"
import ComposedChart from "../ComposedChart/ComposedChart"
import AreaChart from "../AreaChart"
import { AggregationType, UNKNOWN_DATA_KEY } from "~/ui-rtk/constants/charts"
import { BRAND_COLORS_MAP } from "~/ui-rtk/utils/chart-utils"
import _ from "lodash"
import { H3 } from "../../typography"
import TabsGroup from "../../controls/TabsGroup/TabsGroup"

const MOCK_GROUPS = ["mock1", "mock2", "mock3"]

type TMultyChartProps = {
  metrics: {
    metrics: any[]
    metricsConfig: any
  }
  groupKey: string
  state?: string
  groupStackKey?: string
  title?: string
  dataAggType?: AggregationType
  unit?: string
  pluralizeUnit?: boolean
  groupStackLabelFormat?: string
  compareMode?: boolean
  labelAggType?: "sum" | "avg" | "percent-total" | "percent-change"
  className?: string
  highlightKey?: string
}

const colsClassNameMap: Record<number, string> = {
  1: "grid-cols-1",
  2: "grid-cols-2",
  3: "grid-cols-3",
}

const isTabbed = true

export default function MultiChart({
  metrics: metricsProp,
  groupKey: groupKeyProp,
  state = "idle",
  groupStackKey,
  title,
  dataAggType,
  unit,
  pluralizeUnit,
  groupStackLabelFormat,
  labelAggType,
  compareMode = false,
  highlightKey,
  ...props
}: TMultyChartProps) {
  const { metrics, metricsConfig } = metricsProp
  const groups = useMemo(() => {
    if (metrics?.length === 0) {
      return MOCK_GROUPS
    }
    const groups: string[] = []
    for (const dataItem of metricsConfig[groupKeyProp]) {
      if (dataItem && !groups.includes(dataItem)) {
        groups.push(dataItem)
      }
    }
    return groups
  }, [metrics, state])

  const [tabIndex, setTabIndex] = useState<number>(0)

  const unknownStackGroup: string[] = groupStackKey
    ? (metricsConfig.unknown[groupStackKey] ?? [])
    : []

  const stackedChartIds: string[] | null =
    groupStackKey && state === "idle" && metricsConfig[groupStackKey]
      ? metricsConfig[groupStackKey].concat(unknownStackGroup)
      : null

  let subGroups: any[]

  if (groups.length < 4) {
    subGroups = [groups]
  } else {
    const chunkSize = 3
    subGroups = []
    for (let i = 0; i < groups.length; i += chunkSize) {
      const chunk = groups.slice(i, i + chunkSize)
      subGroups.push(chunk)
    }
  }

  if (isTabbed) {
    return (
      <div className="ui-rtk-chart-multichart">
        <div className="inline-flex items-center mb-5">
          <H3>{title}</H3>
        </div>
        <div className="ui-rtk-chart-multichart__tabs border-b border-basic-blue">
          <TabsGroup
            options={groups.map(group => ({
              label: _.startCase(_.lowerCase(group)),
            }))}
            onChange={setTabIndex}
            index={tabIndex}
          ></TabsGroup>
        </div>
        <div className="ui-rtk-chart-multichart__chart">
          {groupStackKey && stackedChartIds ? (
            <ComposedChart
              metrics={metrics}
              dataAggType={dataAggType}
              compareMode={compareMode}
              highlightKey={`${groups[tabIndex]}.${highlightKey}`}
              graphs={stackedChartIds.map(stackKey => {
                const isUnknown =
                  metricsConfig.unknown[groupStackKey].includes(stackKey)
                const dataKey = isUnknown
                  ? `${UNKNOWN_DATA_KEY}.${groups[tabIndex]}.${stackKey}`
                  : `${groups[tabIndex]}.${stackKey}`
                const label = groupStackLabelFormat
                  ? groupStackLabelFormat
                      .replace(/{stackKey}/, stackKey)
                      .replace(/{groupStackKey}/, groups[tabIndex])
                  : undefined
                return {
                  dataKey,
                  type: "area",
                  color: isUnknown ? BRAND_COLORS_MAP.UNKNOWN : undefined,
                  stackId: "default",
                  unit,
                  pluralizeUnit,
                  label: label && (isUnknown ? `Unknown: ${label}` : label),
                  labelAggType,
                  hideLabels: true,
                }
              })}
              {...props}
            />
          ) : (
            <AreaChart
              title={title?.replace(
                /{groupKey}/,
                metrics?.length ? groups[tabIndex] : "-",
              )}
              subTitle={metrics?.length ? groups[tabIndex] : "-"}
              metrics={metrics.map(metric => ({
                ...metric,
                value: metric[`${groupKeyProp}.${groups[tabIndex]}`],
              }))}
              dataAggType={dataAggType}
              labelAggType={
                labelAggType === "percent-total" ? "sum" : labelAggType
              }
              {...props}
            />
          )}
        </div>
      </div>
    )
  }

  return (
    <>
      {groups.length && subGroups.length
        ? subGroups.map((groups, idx) => (
            <div
              key={`subgroup-${idx}-${title}`}
              className={`grid grid-stack-item ${colsClassNameMap[subGroups[0].length]} gap-4 my-4 h-full`}
            >
              {groups.map((groupKey: string) => (
                <div key={`${groupKey}-${state}`} className="h-full">
                  {groupStackKey && stackedChartIds ? (
                    <ComposedChart
                      title={title?.replace(
                        /{groupKey}/,
                        metrics?.length ? groupKey : "-",
                      )}
                      subTitle={metrics?.length ? groupKey : "-"}
                      metrics={metrics}
                      dataAggType={dataAggType}
                      compareMode={compareMode}
                      highlightKey={`${groupKey}.${highlightKey}`}
                      graphs={stackedChartIds.map(stackKey => {
                        const isUnknown =
                          metricsConfig.unknown[groupStackKey].includes(
                            stackKey,
                          )
                        const dataKey = isUnknown
                          ? `${UNKNOWN_DATA_KEY}.${groupKey}.${stackKey}`
                          : `${groupKey}.${stackKey}`
                        const label = groupStackLabelFormat
                          ? groupStackLabelFormat
                              .replace(/{stackKey}/, stackKey)
                              .replace(/{groupStackKey}/, groupKey)
                          : undefined
                        return {
                          dataKey,
                          type: "area",
                          color: isUnknown
                            ? BRAND_COLORS_MAP.UNKNOWN
                            : undefined,
                          stackId: "default",
                          unit,
                          pluralizeUnit,
                          label:
                            label && (isUnknown ? `Unknown: ${label}` : label),
                          labelAggType,
                          hideLabels: true,
                        }
                      })}
                      {...props}
                    />
                  ) : (
                    <AreaChart
                      title={title?.replace(
                        /{groupKey}/,
                        metrics?.length ? groupKey : "-",
                      )}
                      subTitle={metrics?.length ? groupKey : "-"}
                      metrics={metrics.map(metric => ({
                        ...metric,
                        value: metric[`${groupKeyProp}.${groupKey}`],
                      }))}
                      dataAggType={dataAggType}
                      labelAggType={
                        labelAggType === "percent-total" ? "sum" : labelAggType
                      }
                      {...props}
                    />
                  )}
                </div>
              ))}
            </div>
          ))
        : null}
    </>
  )
}
