import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"

import "./index.scss"
import { brandRoutes } from "~/ui-rtk/constants/routes"
import { usePermissions } from "~/ui-rtk/pages/Main/usePermissions"
import TabsGroup from "~/ui-rtk/components/ui/controls/TabsGroup/TabsGroup"
import { useStableNavigate } from "~/ui-rtk/utils/StableNavigateContext"

export default function SettingsTabs() {
  const {
    isCompanyManagementGranted,
    isUserManagementGranted,
    isBrandConversionEventsManagementGranted,
  } = usePermissions()

  const location = useLocation()
  const navigate = useStableNavigate()
  const allSettingsLinks = [
    {
      label: "Profile",
      disabled: false,
      to: brandRoutes.SETTINGS.PROFILE,
    },
    {
      label: "Password",
      disabled: false,
      to: brandRoutes.SETTINGS.PASSWORD,
    },
    {
      label: "Users",
      disabled: false,
      userManagementPermissionRequired: true,
      to: brandRoutes.SETTINGS.USERS,
    },
    {
      label: "Company Settings",
      disabled: false,
      companyManagementPermissionRequired: true,
      to: brandRoutes.SETTINGS.COMPANY,
    },
    {
      label: "Brand Conversion Events",
      disabled: false,
      isBrandConversionEventsManagementPermissionRequired: true,
      to: brandRoutes.SETTINGS.BRAND_EVENTS,
    },
  ]
  const SettingsLinks = allSettingsLinks
    .filter(
      ({ userManagementPermissionRequired }) =>
        !userManagementPermissionRequired || isUserManagementGranted,
    )
    .filter(
      ({ companyManagementPermissionRequired }) =>
        !companyManagementPermissionRequired || isCompanyManagementGranted,
    )
    .filter(
      ({ isBrandConversionEventsManagementPermissionRequired }) =>
        !isBrandConversionEventsManagementPermissionRequired ||
        isBrandConversionEventsManagementGranted,
    )
  const findActiveIndex = () =>
    SettingsLinks.findIndex(({ to }) => location.pathname === to)
  const [index, setIndex] = useState(findActiveIndex())

  const handleChangeIdx = (idx: number) => {
    const link = SettingsLinks[idx]
    if (!link.disabled) {
      navigate(link.to)
    }
  }

  useEffect(() => {
    setIndex(findActiveIndex())
  }, [location])

  return (
    <div className="border-b ui-rtk-settings-tabs border-b-basic-blue">
      <TabsGroup
        options={SettingsLinks}
        onChange={handleChangeIdx}
        index={index}
      />
    </div>
  )
}
