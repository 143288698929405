import {
  TStatsTiktokHeader,
  TStatsTiktokItem,
  type TStatsMetaHeader,
  type TStatsMetaItem,
  type TStatsTotalHeader,
  type TStatsTotalItem,
} from "../hooks/stats"
import {
  renderStatsMetaItem,
  renderStatsTiktokItem,
  renderStatsTotalItem,
} from "../utils"

export default function StatsTable({
  totalStats,
  metaStats,
  tiktokStats,
}: {
  totalStats: TStatsTotalItem[]
  metaStats: TStatsMetaItem[]
  tiktokStats: TStatsTiktokItem[]
}) {
  return (
    <table className="w-full">
      <tbody className="text-3.5">
        {totalStats.map((statsItem: TStatsTotalItem, idx: number) => (
          <tr key={idx}>
            {Object.keys(statsItem).map(statsKey =>
              renderStatsTotalItem(statsItem, statsKey as TStatsTotalHeader),
            )}
          </tr>
        ))}
        <tr>
          <td colSpan={totalStats[0] ? Object.values(totalStats[0]).length : 0}>
            <hr className="text-basic-blue" />
          </td>
        </tr>
        {metaStats.map((statsItem: TStatsMetaItem, idx: number) => (
          <tr key={idx}>
            {Object.keys(statsItem).map(statsKey =>
              renderStatsMetaItem(statsItem, statsKey as TStatsMetaHeader),
            )}
          </tr>
        ))}
        {tiktokStats.map((statsItem: TStatsTiktokItem, idx: number) => (
          <tr key={idx}>
            {Object.keys(statsItem).map(statsKey =>
              renderStatsTiktokItem(statsItem, statsKey as TStatsTiktokHeader),
            )}
          </tr>
        ))}
      </tbody>
    </table>
  )
}
