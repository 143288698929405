import { Card } from "~/ui-rtk/components/ui/common"
import { TMetricCardProps } from "../types"
import { H4, H5 } from "~/ui-rtk/components/ui/typography"
import { renderSourceIcon } from "~/ui-rtk/components/ui/charts/TableVisualization/components/Cell/utils"
import useSources from "~/ui-rtk/hooks/sources"
import { useCallback } from "react"
import { TSource } from "~/ui-rtk/constants/table-visualization-types"
import { Image } from "~/ui-rtk/components/ui/common/Image"
import numeral from "numeral"

const prefixKey = "company_media_metrics_organic_posts"

export function OrganicPostCard(props: TMetricCardProps) {
  const { metric, openDrawer } = props
  const { sources } = useSources()
  const sourceName = metric[`${prefixKey}.media_channel`]?.toUpperCase()
  const postCaption = metric[`${prefixKey}.post_caption`]
  const postImage = metric[`${prefixKey}.post_preview`]
  const postId = metric[`${prefixKey}.post_id`]
  const brandValue =
    metric[`${prefixKey}.brand_value`] ?? metric[`${prefixKey}.brand_value_agg`]
  const source = sources.get(sourceName)

  const innerTitle =
    (postCaption?.length ?? 0) > 25
      ? `${postCaption.slice(0, 25)}...`
      : postCaption

  const handleOpenDrawer = useCallback(() => {
    if (openDrawer) {
      openDrawer({
        props: {
          metric,
        },
        clickSourceId: ".campaign-card *",
      })
    }
  }, [postId, metric])

  return (
    <Card
      rounded
      className="campaign-card bg-basic-dark-blue border border-basic-blue relative w-75 h-75"
    >
      <div className="absolute z-0 top-0 bottom-0 right-0 left-0">
        <Image
          src={postImage}
          alt={""}
          title={""}
          classes={{
            wrapper: "w-full h-full",
            image: "w-full h-full",
          }}
        />
      </div>
      <div className="absolute top-2.5 right-2.5">
        {renderSourceIcon({
          name: source?.name,
          icon: source?.icon,
        } as TSource)}
      </div>
      <div className="absolute bottom-0 right-0 left-0 p-2.5 bg-background-dark-shade/70 grid grid-cols-10">
        <div className="col-span-5">
          <H5 className="font-bold overflow-hidden text-ellipsis max-h-12.5 leading-5.5 break-words">
            {innerTitle}
          </H5>
          <div
            onClick={handleOpenDrawer}
            className="py-3 flex justify-start text-xs cursor-pointer"
          >
            <span className="bg-gradient-to-r from-pink-orange-gradient-start to-pink-orange-gradient-end text-transparent bg-clip-text">
              Read More ↗
            </span>
          </div>
        </div>
        <div className="col-span-5">
          <H4 className="text-end text-">Brand Value</H4>
          <Card
            rounded
            className="bg-transparent border-2 border-charts-blue px-1 py-1 mt-1 text-3xl text-center text-charts-blue"
          >
            ${numeral(brandValue).format("0.00a")}
          </Card>
        </div>
      </div>
    </Card>
  )
}
