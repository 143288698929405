import {
  DataSvg,
  PulseSvg,
  ValueSvg,
} from "~/ui-rtk/components/ui/svg/business"
import { HomeSvg, SettingsSvg } from "~/ui-rtk/components/ui/svg/essentials"

import { brandRoutes } from "~/ui-rtk/constants/routes"
import { SessionRole } from "~/api/dto/auth/session-role"

import type { TSidebarItemProps } from "./SidebarItem"
import { IS_HOME_PAGE_ENABLED } from "~/ui-rtk/pages/Home/HomePage"
import { POSTHOG_FEATURES } from "~/ui-rtk/constants/feature-flags"
import { CurrencyDollarIcon } from "@heroicons/react/24/outline"
import React from "react"
import { cn } from "~/ui-rtk/utils/tailwind-utils"

export const sidebarItems = ({
  isGAConnectorConnected,
  isShopifyEnabled,
  isMediaMentionsEnabled,
}: {
  isGAConnectorConnected: boolean
  isShopifyEnabled: boolean
  isMediaMentionsEnabled: boolean
}): Pick<
  TSidebarItemProps,
  "Icon" | "label" | "path" | "items" | "availableTo" | "isHidden"
>[] =>
  [
    {
      path: brandRoutes.HOME,
      label: "Home",
      Icon: HomeSvg,
      availableTo: IS_HOME_PAGE_ENABLED
        ? [
            SessionRole.SUPER_ADMIN,
            // SessionRole.ADMIN_WITH_ACCESS,
            // SessionRole.USER_WITH_ACCESS,
            // SessionRole.ADMIN,
            // SessionRole.USER,
          ]
        : [], // adjust once dashboard will be ready
    },
    {
      path: brandRoutes.BRAND_HEALTH.OVERVIEW,
      label: "Brand Health",
      availableTo: [
        SessionRole.SUPER_ADMIN,
        SessionRole.ADMIN_WITH_ACCESS,
        SessionRole.USER_WITH_ACCESS,
        SessionRole.ADMIN,
        SessionRole.USER,
      ],
      Icon: PulseSvg,
      items: [
        {
          path: brandRoutes.BRAND_HEALTH.OVERVIEW,
          label: "Overview",
          availableTo: [
            SessionRole.SUPER_ADMIN,
            SessionRole.ADMIN,
            SessionRole.USER,
            SessionRole.ADMIN_WITH_ACCESS,
            SessionRole.USER_WITH_ACCESS,
          ],
        },
        {
          path: brandRoutes.BRAND_HEALTH.MIND_SHARE,
          label: "Mind Share",
          availableTo: [
            SessionRole.SUPER_ADMIN,
            SessionRole.ADMIN,
            SessionRole.USER,
            SessionRole.ADMIN_WITH_ACCESS,
            SessionRole.USER_WITH_ACCESS,
          ],
        },
      ],
    },
    {
      path: brandRoutes.REVENUE.GOOGLE_ANALYTICS,
      label: "Revenue",
      availableTo: [
        SessionRole.SUPER_ADMIN,
        SessionRole.ADMIN,
        SessionRole.USER,
        SessionRole.ADMIN_WITH_ACCESS,
        SessionRole.USER_WITH_ACCESS,
      ],
      isHidden: () => !isGAConnectorConnected && !isShopifyEnabled,
      Icon: (props: React.ComponentProps<"svg">) =>
        React.createElement(CurrencyDollarIcon, {
          ...props,
          fill: undefined,
          className: cn(props.className, "size-6"),
        }),
      items: [
        {
          path: brandRoutes.REVENUE.GOOGLE_ANALYTICS,
          label: "Google Analytics",
          isHidden: () => !isGAConnectorConnected,
          availableTo: [
            SessionRole.SUPER_ADMIN,
            SessionRole.ADMIN,
            SessionRole.USER,
            SessionRole.ADMIN_WITH_ACCESS,
            SessionRole.USER_WITH_ACCESS,
          ],
        },
        {
          path: brandRoutes.REVENUE.SHOPIFY,
          label: "Shopify",
          isHidden: () => !isShopifyEnabled,
          availableTo: [
            SessionRole.SUPER_ADMIN,
            SessionRole.ADMIN,
            SessionRole.USER,
            SessionRole.ADMIN_WITH_ACCESS,
            SessionRole.USER_WITH_ACCESS,
          ],
        },
      ],
    },
    {
      path: brandRoutes.BRAND_VALUE.OVERVIEW,
      label: "Brand Value",
      availableTo: [
        SessionRole.SUPER_ADMIN,
        SessionRole.ADMIN,
        SessionRole.USER,
        SessionRole.ADMIN_WITH_ACCESS,
        SessionRole.USER_WITH_ACCESS,
      ],
      Icon: ValueSvg,
      items: [
        {
          path: brandRoutes.BRAND_VALUE.OVERVIEW,
          label: "Overview",
          availableTo: [
            SessionRole.SUPER_ADMIN,
            SessionRole.ADMIN,
            SessionRole.USER,
            SessionRole.ADMIN_WITH_ACCESS,
            SessionRole.USER_WITH_ACCESS,
          ],
        },
        /* https://linear.app/marathon-data/issue/MD-2250/hide-cohortvisualization-for-mvp */
        // {
        //   path: brandRoutes.BRAND_VALUE.COHORT_ANALYSIS,
        //   label: "Cohort Analysis",
        //   availableTo: [
        //     SessionRole.SUPER_ADMIN,
        //     SessionRole.ADMIN_WITH_ACCESS,
        //     SessionRole.USER_WITH_ACCESS,
        //   ],
        // },
      ],
    },
    {
      path: brandRoutes.BRAND_MEDIA.PAID_OPTIMIZATION,
      label: "Brand Media",
      availableTo: [
        SessionRole.SUPER_ADMIN,
        SessionRole.ADMIN,
        SessionRole.USER,
        SessionRole.ADMIN_WITH_ACCESS,
        SessionRole.USER_WITH_ACCESS,
      ],
      Icon: DataSvg,
      items: [
        {
          path: brandRoutes.BRAND_MEDIA.PAID_OPTIMIZATION,
          label: "Paid Media Optimization",
          availableTo: [
            SessionRole.SUPER_ADMIN,
            SessionRole.ADMIN,
            SessionRole.USER,
            SessionRole.ADMIN_WITH_ACCESS,
            SessionRole.USER_WITH_ACCESS,
          ],
        },
        {
          path: brandRoutes.BRAND_MEDIA.ORGANIC_SOCIAL_POSTS,
          label: "Organic Social Posts",
          availableTo: [
            SessionRole.SUPER_ADMIN,
            SessionRole.ADMIN,
            SessionRole.USER,
            SessionRole.ADMIN_WITH_ACCESS,
            SessionRole.USER_WITH_ACCESS,
          ],
        },
        {
          path: brandRoutes.BRAND_MEDIA.MEDIA_MENTIONS,
          label: "Media Mentions [Beta]",
          isHidden: () => !isMediaMentionsEnabled,
          availableTo: [
            SessionRole.SUPER_ADMIN,
            SessionRole.ADMIN,
            SessionRole.USER,
            SessionRole.ADMIN_WITH_ACCESS,
            SessionRole.USER_WITH_ACCESS,
          ],
        },
      ],
    },
  ] as const

export type TSetupSidebarItem = Pick<
  TSidebarItemProps,
  "Icon" | "label" | "path" | "items" | "availableTo" | "featureFlag"
>

export const setupSidebarItems: TSetupSidebarItem[] = [
  {
    path: brandRoutes.SETUP.ROOT,
    label: "Setup",
    availableTo: [
      SessionRole.SUPER_ADMIN,
      SessionRole.ADMIN,
      SessionRole.USER,
      SessionRole.ADMIN_WITH_ACCESS,
      SessionRole.USER_WITH_ACCESS,
    ],
    Icon: SettingsSvg,
    items: [
      {
        path: brandRoutes.SETUP.KEYWORDS,
        label: "Keywords + Competitors",
        availableTo: [
          SessionRole.SUPER_ADMIN,
          SessionRole.ADMIN,
          SessionRole.USER,
          SessionRole.ADMIN_WITH_ACCESS,
          SessionRole.USER_WITH_ACCESS,
        ],
      },
      {
        path: brandRoutes.SETUP.CONNECTORS,
        label: "Connectors",
        availableTo: [
          SessionRole.SUPER_ADMIN,
          SessionRole.ADMIN,
          SessionRole.USER,
          SessionRole.ADMIN_WITH_ACCESS,
          SessionRole.USER_WITH_ACCESS,
        ],
      },
      {
        path: brandRoutes.SETUP.REVENUE_SOURCE,
        label: "Revenue Source",
        featureFlag: POSTHOG_FEATURES.REVENUE_DEP_VAR_SETUP_PAGE,
        availableTo: [
          SessionRole.SUPER_ADMIN,
          SessionRole.ADMIN,
          SessionRole.USER,
          SessionRole.ADMIN_WITH_ACCESS,
          SessionRole.USER_WITH_ACCESS,
        ],
      },
    ],
  },
] as const
