import {
  TimeDimensionGranularity,
  type Query as TCubeQuery,
} from "@cubejs-client/core"
import {
  useCubeQuery as _useCubeQuery,
  type UseCubeQueryOptions,
} from "@cubejs-client/react"
import { useMemo } from "react"
import { MetricsMapping, TMetric } from "../constants/metrics-mapping"
import { type TCubeFilterOptions } from "../shared/types/charts"
import {
  addSorting,
  getPivotData,
  polyfillCubeQueryWithTimeDimension,
} from "../utils/cube"

import useDate from "~/ui-rtk/hooks/date"
import useCurrentCompany from "./current-company"
const dayjs = useDate()

type TWidget = keyof typeof MetricsMapping
const IN_MEMORY_SUMMARY_CACHE: Record<string, any> = {}
const IN_MEMORY_QUERY_CACHE: Record<string, TCubeQuery> = {}

export const useCubeQuery = _useCubeQuery

const generateKey = (
  companyId: string,
  widget: string,
  startDate: string,
  endDate: string,
  cubeFilters?: TCubeFilterOptions,
  cubeQueryParams?: Record<string, string>,
) =>
  `${companyId}.${widget}.${startDate}.${endDate}.f${JSON.stringify(cubeFilters)}.p${JSON.stringify(cubeQueryParams)}`

export function cubeQuery(customQuery: TCubeQuery, skip = false) {
  const queryOptions: UseCubeQueryOptions = useMemo(
    () => ({
      skip,
    }),
    [],
  )

  const {
    resultSet: result,
    isLoading,
    refetch,
  } = useCubeQuery(customQuery, queryOptions)

  return {
    isLoading,
    result,
    refetch,
  }
}

export function useSummary(
  widget: TWidget,
  dateRange: [Date, Date],
  cubeFilters?: TCubeFilterOptions,
  cubeQueryParams?: {
    granularity: TimeDimensionGranularity
  },
) {
  const currentCompany = useCurrentCompany()
  if (!currentCompany) {
    throw new Error("No current company")
  }

  const { id: companyId } = currentCompany

  const startDate = dayjs(dateRange[0]).format("YYYY-MM-DD")
  const endDate = dayjs(dateRange[1]).format("YYYY-MM-DD")
  const key = generateKey(
    companyId,
    widget,
    startDate,
    endDate,
    cubeFilters,
    cubeQueryParams as Record<string, string>,
  )

  const polyfillTimeDimensionPayload = {
    dateRange,
  }

  let query: TCubeQuery
  if (!IN_MEMORY_QUERY_CACHE[key]) {
    const { q } = MetricsMapping[widget]

    const cubeQ = typeof q === "function" ? q(cubeQueryParams ?? {}) : q

    query = polyfillCubeQueryWithTimeDimension(
      cubeQ as TMetric,
      polyfillTimeDimensionPayload,
    )

    query.limit = 1

    if (cubeFilters) {
      if (typeof cubeFilters === "object" && !Array.isArray(cubeFilters)) {
        const existingFilters = query.filters ?? []
        query.filters = [...existingFilters]

        if (cubeFilters?.and) {
          query.filters.push({ and: cubeFilters.and })
        }

        if (cubeFilters?.extraFilters) {
          query.filters.push(...cubeFilters.extraFilters)
        }
      }
    }

    IN_MEMORY_QUERY_CACHE[key] = query
  } else {
    query = IN_MEMORY_QUERY_CACHE[key]
  }

  const { result, isLoading } = cubeQuery(query, !!IN_MEMORY_SUMMARY_CACHE[key])

  if (result) {
    const res = getPivotData(result, query)
    const { postProcess } = MetricsMapping[widget]
    const processedResult = postProcess
      ? postProcess(res, cubeQueryParams ?? {})[0]
      : res
    IN_MEMORY_SUMMARY_CACHE[key] = processedResult
  }

  return {
    isLoading: Boolean(isLoading),
    summary: IN_MEMORY_SUMMARY_CACHE[key]
      ? IN_MEMORY_SUMMARY_CACHE[key][0]
      : null,
  }
}

export function useSummaries({
  widget,
  cubeFilters,
  dateRange,
  compareRange,
  cubeQueryParams,
}: {
  widget: TWidget
  dateRange: [Date, Date]
  compareRange?: [Date, Date]
  cubeFilters?: TCubeFilterOptions
  cubeQueryParams?: {
    granularity: TimeDimensionGranularity
  }
}) {
  const { summary: summaryData, isLoading } =
    widget && dateRange
      ? useSummary(widget, dateRange, cubeFilters, cubeQueryParams)
      : { summary: null, isLoading: false }

  const { summary: compareSummaryData, isLoading: isLoadingCompare } =
    compareRange && widget
      ? useSummary(widget, compareRange, cubeFilters, cubeQueryParams)
      : { summary: null, isLoading: false }

  return {
    summaryData,
    compareSummaryData,
    isLoading: isLoading || isLoadingCompare,
  }
}

const defaultMetric = "company_last_updated_date.general_max_date"

export type TLastUpdateDateProps = {
  dateMetric: string
  filterMetrics?: string[]
}

export function useLastUpdateDate(
  { dateMetric, filterMetrics }: TLastUpdateDateProps = {
    dateMetric: defaultMetric,
  },
) {
  const query = addSorting(
    {
      limit: 2,
      dimensions: [dateMetric],
      filters: filterMetrics
        ? [
            {
              or: filterMetrics.map((filter: string) => ({
                member: filter,
                operator: "set",
              })),
            },
          ]
        : undefined,
    },
    [
      {
        id: dateMetric,
        desc: true,
      },
    ],
  )
  const { result, isLoading, refetch } = cubeQuery(query)
  let lastDate
  if (result) {
    const parsedDate = getPivotData(result, query)
    lastDate = parsedDate?.[1]?.[dateMetric] ?? parsedDate?.[0]?.[dateMetric]
  }

  return {
    isLoading,
    lastDate: lastDate
      ? dayjs(lastDate as string).format("MMM D, YYYY")
      : undefined,
    refetch,
  }
}
