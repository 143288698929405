import React, { useMemo } from "react"
import { Cell, flexRender } from "@tanstack/react-table"
import { useSortable } from "@dnd-kit/sortable"

import { TMetric } from "~/ui-rtk/constants/table-visualization-types"
import { cn } from "~/ui-rtk/utils/tailwind-utils"
import { getCommonPinningOrderingStyles } from "../../utils"

type TBodyCellProps = {
  cell: Cell<TMetric, unknown>
  className?: string
  demoMode?: boolean
}

export const BodyCell: React.FC<TBodyCellProps> = ({
  cell,
  className,
  demoMode,
}) => {
  const { isDragging, setNodeRef, transform } = useSortable({
    id: cell.column.id,
  })

  const shouldBlur =
    cell.column.id.includes("name") ||
    cell.column.id.includes("caption") ||
    cell.column.id.includes("preview")

  const classNameWithAction = cn(
    "px-5 py-4 text-ellipsis hover:z-40",
    demoMode && shouldBlur && "blur-md",
    className,
    {
      "z-10": isDragging || cell.column.getIsPinned(),
    },
  )

  const tdStyles = useMemo(
    () => ({
      maxWidth: `${cell.column.getSize() ? `${cell.column.getSize()}px` : "unset"}`,
      minWidth: `${cell.column.getSize() ? `${cell.column.getSize()}px` : "unset"}`,
      ...getCommonPinningOrderingStyles(cell.column, isDragging, transform),
    }),
    [cell, isDragging, transform],
  )

  return (
    <td className={classNameWithAction} style={tdStyles} ref={setNodeRef}>
      {flexRender(cell.column.columnDef.cell, cell.getContext())}
    </td>
  )
}
