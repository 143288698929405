import { useMemo } from "react"
import { type Query as TCubeQuery, ResultSet } from "@cubejs-client/core"

import { CUBE_QUERY_KEYS_MAP } from "../../constants"
import useCampaignAdSetAdDetails from "~/ui-rtk/hooks/campaign-adset-ad-details"
import { getPivotData } from "~/ui-rtk/utils/cube"
import { MetricsMapping } from "~/ui-rtk/constants/metrics-mapping"
import useCurrentCompany from "~/ui-rtk/hooks/current-company"

const prefixKey = "company_media_metrics_organic_posts"

const requests: Record<string, TCubeQuery> = {}
const results: Record<string, any> = {}

function prepareAllItemsDetails(metrics: any[], prefix = prefixKey) {
  const brandValueKey = `${prefix}.brand_value`
  const brandValueAggKey = `${prefix}.brand_value_agg`

  const brandValue = metrics.reduce(
    (acc, metric) =>
      acc + (metric[brandValueKey] ?? metric[brandValueAggKey] ?? 0),
    0,
  )
  return {
    brandValue,
    total: metrics.length,
  }
}

export const usePostQubeQuery = (
  postId: string,
  cube = "brand_media_organic_social_posts_table",
  prefix = prefixKey,
) => {
  const currentCompany = useCurrentCompany()
  const currentCompanyId = currentCompany?.id ?? "-company-not-set-"
  const cacheKey = `${currentCompanyId}.${postId}`
  const queryAllPostsKey = `${cacheKey}.all`

  const baseQuery = {
    ...MetricsMapping[cube].q,
  }

  const postQuery = useMemo(
    () =>
      ({
        ...baseQuery,
      }) as TCubeQuery,
    [baseQuery],
  )

  const allPostsQuery = useMemo(() => {
    if (!requests[queryAllPostsKey]) {
      requests[queryAllPostsKey] = {
        ...postQuery,
      }
    }
    return requests[queryAllPostsKey]
  }, [queryAllPostsKey])

  const { [CUBE_QUERY_KEYS_MAP.ALL]: allPostsSet, isLoading } =
    useCampaignAdSetAdDetails({
      [CUBE_QUERY_KEYS_MAP.ALL]: allPostsQuery,
    })

  if (!results[cacheKey]) {
    results[cacheKey] = {}
  }

  if (allPostsSet) {
    const pivotData = getPivotData(allPostsSet as ResultSet<any>, allPostsQuery)
    results[cacheKey].allPostsDetails = prepareAllItemsDetails(
      pivotData,
      prefix,
    )
  }

  const { allPostsDetails, valueOverTimeDetails } = results[cacheKey]

  return { allPostsDetails, valueOverTimeDetails, isLoading }
}
