import { UpdateCompanyBrandEventDto } from "~/api/dto/company-brand-event/update-company-brand-event.dto"
import { useCallback, useEffect, useMemo, useState } from "react"
import { toast } from "react-toastify"
import { useBrandEventsControllerGetCompanyConnectorsQuery } from "~/ui-rtk/api/brandEventApi"
import {
  useCompanyBrandEventsControllerGetCompanyBrandEventsQuery,
  useCompanyBrandEventsControllerUpsertCompanyBrandEventsMutation,
} from "~/ui-rtk/api/companyBrandEventApi"
import { isUnauthorizedError } from "~/ui-rtk/utils/http-utils"
import { useAuthControllerLogoutMutation } from "~/ui-rtk/api/authApi"
import { useStableNavigate } from "~/ui-rtk/utils/StableNavigateContext"
import useCurrentCompany from "~/ui-rtk/hooks/current-company"

export function useCodeBlock(companyId: string) {
  // PLZ Keep formatting for formatting's sake!
  const formattedCodeBlock = useMemo(
    () => `<script
  id="marathon-data-pixel-script"
  async
  src="https://pixel.marathondataco.com/pixel.js?companyId=${companyId}"
></script>`,
    [companyId],
  )

  const codeBlock = `<script id="marathon-data-pixel-script" async src="https://pixel.marathondataco.com/pixel.js?companyId=${companyId}"></script>`
  return {
    codeBlock,
    formattedCodeBlock,
  }
}

export function useConnect() {
  const [companyIdSet, setCompanyIdSet] = useState<string | undefined>()
  const currentCompany = useCurrentCompany()
  const [logout] = useAuthControllerLogoutMutation()
  const navigate = useStableNavigate()

  const { data: allBrandEvents, isLoading: isBeLoading } =
    useBrandEventsControllerGetCompanyConnectorsQuery()
  const {
    data: companyBrandEvents,
    isLoading,
    refetch,
  } = useCompanyBrandEventsControllerGetCompanyBrandEventsQuery()
  const [updateCompanyBrandEvents] =
    useCompanyBrandEventsControllerUpsertCompanyBrandEventsMutation()

  useEffect(() => {
    if (companyIdSet && companyIdSet !== currentCompany?.id) {
      void refetch()
    }
    setCompanyIdSet(currentCompany?.id)
  }, [currentCompany?.id])

  const initialValues = useMemo(
    () => ({
      events: allBrandEvents?.map(brandEvent => {
        const companyBrandEvent = companyBrandEvents?.find(
          event => event.eventId === brandEvent.id,
        )
        if (companyBrandEvent) {
          return {
            id: companyBrandEvent.id,
            eventId: companyBrandEvent.eventId,
            isActive: companyBrandEvent.isActive,
          }
        }
        return {
          id: `${currentCompany?.id}-${brandEvent.id}`,
          eventId: brandEvent.id,
          isActive: false,
        }
      }),
    }),
    [currentCompany, allBrandEvents, companyBrandEvents],
  )

  const updateSettings = useCallback(
    async (values: UpdateCompanyBrandEventDto[]) => {
      if (!currentCompany?.id) {
        return
      }

      try {
        await updateCompanyBrandEvents({
          body: values,
        }).unwrap()
        toast.success("Brand Events Settings were updated")
        await refetch()
      } catch (error) {
        if (isUnauthorizedError(error)) {
          await logout()
          navigate("/")
          return
        }
        toast.error("Could not update Brand Events Settings")
      }
    },
    [currentCompany],
  )

  return {
    allBrandEvents,
    initialValues,
    companyBrandEvents,
    isLoading: isLoading || isBeLoading,
    updateSettings,
  }
}
