import { POSTHOG_FEATURES } from "../constants/feature-flags"
import useCurrentCompanyMetadata from "./current-company-metadata"
import usePostHogFeatureFlags from "./posthog-feaures"

export default function useGaMultiPropertyAccess() {
  return true || usePostHogFeatureFlags(POSTHOG_FEATURES.GA_MULTI_PROPERTY)
}

export function useGaMultiPropertyEnabled() {
  const metadata = useCurrentCompanyMetadata()
  const isGaMultiPropertyEnabled = Boolean(
    metadata?.settings?.flags?.ga_multi_property,
  )
  // ToDo: add metadata prop check
  return useGaMultiPropertyAccess() && isGaMultiPropertyEnabled
}
