import { FC, useMemo } from "react"
import { useStableNavigate } from "~/ui-rtk/utils/StableNavigateContext"

import P from "~/ui-rtk/components/ui/typography/P"
import { ActionsMenu, Popover } from "~/ui-rtk/components/ui/common"
import { LogoutSvg } from "~/ui-rtk/components/ui/svg/arrow"
import { PersonSvg, SettingsSvg } from "~/ui-rtk/components/ui/svg/essentials"

import { useAppSelector } from "~/ui-rtk/app/hooks"
import { selectUser } from "~/ui-rtk/app/selectors/user.selector"

import { TAction } from "~/ui-rtk/components/ui/common/ActionsMenu"
import { Button } from "~/ui-rtk/components/ui/controls"
import { useAuthControllerLogoutMutation } from "~/ui-rtk/api/authApi"

const actionIds = {
  logout: "logout",
  settings: "settings",
} as const

export type MenuActionKey = keyof typeof actionIds

const actions: TAction[] = [
  {
    Icon: SettingsSvg,
    label: "Settings",
    actionId: actionIds.settings,
  },
  {
    Icon: LogoutSvg,
    label: "Logout",
    actionId: actionIds.logout,
  },
]

type TProfileMenuProps = {
  config?: {
    disabledActions: Array<MenuActionKey>
  }
}

export const ProfileMenu: FC<TProfileMenuProps> = ({ config }) => {
  const navigate = useStableNavigate()
  const { name } = useAppSelector(selectUser)
  const [logout] = useAuthControllerLogoutMutation()

  const handleLogout = (callback: () => void) => {
    void logout().finally(callback)
  }

  const menuActions = useMemo(() => {
    if (!config) {
      return actions
    }

    return actions.filter(
      ({ actionId }) =>
        !config.disabledActions.includes(actionId as MenuActionKey),
    )
  }, [config])

  return (
    <div className="relative">
      <Popover
        relative
        handler={() => (
          <Button
            type="button"
            className="py-2 rounded-md"
            variant={{ variant: "solid", color: "blue" }}
          >
            <PersonSvg size={20} fill="background.light" />
            <P className="font-bold text-3 text-white">{name}</P>
          </Button>
        )}
      >
        {closePopover => (
          <ActionsMenu
            classes={{ list: "mt-2.5" }}
            actions={menuActions}
            handleAction={actionId => {
              if (actionId === actionIds.logout) {
                handleLogout(closePopover)
                return
              }
              navigate(`/${actionId}`)
              closePopover()
            }}
          />
        )}
      </Popover>
    </div>
  )
}
