import { LocalStorageKeys, useLocalStorage } from "../utils/storage"
import { useLocation } from "react-router-dom"
import { brandRoutes } from "../constants/routes"
import { useState } from "react"

const isMoneyWarningDialogEnabled = false

export default function useMoneyWarnDialog() {
  const localStorageKey = LocalStorageKeys.MONEY_WARN_DIALOG_SHOWN
  const currentLocation = useLocation()
  const [dialogShown, setDialogShown] = useLocalStorage<number>(
    localStorageKey,
    0,
  )
  const [, rerender] = useState(0)
  const routes = [brandRoutes.BRAND_VALUE.ROOT, brandRoutes.BRAND_MEDIA.ROOT]

  const hideDialog = () => {
    setDialogShown(1)
    rerender(1)
  }

  return [
    isMoneyWarningDialogEnabled &&
      !dialogShown &&
      routes.some(route => currentLocation.pathname.startsWith(route)),
    hideDialog,
  ]
}
