import { CONNECTORS_IDS } from "../constants/sources"
import useCurrentCompanyMetadata from "./current-company-metadata"
import { useGaMultiPropertyEnabled } from "./ga-multi-property-access"

export const GA_BASELINE_REVENUE_METRIC = "ga_baseline_revenue"
export const GA_MP_BASELINE_REVENUE_METRIC =
  "ga_multi_property_baseline_revenue"
export const SHOPIFY_BASELINE_REVENUE_METRIC = "shopify_baseline_revenue"

export const DEFAULT_BASELINE_REVENUE_METRIC = GA_BASELINE_REVENUE_METRIC

export default function useBaselineRevenueSourceMetric() {
  const companyMetdata = useCurrentCompanyMetadata()
  const isGAMultiPropertyEnabled = useGaMultiPropertyEnabled()

  const { revenueSource } = (companyMetdata ?? {}) as {
    revenueSource?: string
  }

  if (revenueSource === CONNECTORS_IDS.SHOPIFY) {
    return SHOPIFY_BASELINE_REVENUE_METRIC
  }
  if (revenueSource === CONNECTORS_IDS.GOOGLE_ANALYTICS_4) {
    if (isGAMultiPropertyEnabled) {
      return GA_MP_BASELINE_REVENUE_METRIC
    }
    return GA_BASELINE_REVENUE_METRIC
  }

  return DEFAULT_BASELINE_REVENUE_METRIC
}
