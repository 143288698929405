import WithLoader from "~/ui-rtk/components/layout/WithLoader/WithLoader"
import { BrandMediaSocialPostsDashboard } from "~/ui-rtk/components/features/dashboards"

import useConnect from "./connect"
import useReconnectSourcesBanner from "~/ui-rtk/hooks/reconnect-sources-banner"
import { useEffect } from "react"
import ReconnectBanner from "~/ui-rtk/components/layout/ReconnectBanner/ReconnectBanner"

export default function PageBrandMediaMediaMentions() {
  const { content, isLoading, requiredSources } = useConnect()

  const { connectorsToReconnect, setRequiredSources } =
    useReconnectSourcesBanner()

  useEffect(() => {
    setRequiredSources(requiredSources)
  }, [requiredSources])

  return (
    <WithLoader isLoading={isLoading}>
      <ReconnectBanner connectors={connectorsToReconnect} />
      <BrandMediaSocialPostsDashboard
        slug={content.slug}
        items={content.content.items}
        title={content.title}
        parent={content.parent}
        lastDateChipProps={content.content.lastDateChipProps}
      />
    </WithLoader>
  )
}
