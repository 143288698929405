import { useCallback } from "react"
import { useConnectorControllerGetAllConnectorsQuery } from "../api/connectorApi"
import { useCompanyConnectorControllerCreateCompanyConnectorMutation } from "../api/companyConnectorApi"

export default function useConnectors() {
  const {
    data: connectors = [],
    isError: isGetAllConnectorsError,
    isLoading: isGetAllConnectorsLoading,
  } = useConnectorControllerGetAllConnectorsQuery()

  const getConnectorById = useCallback(
    (id: string) => {
      const connector = connectors.find(c => c.id === id)
      return connector
    },
    [connectors],
  )

  const [connectAsync] =
    useCompanyConnectorControllerCreateCompanyConnectorMutation()

  const connect = async (service: string) => {
    try {
      const { uri, companyConnectorId } = await connectAsync({
        createCompanyConnectorDto: {
          service,
        },
      }).unwrap()

      return { uri, companyConnectorId }
    } catch {
      throw new Error("Failed to connect to the service.")
    }
  }

  return {
    connectors,
    isGetAllConnectorsError,
    isGetAllConnectorsLoading,
    connect,
    getConnectorById,
  }
}
