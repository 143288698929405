import { Navigate } from "react-router-dom"
import { PropsWithChildren, useCallback, useMemo } from "react"

import { Card, Loader, Logo } from "~/ui-rtk/components/ui/common"
import { Button } from "~/ui-rtk/components/ui/controls"
import { CheckMarkSvg } from "~/ui-rtk/components/ui/svg/essentials"
import { H1, H2, H5 } from "~/ui-rtk/components/ui/typography"
import { Footer } from "~/ui-rtk/components/layout"
import { BrandValueCard, OrganicMediaCard, PaidMediaCard } from "./components"

import useAccess from "~/ui-rtk/hooks/access"
import useConnect from "./connect"

import { cn } from "~/ui-rtk/utils/tailwind-utils"
import { brandRoutes } from "~/ui-rtk/constants/routes"

import type { SessionDto } from "~/ui-rtk/api/types"
import useCurrentCompany from "~/ui-rtk/hooks/current-company"

const FreeTrialProductId =
  import.meta.env.VITE_ENV === "production"
    ? "prod_QZUQ3yt1TcSHu4"
    : "prod_PNhCdhaJFHsEZd"

type TSubscribeGuardProps = {
  session: SessionDto
}

export function SubscribeGuard({
  children,
}: PropsWithChildren<TSubscribeGuardProps>) {
  const { hasAccess: isAccessGranted, isBillingEnabled } = useAccess()

  if (isAccessGranted) {
    return <Navigate to="/" />
  }

  if (!isBillingEnabled) {
    return <Navigate to={brandRoutes.SETUP.CONNECTORS} />
  }

  return children
}

export default function Checkout() {
  const {
    createCheckoutSession,
    isSubmitting,
    price,
    isLoading,
    organicMedia,
    isLoadingOrganicMedia,
    paidMedia,
    isLoadingPaidMedia,
    brandValue,
    isLoadingBrandValue,
  } = useConnect()
  const currentCompany = useCurrentCompany()

  const handleCreateCheckout = useCallback(async () => {
    await createCheckoutSession({
      productIds: [FreeTrialProductId],
    })
  }, [createCheckoutSession])

  const planItems = useMemo(
    () => [
      "No setup fee;",
      'No complex pricing hidden behind a "Contact Us" button;',
      "1:1 product onboarding with our founders;",
      'No annual contract. No protracted negotiations with "legal". Our product earns your business every month',
      "1:1 slack channel with our founders for early partners.",
    ],
    [],
  )

  if (!currentCompany?.metadata?.isBillingEnabled) {
    return <Navigate to="/" />
  }

  const renderLogo = () => (
    <div className="items-center flex justify-start gap-2">
      <Logo size={25} />
      <h4 className="text-lg font-bold">marathon data</h4>
    </div>
  )

  return (
    <div className="mx-auto px-4 md:px-10 lg:px-21">
      <Card
        rounded
        className="mx-auto max-w-150 px-4 py-4 grid grid-cols-2 bg-basic-dark-blue border border-basic-blue justify-between items-center"
      >
        {renderLogo()}
        <div className="items-center flex justify-end">
          <Button
            variant={{
              variant: "solid",
              color: "pink",
            }}
            size="sm"
            isLoading={isSubmitting}
            onClick={handleCreateCheckout}
          >
            Start Free Trial
          </Button>
        </div>
      </Card>
      <H1 className="mx-auto mt-10.5 max-w-200 font-semibold text-center text-6xl leading-15 md:leading-18 md:text-7xl">
        Unlock the power of <br className="xs:hidden" /> your data with a free
        trial
      </H1>
      <div className="mx-auto max-w-150 flex flex-col gap-4 items-center mt-8 text-3xl leading-9.5">
        <div className="h-24 border-2 border-basic-blue w-0"></div>
        <div className="min-h-24 text-center">
          Your data is ready,{" "}
          <span className="opacity-30">
            and we've built a tailored model just for you
          </span>
        </div>
        <div className="h-24 border-2 border-basic-blue w-0"></div>
        <div className="min-h-24 text-center opacity-30">
          Now it’s time to take the next step and convert all these insights
          into action
        </div>
      </div>
      <div className="mx-auto mt-45 max-w-150">
        <H1 className="font-semibold text-center text-5x leading-16">
          One plan.
        </H1>
      </div>
      <div className="bg-free-trial bg-top-50 bg-cover bg-no-repeat mt-8 pb-33">
        <Card
          rounded
          className="mx-auto max-w-100 bg-gradient-to-b from-brand via-yellow-info to-brand p-[1px] animate-shimmer"
        >
          <Card rounded className="bg-basic-dark-blue px-5 py-5">
            <H5 className="text-brand text-center uppercase text-base">
              Designed for you
            </H5>
            {isLoading ? (
              <div className="w-full mt-8 text-center">
                <Loader className="mx-auto" size={68} />
              </div>
            ) : (
              <H2 className="text-xl mt-8 text-center">
                <span className="text-3xl font-semibold">$</span>
                <span className="text-5xl font-semibold">
                  {price ? price / 100 : 0}
                </span>
                /mo
              </H2>
            )}
            <table className="table table-xs mt-6.5 text-start">
              <tbody>
                {planItems.map((item, idx) => (
                  <tr key={idx}>
                    <td className="w-5 align-top ps-0">
                      <CheckMarkSvg fill="japanese-laurel" size={20} />
                    </td>
                    <td className="text-sm pe-0 leading-4">{item}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Button
              className="mt-8 w-full"
              size="lg"
              variant={{
                variant: "solid",
                color: "white",
              }}
              isLoading={isSubmitting}
              onClick={handleCreateCheckout}
            >
              I'm ready to start
            </Button>
          </Card>
        </Card>
      </div>
      <div className="mx-auto max-w-100 text-center">
        <H5 className="text-brand text-center uppercase text-base">
          Designed for you
        </H5>
        <H1 className="mt-2 text-3xl md:text-4xl font-semibold leading-14">
          Your Brand Value in the last 12 months
        </H1>
        <Button
          variant={{
            variant: "solid",
            color: "pink",
          }}
          className="mx-auto mt-8"
          isLoading={isSubmitting}
          onClick={handleCreateCheckout}
        >
          Unlock your Data Model now
        </Button>
      </div>
      <div
        className={cn(
          "mt-10 w-full flex flex-col sm:flex-row flex-wrap md:flex-nowrap gap-4 lg:gap-6 mx-auto justify-center items-stretch max-w-full",
          "max-w-96 sm:max-w-96 md:max-w-200 lg:max-w-256 xl:max-w-312",
        )}
      >
        <OrganicMediaCard
          data={organicMedia}
          isLoading={isLoadingOrganicMedia}
          onCheckout={handleCreateCheckout}
        />
        <PaidMediaCard
          data={paidMedia}
          isLoading={isLoadingPaidMedia}
          onCheckout={handleCreateCheckout}
        />
        <BrandValueCard
          data={brandValue}
          isLoading={isLoadingBrandValue}
          onCheckout={handleCreateCheckout}
        />
      </div>
      <div className="mx-auto mt-45 max-w-312 text-center">
        <Card
          rounded
          className="mx-auto w-full px-4 py-4 bg-basic-dark-blue border border-basic-blue text-center"
        >
          <H5 className="mx-auto text-brand text-center uppercase text-base">
            You are ready to go
          </H5>
          <H1 className="mx-auto mt-2 text-3xl md:text-4xl font-semibold leading-14 max-w-150">
            Ready to experience the power of Marathon Data?
          </H1>
          <div className="mt-8 flex gap-3.5 justify-center items-center">
            <Button
              variant={{
                variant: "solid",
                color: "pink",
              }}
              isLoading={isSubmitting}
              onClick={handleCreateCheckout}
            >
              Get on Board
            </Button>
            <Button
              variant={{
                variant: "outlined",
              }}
              isLoading={isSubmitting}
              onClick={handleCreateCheckout}
            >
              Start a Demo
            </Button>
          </div>
        </Card>
      </div>
      <hr className="mx-auto h-px mt-18 bg-basic-blue border-0 max-w-312" />
      <div className="mx-auto max-w-312 mt-6">
        <Footer disableSocialLinks />
      </div>
    </div>
  )
}
