import { type ConnectorGroupItem } from "~/ui-rtk/pages/Onboarding/ConnectSources/types"
import SourceItem from "../SourceItem/SourceItem"
import ConnectionStatusDialog from "~/ui-rtk/components/features/connectors/ConnectionStatusDialog"
import ConnectorLinkDialog from "~/ui-rtk/components/features/connectors/ConnectorLinkDialog"
import { serviceConnectorMap } from "~/ui-rtk/components/features/connectors/forms/utils"

import { ReactNode, useCallback, useEffect, useState } from "react"
import { toast } from "react-toastify"
import useConnectedServices from "~/ui-rtk/hooks/connected-services"
import useConnectors from "~/ui-rtk/hooks/connectors"

export default function SourceItems({
  connectors,
}: {
  connectors: ConnectorGroupItem[]
}) {
  const [Dialog, setDialog] = useState<ReactNode | null>(null)
  const [onboardUrl, setOnboardUrl] = useState<string | null>(null)
  const { getCompanyConnectorByService } = useConnectedServices()
  const { connect, getConnectorById } = useConnectors()

  const closeDialog = () => {
    setDialog(null)
  }

  const connectToService = useCallback(
    async (service: string, callback?: CallableFunction) => {
      try {
        const { companyConnectorId, uri } = await connect(service)

        const connectorService = serviceConnectorMap[service]
        if (connectorService) {
          const { component: ConnectorDialog } = connectorService
          setDialog(() => (
            <ConnectorDialog
              isOpen
              uri={uri}
              service={service}
              companyConnectorId={companyConnectorId}
              onClose={closeDialog}
            />
          ))
          return
        }

        setOnboardUrl(uri)
      } catch {
        toast.error("Failed to connect to the service. Try again later", {
          toastId: `fail-connect-to-service-${service}`,
        })
      } finally {
        void callback?.()
      }
    },
    [],
  )

  const openConnectionStatusDialog = (service: string) => {
    const companyConnector = getCompanyConnectorByService(service)
    if (!companyConnector) {
      toast.error(`Oops... Seems like this service is not ready yet.`)
      return
    }

    const connector = getConnectorById(service)
    const props = {
      label: connector?.name ?? "",
      imgSrc: connector?.icon ?? "",
    }

    const reconnect = () => connectToService(service)

    setDialog(() => (
      <ConnectionStatusDialog
        isOpen
        onClose={closeDialog}
        onReconnect={reconnect}
        confirmedAt={companyConnector.confirmedAt}
        companyConnectorId={companyConnector.id}
        {...props}
      />
    ))
  }

  useEffect(() => {
    if (onboardUrl) {
      window.location.href = onboardUrl
    }
  }, [onboardUrl])

  return (
    <>
      <ul className="grid grid-cols-1 xl:grid-cols-2 gap-x-4 gap-y-2">
        {connectors.map(connector => (
          <SourceItem
            {...connector}
            key={connector.id}
            image={{ src: connector.icon, alt: `${name} Logo` }}
            onConnect={connectToService}
            onStatusCheck={openConnectionStatusDialog}
            onOpenConnectionLinkDialog={() => {
              setDialog(
                <ConnectorLinkDialog
                  connectorId={connector.id}
                  imgSrc={connector.icon}
                  service={connector.name}
                  onClose={closeDialog}
                />,
              )
            }}
          />
        ))}
      </ul>
      {Dialog}
    </>
  )
}
