import type { ReactNode } from "react"
import type { ColumnDef, ColumnFilter } from "@tanstack/react-table"

import type { ConvertNumberType } from "~/ui-rtk/utils/number-utils"

export type TMetric = Record<string, any>

export type TTextOperator = "contains" | "notContains"
export interface ColumnFilterExtended extends ColumnFilter {
  operator?: TTextOperator
  idx?: number
}

export const FILTER_OPERATOR: Record<TTextOperator, string> = {
  contains: "Contains",
  notContains: "Excludes",
}

export type TableColumnType =
  | ConvertNumberType
  | "auto"
  | "image"
  | "title"
  | "date"
  | "text"
  | undefined

export type TableVisualizationColumn = {
  key: string
  header?: string
  type?: TableColumnType
  width?: number
  disabled?: boolean
  disableSorting?: boolean
  disableFiltering?: boolean
  render?: (row: any, renderConfig?: Record<string, any>) => ReactNode | string
  className?: string
  classes?: Partial<{
    cell: string
    head: string
  }>
}

export type TSource = {
  name: string
  icon: string
  isConnected: boolean
}

export type TSources = Map<string, TSource>

export type TableVisualizationColumnFilter = {
  variant: "text" | "range"
} & ColumnFilterExtended

export type TableVisualizationColumnDef = ColumnDef<TMetric, unknown> &
  TableVisualizationColumn & {
    meta?: {
      filterFn?: (filter: TableVisualizationColumnFilter) => void
    }
  }

export type TColumnFiltersState = TableVisualizationColumnFilter[]

export type TableVisualizationMeta = {
  filterFn: (state: TColumnFiltersState) => void
  getFiltersStateFn: () => TColumnFiltersState
  addFilterFn: (columnFilter: TableVisualizationColumnFilter) => void
  resetFilters: () => void
}
