import { DataSource } from "@db/maindb/prisma/client"
import { emptySplitApi as api } from "./emptyApi"
import { userActions } from "../app/slices/user.slice"
import { companyActions } from "../app/slices/company.slice"
import { revertAll } from "../app/revertAll"
import { clearMetricsCache } from "../hooks/metrics-cache"
import { DefaultDto, SessionDto } from "./types"

const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    authControllerLogin: build.mutation<
      AuthControllerLoginApiResponse,
      AuthControllerLoginApiArg
    >({
      query: queryArg => ({
        url: `/auth/login`,
        method: "POST",
        body: {
          email: queryArg.loginCredentialsDto.email?.toLowerCase(),
          password: queryArg.loginCredentialsDto.password,
        },
      }),
      invalidatesTags: ["Auth"],
    }),
    authControllerAuthorize: build.mutation<
      AuthControllerAuthorizeApiResponse,
      AuthControllerAuthorizeApiArg
    >({
      query: () => ({ url: `/auth/authorize`, method: "POST" }),
    }),
    authControllerLogout: build.mutation<
      AuthControllerLogoutApiResponse,
      AuthControllerLogoutApiArg
    >({
      query: () => ({ url: `/auth/logout`, method: "POST" }),
      invalidatesTags: ["Auth"],
      onQueryStarted: async (_arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled
          dispatch(api.util.resetApiState())
          dispatch(revertAll())
        } catch {
          //
        }
      },
    }),
    authControllerCurrentUser: build.query<
      AuthControllerCurrentUserApiResponse,
      AuthControllerCurrentUserApiArg
    >({
      query: () => ({ url: `/auth/current-user` }),
      providesTags: ["Auth"],
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const response = await queryFulfilled
          const session: SessionDto = response.data
          dispatch(revertAll())
          dispatch(userActions.setUserSession(session))
          dispatch(companyActions.setCurrentCompany(session.currentCompany))
          clearMetricsCache()
        } catch {
          //
        }
      },
    }),
    authControllerSwitchCompany: build.mutation<
      AuthControllerSwitchCompanyApiResponse,
      AuthControllerSwitchCompanyApiArg
    >({
      query: queryArg => ({
        url: `/auth/switch-company`,
        method: "POST",
        body: queryArg.switchCompanyDto,
      }),
      invalidatesTags: ["Auth", "UserCompany"],
    }),
    authControllerRequestResetPassword: build.mutation<
      AuthControllerRequestResetPasswordApiResponse,
      AuthControllerRequestResetPasswordApiArg
    >({
      query: queryArg => ({
        url: `/auth/request-reset-password`,
        method: "POST",
        body: queryArg.requestResetPasswordDto,
      }),
    }),
    authControllerValidateToken: build.query<
      AuthControllerValidateTokenApiResponse,
      AuthControllerValidateTokenApiArg
    >({
      query: queryArg => ({
        url: `/auth/validate-token`,
        params: { token: queryArg.token },
      }),
    }),
    authControllerResetPassword: build.mutation<
      AuthControllerResetPasswordApiResponse,
      AuthControllerResetPasswordApiArg
    >({
      query: queryArg => ({
        url: `/auth/reset-password`,
        method: "PUT",
        body: queryArg.resetPasswordDto,
      }),
    }),
    authControllerChangePassword: build.mutation<
      AuthControllerChangePasswordApiResponse,
      AuthControllerChangePasswordApiArg
    >({
      query: queryArg => ({
        url: `/auth/change-password`,
        method: "PUT",
        body: queryArg.resetPasswordLoggedDto,
      }),
    }),
    authControllerUpdateProfile: build.mutation<
      AuthControllerUpdateProfileApiResponse,
      AuthControllerUpdateProfileApiArg
    >({
      query: queryArg => ({
        url: `/auth/profile`,
        method: "PUT",
        body: queryArg.updateProfileDto,
      }),
    }),
    authControllerCreateAccount: build.mutation<
      AuthControllerCreateAccountApiResponse,
      AuthControllerCreateAccountApiArg
    >({
      query: queryArg => ({
        url: "/auth/create-account",
        method: "POST",
        body: queryArg.createAccountDto,
      }),
      invalidatesTags: ["Auth"],
    }),
    authControllerConfirmEmail: build.mutation<
      AuthControllerConfirmEmailApiResponse,
      AuthControllerConfirmEmailApiArg
    >({
      query: queryArg => ({
        url: "/auth/confirm-email",
        method: "PUT",
        body: queryArg.confirmEmailDto,
      }),
      invalidatesTags: ["Auth"],
    }),
    authControllerRequestConfirmEmail: build.mutation<
      AuthControllerRequestConfirmEmailApiResponse,
      AuthControllerRequestConfirmEmailApiArg
    >({
      query: () => ({
        url: "/auth/request-confirm-email",
        method: "POST",
      }),
    }),
  }),
  overrideExisting: false,
})
export { injectedRtkApi as generatedApi }
export type AuthControllerLoginApiResponse = /** status 200  */ SessionDto
export type AuthControllerLoginApiArg = {
  loginCredentialsDto: LoginCredentialsDto
}

export type AuthControllerCreateAccountApiResponse = SessionDto
export type AuthControllerCreateAccountApiArg = {
  createAccountDto: CreateAccountDto
}

export type CreateAccountDto = {
  fullName: string
  workEmail: string
  password: string
}

export type AuthControllerConfirmEmailApiResponse = void
export type AuthControllerConfirmEmailApiArg = {
  confirmEmailDto: ConfirmEmailDto
}

export type AuthControllerAuthorizeApiResponse = /** status 200  */ SessionDto
export type AuthControllerAuthorizeApiArg = void
export type AuthControllerLogoutApiResponse = unknown
export type AuthControllerLogoutApiArg = void
export type AuthControllerCurrentUserApiResponse = /** status 200  */ SessionDto
export type AuthControllerCurrentUserApiArg = void
export type AuthControllerSwitchCompanyApiResponse =
  /** status 200  */ SessionDto
export type AuthControllerSwitchCompanyApiArg = {
  switchCompanyDto: SwitchCompanyDto
}
export type AuthControllerRequestResetPasswordApiResponse =
  /** status 200  */ DefaultDto
export type AuthControllerRequestResetPasswordApiArg = {
  requestResetPasswordDto: RequestResetPasswordDto
}
export type AuthControllerValidateTokenApiResponse =
  /** status 200  */ DefaultDto
export type AuthControllerValidateTokenApiArg = {
  token: string
}
export type AuthControllerResetPasswordApiResponse =
  /** status 200  */ DefaultDto
export type AuthControllerResetPasswordApiArg = {
  resetPasswordDto: ResetPasswordDto
}
export type AuthControllerChangePasswordApiResponse =
  /** status 200  */ DefaultDto
export type AuthControllerChangePasswordApiArg = {
  resetPasswordLoggedDto: ResetPasswordLoggedDto
}
export type AuthControllerUpdateProfileApiResponse =
  /** status 200  */ DefaultDto
export type AuthControllerUpdateProfileApiArg = {
  updateProfileDto: UpdateProfileDto
}

export type AuthControllerRequestConfirmEmailApiResponse = void
export type AuthControllerRequestConfirmEmailApiArg = void

export type LoginCredentialsDto = {
  email: string
  password: string
}

export type SwitchCompanyDto = {
  companyId: string
}

export type RequestResetPasswordDto = {
  email: string
}
export type ResetPasswordDto = {
  token: string
  newPassword: string
}
export type ResetPasswordLoggedDto = {
  oldPassword: string
  newPassword: string
}
export type UpdateProfileDto = {
  email: string
  name: string
  dataSource?: DataSource
}

export type ConfirmEmailDto = {
  token: string
}

export const {
  useAuthControllerCreateAccountMutation,
  useAuthControllerLoginMutation,
  useAuthControllerAuthorizeMutation,
  useAuthControllerLogoutMutation,
  useAuthControllerCurrentUserQuery,
  useAuthControllerSwitchCompanyMutation,
  useAuthControllerRequestResetPasswordMutation,
  useAuthControllerValidateTokenQuery,
  useAuthControllerResetPasswordMutation,
  useAuthControllerChangePasswordMutation,
  useAuthControllerUpdateProfileMutation,
  useAuthControllerConfirmEmailMutation,
  useAuthControllerRequestConfirmEmailMutation,
} = injectedRtkApi
