import React, { useMemo } from "react"
import { Header, flexRender } from "@tanstack/react-table"
import { useSortable } from "@dnd-kit/sortable"

import { cn } from "~/ui-rtk/utils/tailwind-utils"

import type { TMetric } from "~/ui-rtk/constants/table-visualization-types"
import { ArrowDownSvg, ArrowTopSvg } from "~/ui-rtk/components/ui/svg/arrow"
import { getCommonPinningOrderingStyles } from "../../utils"
import { PinSvg } from "~/ui-rtk/components/ui/svg/essentials"

type THeadCellProps = {
  header: Header<TMetric, unknown>
  className?: string
  sorting?: "asc" | "desc"
  sortingOrder?: number
}

export const HeadCell: React.FC<THeadCellProps> = ({
  header,
  className,
  sorting,
  sortingOrder,
}) => {
  const { attributes, isDragging, listeners, setNodeRef, transform } =
    useSortable({
      id: header.column.id,
    })

  const classNameWithAction = cn(
    "p-4 text-4.5 text-white font-bold leading-6 tracking-[-2%] text-left group bg-basic-dark-blue hover:z-20 group",
    className,
    {
      "z-10": isDragging || header.column.getIsPinned(),
    },
  )

  const thStyles = useMemo(
    () => ({
      maxWidth: `${header.column.getSize() ? `${header.column.getSize()}px` : "unset"}`,
      minWidth: `${header.column.getSize() ? `${header.column.getSize()}px` : "unset"}`,
      cursor: header.column.getCanSort() ? "pointer" : undefined,
      ...getCommonPinningOrderingStyles(header.column, isDragging, transform),
    }),
    [header, isDragging, transform],
  )

  return (
    <th
      key={header.id}
      style={thStyles}
      className={classNameWithAction}
      colSpan={header.colSpan}
      ref={setNodeRef}
    >
      <div className="flex items-center justify-between gap-2">
        {header.column.getIsPinned() ? (
          <div onClick={header.column.getToggleSortingHandler()}>
            {header.isPlaceholder
              ? null
              : flexRender(header.column.columnDef.header, header.getContext())}
          </div>
        ) : (
          <div
            onClick={header.column.getToggleSortingHandler()}
            {...attributes}
            {...listeners}
          >
            {header.isPlaceholder
              ? null
              : flexRender(header.column.columnDef.header, header.getContext())}
          </div>
        )}
        <div className="flex items-center justify-end gap-1">
          {sorting && (
            <div className="whitespace-nowrap">
              {sorting === "desc" && <ArrowDownSvg />}
              {sorting === "asc" && <ArrowTopSvg />}
              {<sub className="text-xs">{(sortingOrder ?? 0) + 1}</sub>}
            </div>
          )}
          {!sorting && header.column.getCanSort() && (
            <div
              className="opacity-0 group-hover:opacity-100"
              onClick={header.column.getToggleSortingHandler()}
            >
              <ArrowDownSvg />
            </div>
          )}
          {header.column.getIsPinned() && (
            <PinSvg onClick={() => header.column.pin(false)} />
          )}
          {!header.column.getIsPinned() && header.column.getCanPin() && (
            <div className="opacity-0 group-hover:opacity-100">
              <PinSvg onClick={() => header.column.pin("left")} />
            </div>
          )}
        </div>
      </div>
    </th>
  )
}
