import { useCallback, useEffect, useState } from "react"
import { toast } from "react-toastify"
import { SuccessSvg } from "~/ui-rtk/components/ui/svg/essentials"
import { type TCompanySettingsFormValues, useConnect } from "./connect"
import useLoadingState from "~/ui-rtk/hooks/loading-state"
import { H2, H3 } from "~/ui-rtk/components/ui/typography"
import Breadcrumbs from "~/ui-rtk/components/layout/Breadcrumbs"
import { ErrorMessage, Field, Form, Formik } from "formik"
import { Input, Button } from "~/ui-rtk/components/ui/controls"
import { Divider } from "~/ui-rtk/components/ui/common"
import { Toggle } from "~/ui-rtk/components/ui/controls/Toggle"
import DateField from "~/ui-rtk/components/ui/controls/DateField/DateField"
import type { DateOrNull } from "~/ui-rtk/shared/types/date"
import { useCompanyUserControllerNotifyMutation } from "~/ui-rtk/api/companyUserApi"
import { CompanyUserNotification } from "~/api/dto/company-user/company-user-notification"
import { copyTextToClipboard } from "~/ui-rtk/utils/clipboard"
import useGaMultiPropertyAccess from "~/ui-rtk/hooks/ga-multi-property-access"

export default function SettingsCompanyPage() {
  const { currentCompany, initialValues, validationSchema, updateSettings } =
    useConnect()

  const [notify, { isLoading: isCompanyUserNotificationLoading }] =
    useCompanyUserControllerNotifyMutation()

  const { isLoading, setIsLoading } = useLoadingState()

  const hasGaMultiPropertyAccess = useGaMultiPropertyAccess()

  const handleNotifyCompanyUsers = useCallback(async () => {
    if (!currentCompany) {
      return
    }

    try {
      await notify({
        id: currentCompany.id,
        companyUserNotifyDto: {
          notificationType: CompanyUserNotification.CONNECTION_COMPLETED,
        },
      }).unwrap()
      toast.success("Notification sent successfully!", {
        toastId: "connection-completed-notification-success",
      })
    } catch (error) {
      toast.error("Notification failed", {
        toastId: "connection-completed-notification-error",
      })
    }
  }, [currentCompany, isCompanyUserNotificationLoading])

  useEffect(() => {
    document.title = "Marathon Data: Settings > Company Settings"
  }, [])

  const handleUpdateSettings = async (values: TCompanySettingsFormValues) => {
    setIsLoading(true)
    await updateSettings(values).catch(() => null)
    setIsLoading(false)
  }

  const [showClipboardSuccess, setShowClipboardSuccess] = useState(false)

  const copyToClipboard = () => {
    void copyTextToClipboard(currentCompany?.id || "")
      .then(() => setShowClipboardSuccess(true))
      .catch(() =>
        toast.error("Failed to copy id to clipboard", {
          toastId: "clipboard-copy-company-id",
        }),
      )
  }

  return (
    <div className="px-6 pt-6">
      <div className="text-start">
        <H2>
          <Breadcrumbs
            items={[
              {
                slug: "/settings",
                name: "Settings",
              },
              {
                slug: "company",
                name: "Company Settings",
              },
            ]}
          />
        </H2>
      </div>
      <div className="space-y-8 mt-4 pr-8 text-start">
        <div className="space-y-2">
          <h3>Notify company members when data is ready</h3>
          <Button
            variant={{ variant: "solid", color: "white" }}
            size="md"
            type="button"
            isLoading={isCompanyUserNotificationLoading || isLoading}
            onClick={handleNotifyCompanyUsers}
          >
            Notify
          </Button>
        </div>
        <Formik
          initialValues={initialValues}
          onSubmit={handleUpdateSettings}
          validationSchema={validationSchema}
        >
          {({ setFieldValue, values }) => (
            <Form className="py-6">
              <div className="grid grid-cols-3 grid-rows-1 gap-8 py-3">
                <h3 className="row-span-4 py-3">Company ID:</h3>
                <div className="relative col-span-2">
                  <div className="flex items-center gap-2">
                    <div className="flex items-center gap-x-4">
                      <button
                        type="button"
                        className="btn"
                        onClick={() => copyToClipboard()}
                      >
                        {currentCompany?.id}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.666 3.888A2.25 2.25 0 0 0 13.5 2.25h-3c-1.03 0-1.9.693-2.166 1.638m7.332 0c.055.194.084.4.084.612v0a.75.75 0 0 1-.75.75H9a.75.75 0 0 1-.75-.75v0c0-.212.03-.418.084-.612m7.332 0c.646.049 1.288.11 1.927.184 1.1.128 1.907 1.077 1.907 2.185V19.5a2.25 2.25 0 0 1-2.25 2.25H6.75A2.25 2.25 0 0 1 4.5 19.5V6.257c0-1.108.806-2.057 1.907-2.185a48.208 48.208 0 0 1 1.927-.184"
                          />
                        </svg>
                      </button>
                      {showClipboardSuccess ? (
                        <div className="flex items-center justify-center gap-2 text-sm text-american-green">
                          <SuccessSvg fill="green" size={20} />
                          <p>Successfully copied to the clipboard!</p>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <Divider className="my-3" />
              <div className="grid grid-cols-3 grid-rows-1 gap-8 py-3">
                <h3 className="row-span-4 py-3">Industry</h3>
                <div className="relative col-span-2">
                  <div className="flex items-center gap-2">
                    <Field
                      type="text"
                      id="industry"
                      name="industry"
                      as={Input}
                      value={values.industry}
                    />
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-3 grid-rows-1 gap-8 py-3">
                <h3 className="row-span-4 py-3">Grant free access</h3>
                <div className="relative col-span-2">
                  <div className="flex items-center gap-2">
                    <Field
                      type="text"
                      id="freeAccessEndsAt"
                      name="freeAccessEndsAt"
                      as={DateField}
                      value={values.freeAccessEndsAt}
                      onChange={(date: DateOrNull) =>
                        setFieldValue("freeAccessEndsAt", date)
                      }
                    />
                    <ErrorMessage
                      component="a"
                      name="freeAccessEndsAt"
                      className="absolute input-error-message"
                    />
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-3 grid-rows-1 gap-8 py-3">
                <div className="row-span-4 py-3">Data processing Enabled:</div>
                <div className="relative col-span-2">
                  <Field
                    id="isPipelineEnabled"
                    name="isPipelineEnabled"
                    as={Toggle}
                    value={values.isPipelineEnabled}
                    onChange={(value: boolean) =>
                      setFieldValue("isPipelineEnabled", value)
                    }
                  />
                </div>
              </div>
              <div className="grid grid-cols-3 grid-rows-1 gap-8 py-3">
                <h3 className="row-span-4 py-3">Billing Enabled:</h3>
                <div className="relative col-span-2">
                  <div className="flex items-center gap-2">
                    <Field
                      type="text"
                      id="isBillingEnabled"
                      name="isBillingEnabled"
                      as={Toggle}
                      value={values.isBillingEnabled}
                      onChange={(date: boolean) =>
                        setFieldValue("isBillingEnabled", date)
                      }
                    />
                    <ErrorMessage
                      component="a"
                      name="isBillingEnabled"
                      className="absolute input-error-message"
                    />
                  </div>
                </div>
              </div>
              {hasGaMultiPropertyAccess && (
                <>
                  <Divider className="my-3" />
                  <H3>Flags</H3>
                  <div className="grid grid-cols-3 grid-rows-1 gap-8 py-3">
                    <h3 className="row-span-4 py-3">GA Multi Property:</h3>
                    <div className="relative col-span-2">
                      <div className="flex items-center gap-2">
                        <Field
                          type="text"
                          id="gaMultiProperty"
                          name="gaMultiProperty"
                          as={Toggle}
                          value={values.gaMultiProperty}
                          onChange={(date: boolean) =>
                            setFieldValue("gaMultiProperty", date)
                          }
                        />
                        <ErrorMessage
                          component="a"
                          name="gaMultiProperty"
                          className="absolute input-error-message"
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
              <Divider className="my-3" />
              <div className="items-right flex justify-between">
                <Button
                  variant={{ variant: "solid", color: "pink" }}
                  size="sm"
                  type="submit"
                  isLoading={isLoading}
                >
                  Update
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}
