import AdDetails from "~/ui-rtk/components/features/details/AdDetails/AdDetails"
import AdSetDetails from "~/ui-rtk/components/features/details/AdSetDetails/AdSetDetails"
import CampaignDetails from "~/ui-rtk/components/features/details/CampaignDetails/CampaignDetails"
import { ElementType } from "react"
import { TMetric } from "~/ui-rtk/constants/table-visualization-types"
import PostDetails from "./PostDetails/PostDetails"

export type TCampaignAdSetAdDetailsProps = {
  metric: TMetric
  widget: string
  dateRange: [Date, Date]
  prefix?: string
}

export const CampaignAdSetAdStringToComponentMap: Record<string, ElementType> =
  {
    CampaignDetails,
    AdSetDetails,
    AdDetails,
    PostDetails,
  }

export const ignoreTableHeads = ["Source"]

export const getCubeQueryBase = (tablePrefix: string) => ({
  measures: [`${tablePrefix}.spend`, `${tablePrefix}.brand_value_agg`],
})

export const CUBE_QUERY_KEYS_MAP = {
  ALL: "ALL",
}

export type TAdsetMediaOption =
  | "Exclude"
  | "Demand Capture - Acquisition"
  | "Demand Capture - Retargeting"
  | "Demand Capture - Broad"
  | "Demand Generation"
  | "Retention"

export type TCampaignMediaOption =
  | "Exclude"
  | "Demand Capture - Acquisition"
  | "Demand Capture - Retargeting"
  | "Demand Capture - Broad"
  | "Demand Generation"
  | "Retention"

export type TAdMediaOption =
  | "Exclude"
  | "Prompted Engagements"
  | "Meme Content"
  | "Branded Content"

export type TPostMediaOption =
  | "Exclude"
  | "Prompted Engagements"
  | "Meme Content"
  | "Branded Content"

export const CAMPAIGN_MEDIA_CATEGORIES_OPTIONS: TCampaignMediaOption[] = [
  "Exclude",
  "Demand Capture - Acquisition",
  "Demand Capture - Retargeting",
  "Demand Capture - Broad",
  "Demand Generation",
  "Retention",
]

export const ADSET_MEDIA_CATEGORIES_OPTIONS: TAdsetMediaOption[] = [
  "Exclude",
  "Demand Capture - Acquisition",
  "Demand Capture - Retargeting",
  "Demand Capture - Broad",
  "Demand Generation",
  "Retention",
]

export const AD_MEDIA_CATEGORIES_OPTIONS: TAdMediaOption[] = [
  "Exclude",
  "Prompted Engagements",
  "Meme Content",
  "Branded Content",
]

export const POST_MEDIA_CATEGORIES_OPTIONS: TPostMediaOption[] = [
  "Exclude",
  "Prompted Engagements",
  "Meme Content",
  "Branded Content",
]
