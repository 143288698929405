import * as Sentry from "@sentry/react"
import { type Query, TimeDimensionGranularity } from "@cubejs-client/core"
import {
  AggregationType,
  UNKNOWN_DATA_KEY,
  TOTAL_CALCULATED_DATA_KEY,
} from "./charts"
import { MEDIA_CHANNEL_TO_SOURCE_ID_MAPPING } from "../components/features/dashboards/BrandMediaOptimizationDashboard/constants"

import useDate from "~/ui-rtk/hooks/date"
import { safeDivide } from "../utils/number-utils"
import {
  SHOPIFY_REVENUE_TYPE,
  SHOPIFY_REVENUE_TYPE_TO_COLOR_MAP,
  SHOPIFY_REVENUE_TYPE_TO_TITLE_MAP,
} from "./revenue"
import { DEFAULT_BASELINE_REVENUE_METRIC } from "../hooks/revenue-source"
const dayjs = useDate()

export const GranularityMapping: Record<string, string> = {
  [AggregationType.DAILY]: "day",
  [AggregationType.MONTHLY]: "month",
  [AggregationType.WEEKLY]: "week",
}

export type TMetric = Partial<Query>
export type TQuery = {
  q:
    | TMetric
    | TMetric[]
    | ((params: Record<string, any>) => TMetric | TMetric[])
  postProcess?: (
    data: any[],
    params: Record<string, any>,
  ) => [any[]] | [any[], any[]]
  getCubePrefix?: (params: Record<string, any>) => string
  disabled?: boolean
  filter?: (
    data: any[],
    granularity: TimeDimensionGranularity,
    options: Record<string, any>,
  ) => any[] | { metrics: any[]; metricsConfig: any }
}
export type TMetricsMap = Record<string, TQuery>

export type TMetricItem = Record<string, string | number>
export type TMetricColumn = {
  key: string
  [key: string]: string
}

interface brandValueStackedChartDataPoint {
  [dateKey: string]: {
    // This allows for dynamic keys, like '2024-09'
    date: string
    baseline_revenue?: number // Optional, as it might not be present in every data point
    engagements?: number // Optional
    conversions?: number // Optional
  }
}

export const generalPostProcess = (data: any, columns: any) => {
  const [dateColumn, ...dataColumns] = columns

  return (data ?? []).map((metrics: TMetricItem) => {
    const metric: Record<string, number | string | boolean> = {
      [dateColumn.key]: metrics[dateColumn.key],
    }
    dataColumns.forEach((column: TMetricColumn) => {
      const parsedValue = parseFloat(`${metrics[column.dataIndex]}`) ?? 0
      metric[column.dataIndex] =
        column.type === "number" ? parsedValue : metrics[column.dataIndex]
    })
    return metric
  })
}

export const MetricsMapping: TMetricsMap = {
  google_keywords_share_of_search_total_composed_chart: {
    q: {
      measures: [
        "company_mind_share_metrics_monthly.share_of_search",
        "company_mind_share_metrics_monthly.searches",
      ],
      dimensions: [
        "company_mind_share_metrics_monthly.brand",
        "company_mind_share_metrics_monthly.category",
      ],
      timeDimensions: [
        {
          dimension: "company_mind_share_metrics_monthly.date",
        },
      ],
      filters: [
        {
          dimension: "company_mind_share_metrics_monthly.metric",
          operator: "equals",
          values: ["google_keywords_share_of_search_total"],
        },
      ],
    },
    postProcess: (
      data: any[],
      params: { granularity?: TimeDimensionGranularity },
    ) => {
      const { granularity } = params ?? {
        granularity: GranularityMapping.MONTHLY,
      }
      const dateKey = `company_mind_share_metrics_monthly.date.${granularity}`
      const brandKey = "company_mind_share_metrics_monthly.brand"
      const valueKey = "company_mind_share_metrics_monthly.share_of_search"
      const absValueKey = "company_mind_share_metrics_monthly.searches"
      const resultMap: Record<string, any> = {}
      const columnsMap: Record<string, Record<string, string>> = {}

      data.forEach((item: any) => {
        const date = dayjs(item[dateKey]).format("YYYY-MM-DD")
        if (!resultMap[date]) {
          resultMap[date] = {
            [dateKey]: date,
            [brandKey]: {},
            [TOTAL_CALCULATED_DATA_KEY]: 0,
          }
        }
        const brandDataKey = `${brandKey}.${item[brandKey]}`
        if (!columnsMap[brandDataKey]) {
          columnsMap[brandDataKey] = {
            dataIndex: brandDataKey,
            key: brandDataKey,
            shortTitle: item[brandKey],
            title: item[brandKey],
            type: "number",
          }
        }
        const valueItem = {
          share_of_search: parseFloat(item[valueKey]),
          searches: parseFloat(item[absValueKey]),
        }
        resultMap[date][brandKey][item[brandKey]] = valueItem
        resultMap[date][brandDataKey] = valueItem
        resultMap[date][TOTAL_CALCULATED_DATA_KEY] += +item[absValueKey]
      })

      let not100 = false

      const result = Object.values(resultMap)
        .sort((a, b) => dayjs(a[dateKey]).unix() - dayjs(b[dateKey]).unix())
        .map((item: any) => {
          const total = Object.keys(item[brandKey]).reduce(
            (acc, brand) => acc + item[brandKey][brand].share_of_search,
            0,
          )

          // Hack to make total = 100% (by extrapolating existing values)
          if (total < 100) {
            not100 = true

            const coef = 100 / total
            const newItem = {
              [dateKey]: item[dateKey],
              [brandKey]: {} as Record<string, any>,
              [TOTAL_CALCULATED_DATA_KEY]: item[TOTAL_CALCULATED_DATA_KEY],
            }
            Object.keys(item[brandKey]).forEach(brand => {
              const newVal = item[brandKey][brand].share_of_search * coef
              const newValItem = {
                ...item[brandKey][brand],
                share_of_search: newVal,
              }
              newItem[brandKey][brand] = newValItem
              newItem[`${brandKey}.${brand}`] = newValItem
            }, 0)
            return newItem
          }
          return item
        })

      if (not100) {
        Sentry.captureMessage(
          "google_keywords_share_of_search_total_composed_chart - data not end up to 100%",
        )
      }

      return [
        result,
        [
          {
            dataIndex: dateKey,
            key: dateKey,
            shortTitle: "Date",
            title: "Company Mind Share Metrics Monthly Date",
            type: "time",
          },
          ...Object.values(columnsMap),
        ],
      ]
    },
    filter: (
      data: any[],
      granularity: TimeDimensionGranularity,
      { currentCompany, compareMode },
    ) => {
      const dateKey = `company_mind_share_metrics_monthly.date.${granularity}`
      const brandKey = "company_mind_share_metrics_monthly.brand"
      const { keywords } = (currentCompany?.metadata ?? {}) as { keywords: any }
      const shareOfSearchSuffix = "share_of_search"
      const searchesSuffix = "searches"
      const brands = keywords?.google?.competitors ?? []
      const alias = keywords?.google?.searchAlias
      const aliases = alias ? [alias] : []
      const currentCompanyName = currentCompany?.name

      let skippedKeys = [dateKey, "date", brandKey, TOTAL_CALCULATED_DATA_KEY]
      if (compareMode) {
        skippedKeys = [
          ...skippedKeys,
          `compared.${dateKey}`,
          "compared.date",
          `compared.${brandKey}`,
          `compared.${TOTAL_CALCULATED_DATA_KEY}`,
        ]
      }

      if (!brands || brands.length < 1) {
        return []
      }

      const lowerBrands = brands.map((b: string) => b.toLowerCase())
      const lowerAliases = [currentCompanyName, ...aliases].map((a: string) =>
        a.toLowerCase(),
      )

      return data.map(dataItem => {
        const item: Record<string, any> = {
          [dateKey]: dataItem[dateKey],
          date: dataItem[dateKey],
          [brandKey]: dataItem[brandKey],
          [UNKNOWN_DATA_KEY]: {
            [brandKey]: {},
          },
          [TOTAL_CALCULATED_DATA_KEY]: dataItem[TOTAL_CALCULATED_DATA_KEY],
        }

        if (compareMode) {
          item[`compared.${dateKey}`] = dataItem[`compared.${dateKey}`]
          item["compared.date"] = dataItem["compared.date"]
          item[`compared.${TOTAL_CALCULATED_DATA_KEY}`] =
            dataItem[`compared.${TOTAL_CALCULATED_DATA_KEY}`]
          item[`compared.${UNKNOWN_DATA_KEY}`] = {
            [brandKey]: {},
          }
        }

        const foundBrands: string[] = []
        const subItem: Record<string, any> = {}

        Object.keys(dataItem).forEach((dataKey: string) => {
          if (skippedKeys.includes(dataKey)) {
            return
          }
          const isCompareKey = dataKey.startsWith("compared.")
          const compareOrMainPrefix = isCompareKey ? "compared." : ""

          const brand = dataKey.replace(
            `${compareOrMainPrefix}${brandKey}.`,
            "",
          )
          const currentCompanyShareOfSearchKey = `${compareOrMainPrefix}${brandKey}.${currentCompanyName}.${shareOfSearchSuffix}`
          const currentCompanySearchesKey = `${compareOrMainPrefix}${brandKey}.${currentCompanyName}.${searchesSuffix}`
          const unknownShareOfSearchKey = `${compareOrMainPrefix}${UNKNOWN_DATA_KEY}.${dataKey}.${shareOfSearchSuffix}`
          const unknownSearchesKey = `${compareOrMainPrefix}${UNKNOWN_DATA_KEY}.${dataKey}.${searchesSuffix}`
          const subitemBrandKey = `${compareOrMainPrefix}${brand}`
          const subitemCurrentCompanyKey = `${compareOrMainPrefix}${currentCompanyName}`
          const unknownDataKey = `${compareOrMainPrefix}${UNKNOWN_DATA_KEY}`
          const shareOfSearchDataKey = `${dataKey}.${shareOfSearchSuffix}`
          const searchesDataKey = `${dataKey}.${searchesSuffix}`

          foundBrands.push(brand.toLowerCase())

          if (lowerBrands.includes(brand.toLowerCase())) {
            item[shareOfSearchDataKey] = dataItem[dataKey].share_of_search ?? 0
            item[searchesDataKey] = dataItem[dataKey].searches

            if (!dataKey.startsWith("compared.")) {
              subItem[subitemBrandKey] = dataItem[dataKey]
            }
          } else if (lowerAliases.includes(brand.toLowerCase())) {
            if (!item[currentCompanyShareOfSearchKey]) {
              item[currentCompanyShareOfSearchKey] =
                dataItem[dataKey].share_of_search
              item[currentCompanySearchesKey] = dataItem[dataKey].searches
              subItem[subitemCurrentCompanyKey] = dataItem[dataKey]
            } else {
              item[currentCompanyShareOfSearchKey] +=
                dataItem[dataKey].share_of_search
              item[currentCompanySearchesKey] += dataItem[dataKey].searches
              subItem[subitemCurrentCompanyKey].share_of_search +=
                dataItem[dataKey].share_of_search
              subItem[subitemCurrentCompanyKey].searches +=
                dataItem[dataKey].searches
            }
          } else {
            item[unknownShareOfSearchKey] = dataItem[dataKey].share_of_search
            item[unknownSearchesKey] = dataItem[dataKey].searches
            item[unknownDataKey][brandKey][brand] = dataItem[dataKey] ?? {
              share_of_search: 0,
              searches: 0,
            }
            subItem[brand] = dataItem[dataKey]
          }
        })

        const notFoundBrands = lowerBrands.filter(
          (x: string) => !foundBrands.includes(x),
        )
        notFoundBrands.forEach((brand: string) => {
          item[`${brandKey}.${brand}.${shareOfSearchSuffix}`] = 0
          item[`${brandKey}.${brand}.${searchesSuffix}`] = 0
          subItem[brand] = {
            share_of_search: 0,
            searches: 0,
          }
        })
        item[brandKey] = subItem
        return item
      })
    },
  },
  // No Category
  google_keywords_share_of_search_brands_share_composed_chart: {
    q: {
      measures: [
        "company_mind_share_metrics_monthly.share_of_search",
        "company_mind_share_metrics_monthly.searches",
      ],
      dimensions: ["company_mind_share_metrics_monthly.brand"],
      timeDimensions: [
        {
          dimension: "company_mind_share_metrics_monthly.date",
        },
      ],
      filters: [
        {
          dimension: "company_mind_share_metrics_monthly.metric",
          operator: "equals",
          values: ["google_keywords_share_of_search_per_brand"],
        },
      ],
    },
    postProcess: (
      data: any[],
      params: { granularity?: TimeDimensionGranularity },
    ) => {
      const { granularity } = params ?? {
        granularity: GranularityMapping.MONTHLY,
      }
      const dateKey = `company_mind_share_metrics_monthly.date.${granularity}`
      const brandKey = "company_mind_share_metrics_monthly.brand"
      const valueKey = "company_mind_share_metrics_monthly.share_of_search"
      const absValueKey = "company_mind_share_metrics_monthly.searches"
      const resultMap: Record<string, any> = {}
      const columnsMap: Record<string, Record<string, string>> = {}

      data.forEach((item: any) => {
        const date = dayjs(item[dateKey]).format("YYYY-MM-DD")
        if (!resultMap[date]) {
          resultMap[date] = {
            [dateKey]: date,
            [brandKey]: {},
            [TOTAL_CALCULATED_DATA_KEY]: 0,
          }
        }
        const brandDataKey = `${brandKey}.${item[brandKey]}`
        if (!columnsMap[brandDataKey]) {
          columnsMap[brandDataKey] = {
            dataIndex: brandDataKey,
            key: brandDataKey,
            shortTitle: item[brandKey],
            title: item[brandKey],
            type: "number",
          }
        }
        const valueItem = {
          share_of_search: parseFloat(item[valueKey]),
          searches: parseFloat(item[absValueKey]),
        }
        resultMap[date][brandKey][item[brandKey]] = valueItem
        resultMap[date][brandDataKey] = valueItem
        resultMap[date][TOTAL_CALCULATED_DATA_KEY] += +item[absValueKey]
      })

      let not100 = false

      const result = Object.values(resultMap)
        .sort((a, b) => dayjs(a[dateKey]).unix() - dayjs(b[dateKey]).unix())
        .map((item: any) => {
          const total = Object.keys(item[brandKey]).reduce(
            (acc, brand) => acc + item[brandKey][brand].share_of_search,
            0,
          )

          // Hack to make total = 100% (by extrapolating existing values)
          if (total < 100) {
            not100 = true

            const coef = 100 / total
            const newItem = {
              [dateKey]: item[dateKey],
              [brandKey]: {} as Record<string, any>,
              [TOTAL_CALCULATED_DATA_KEY]: item[TOTAL_CALCULATED_DATA_KEY],
            }
            Object.keys(item[brandKey]).forEach(brand => {
              const newVal = item[brandKey][brand].share_of_search * coef
              const newValItem = {
                ...item[brandKey][brand],
                share_of_search: newVal,
              }
              newItem[brandKey][brand] = newValItem
              newItem[`${brandKey}.${brand}`] = newValItem
            }, 0)
            return newItem
          }
          return item
        })

      if (not100) {
        Sentry.captureMessage(
          "google_keywords_share_of_search_brands_share_composed_chart - data not end up to 100%",
        )
      }

      return [
        result,
        [
          {
            dataIndex: dateKey,
            key: dateKey,
            shortTitle: "Date",
            title: "Company Mind Share Metrics Monthly Date",
            type: "time",
          },
          ...Object.values(columnsMap),
        ],
      ]
    },
    filter: (
      data: any[],
      granularity: TimeDimensionGranularity,
      { currentCompany, compareMode },
    ) => {
      const dateKey = `company_mind_share_metrics_monthly.date.${granularity}`
      const brandKey = "company_mind_share_metrics_monthly.brand"
      const { keywords } = (currentCompany?.metadata ?? {}) as { keywords: any }
      const shareOfSearchSuffix = "share_of_search"
      const searchesSuffix = "searches"
      const brands = keywords?.google?.competitors ?? []
      const alias = keywords?.google?.searchAlias
      const aliases = alias ? [alias] : []
      const currentCompanyName = currentCompany?.name

      let skippedKeys = [dateKey, "date", brandKey, TOTAL_CALCULATED_DATA_KEY]
      if (compareMode) {
        skippedKeys = [
          ...skippedKeys,
          `compared.${dateKey}`,
          "compared.date",
          `compared.${brandKey}`,
          `compared.${TOTAL_CALCULATED_DATA_KEY}`,
        ]
      }

      if (!brands || brands.length < 1) {
        return []
      }

      const lowerBrands = brands.map((b: string) => b.toLowerCase())
      const lowerAliases = [currentCompanyName, ...aliases].map((a: string) =>
        a.toLowerCase(),
      )

      return data.map(dataItem => {
        const item: Record<string, any> = {
          [dateKey]: dataItem[dateKey],
          date: dataItem[dateKey],
          [brandKey]: dataItem[brandKey],
          [UNKNOWN_DATA_KEY]: {
            [brandKey]: {},
          },
          [TOTAL_CALCULATED_DATA_KEY]: dataItem[TOTAL_CALCULATED_DATA_KEY],
        }

        if (compareMode) {
          item[`compared.${dateKey}`] = dataItem[`compared.${dateKey}`]
          item["compared.date"] = dataItem["compared.date"]
          item[`compared.${TOTAL_CALCULATED_DATA_KEY}`] =
            dataItem[`compared.${TOTAL_CALCULATED_DATA_KEY}`]
          item[`compared.${UNKNOWN_DATA_KEY}`] = {
            [brandKey]: {},
          }
        }

        const foundBrands: string[] = []
        const subItem: Record<string, any> = {}
        Object.keys(dataItem).forEach((dataKey: string) => {
          if (skippedKeys.includes(dataKey)) {
            return
          }
          const isCompareKey = dataKey.startsWith("compared.")
          const compareOrMainPrefix = isCompareKey ? "compared." : ""

          const brand = dataKey.replace(
            `${compareOrMainPrefix}${brandKey}.`,
            "",
          )
          const currentCompanyShareOfSearchKey = `${compareOrMainPrefix}${brandKey}.${currentCompanyName}.${shareOfSearchSuffix}`
          const currentCompanySearchesKey = `${compareOrMainPrefix}${brandKey}.${currentCompanyName}.${searchesSuffix}`
          const unknownShareOfSearchKey = `${compareOrMainPrefix}${UNKNOWN_DATA_KEY}.${dataKey}.${shareOfSearchSuffix}`
          const unknownSearchesKey = `${compareOrMainPrefix}${UNKNOWN_DATA_KEY}.${dataKey}.${searchesSuffix}`
          const subitemBrandKey = `${compareOrMainPrefix}${brand}`
          const subitemCurrentCompanyKey = `${compareOrMainPrefix}${currentCompanyName}`
          const unknownDataKey = `${compareOrMainPrefix}${UNKNOWN_DATA_KEY}`
          const shareOfSearchDataKey = `${dataKey}.${shareOfSearchSuffix}`
          const searchesDataKey = `${dataKey}.${searchesSuffix}`

          foundBrands.push(brand.toLowerCase())

          if (lowerBrands.includes(brand.toLowerCase())) {
            item[shareOfSearchDataKey] = dataItem[dataKey].share_of_search ?? 0
            item[searchesDataKey] = dataItem[dataKey].searches

            if (!dataKey.startsWith("compared.")) {
              subItem[subitemBrandKey] = dataItem[dataKey]
            }
          } else if (lowerAliases.includes(brand.toLowerCase())) {
            if (!item[currentCompanyShareOfSearchKey]) {
              item[currentCompanyShareOfSearchKey] =
                dataItem[dataKey].share_of_search
              item[currentCompanySearchesKey] = dataItem[dataKey].searches
              subItem[subitemCurrentCompanyKey] = dataItem[dataKey]
            } else {
              item[currentCompanyShareOfSearchKey] +=
                dataItem[dataKey].share_of_search
              item[currentCompanySearchesKey] += dataItem[dataKey].searches
              subItem[subitemCurrentCompanyKey].share_of_search +=
                dataItem[dataKey].share_of_search
              subItem[subitemCurrentCompanyKey].searches +=
                dataItem[dataKey].searches
            }
          } else {
            item[unknownShareOfSearchKey] = dataItem[dataKey].share_of_search
            item[unknownSearchesKey] = dataItem[dataKey].searches
            item[unknownDataKey][brandKey][brand] = dataItem[dataKey] ?? {
              share_of_search: 0,
              searches: 0,
            }
            subItem[brand] = dataItem[dataKey]
          }
        })

        const notFoundBrands = lowerBrands.filter(
          (x: string) => !foundBrands.includes(x),
        )
        notFoundBrands.forEach((brand: string) => {
          item[`${brandKey}.${brand}.${shareOfSearchSuffix}`] = 0
          item[`${brandKey}.${brand}.${searchesSuffix}`] = 0
          subItem[brand] = {
            share_of_search: 0,
            searches: 0,
          }
        })
        item[brandKey] = subItem
        return item
      })
    },
  },
  // Category: <Category name>
  google_keywords_share_of_search_by_category_multi_chart: {
    q: {
      measures: [
        "company_mind_share_metrics_monthly.share_of_search",
        "company_mind_share_metrics_monthly.searches",
      ],
      dimensions: [
        "company_mind_share_metrics_monthly.category",
        "company_mind_share_metrics_monthly.brand",
      ],
      timeDimensions: [
        {
          dimension: "company_mind_share_metrics_monthly.date",
        },
      ],
      filters: [
        {
          dimension: "company_mind_share_metrics_monthly.metric",
          operator: "equals",
          values: ["google_keywords_share_of_search_per_category"],
        },
      ],
    },
    postProcess: (
      data: any[],
      params: { granularity?: TimeDimensionGranularity },
    ) => {
      const { granularity } = params ?? {
        granularity: GranularityMapping.MONTHLY,
      }
      const dateKey = `company_mind_share_metrics_monthly.date.${granularity}`
      const categoryKey = "company_mind_share_metrics_monthly.category"
      const brandKey = "company_mind_share_metrics_monthly.brand"
      const valueKey = "company_mind_share_metrics_monthly.share_of_search"
      const absValueKey = "company_mind_share_metrics_monthly.searches"
      const resultMap: Record<string, any> = {}
      const columnsMap: Record<string, Record<string, string>> = {}

      data.forEach((item: any) => {
        const date = dayjs(item[dateKey]).format("YYYY-MM-DD")
        if (!resultMap[date]) {
          resultMap[date] = {
            [dateKey]: date,
            [categoryKey]: {},
          }
        }
        if (!resultMap[date][categoryKey][item[categoryKey]]) {
          resultMap[date][categoryKey][item[categoryKey]] = {
            [TOTAL_CALCULATED_DATA_KEY]: 0,
          }
        }
        const val = {
          share_of_search: parseFloat(item[valueKey]),
          searches: parseFloat(item[absValueKey]),
        }
        resultMap[date][categoryKey][item[categoryKey]][item[brandKey]] = val
        resultMap[date][categoryKey][item[categoryKey]][
          TOTAL_CALCULATED_DATA_KEY
        ] += +item[absValueKey]
      })

      let not100 = false

      const result = Object.values(resultMap)
        .sort((a, b) => dayjs(a[dateKey]).unix() - dayjs(b[dateKey]).unix())
        .map((item: any) => {
          const newItem = {
            [dateKey]: item[dateKey],
          }

          Object.keys(item[categoryKey]).forEach((category: any) => {
            const totalShare = Object.keys(item[categoryKey][category]).reduce(
              (acc, brand: string) =>
                brand === TOTAL_CALCULATED_DATA_KEY
                  ? acc
                  : acc + item[categoryKey][category][brand].share_of_search,
              0,
            )

            // Hack to make total = 100% (by extrapolating existing values)
            if (totalShare > 0 && totalShare !== 100) {
              not100 = true

              const coef = 100 / totalShare

              Object.keys(item[categoryKey][category]).forEach(brand => {
                const categoryBrandKey = `${category}.${brand}`

                if (!columnsMap[categoryBrandKey]) {
                  columnsMap[categoryBrandKey] = {
                    dataIndex: categoryBrandKey,
                    key: categoryBrandKey,
                    shortTitle: brand,
                    title: brand,
                    type: "number",
                  }
                }
                if (brand === TOTAL_CALCULATED_DATA_KEY) {
                  newItem[categoryBrandKey] = item[categoryKey][category][brand]
                } else {
                  const newShareVal =
                    item[categoryKey][category][brand].share_of_search * coef

                  newItem[categoryBrandKey] = {
                    searches: item[categoryKey][category][brand].searches,
                    share_of_search: newShareVal,
                  }
                }
              }, 0)
            } else {
              Object.keys(item[categoryKey][category]).forEach(brand => {
                const categoryBrandKey = `${category}.${brand}`

                if (!columnsMap[categoryBrandKey]) {
                  columnsMap[categoryBrandKey] = {
                    dataIndex: categoryBrandKey,
                    key: categoryBrandKey,
                    shortTitle: brand,
                    title: brand,
                    type: "number",
                  }
                }

                newItem[categoryBrandKey] = item[categoryKey][category][brand]
              }, 0)
            }
          })

          return newItem
        })

      if (not100) {
        Sentry.captureMessage(
          "google_keywords_share_of_search_by_category_multi_chart - data not end up to 100%",
        )
      }

      return [
        result,
        [
          {
            dataIndex: dateKey,
            key: dateKey,
            shortTitle: "Date",
            title: "Company Mind Share Metrics Monthly Date",
            type: "time",
          },
          ...Object.values(columnsMap),
        ],
      ]
    },
    filter: (
      data: any[],
      granularity: TimeDimensionGranularity,
      { currentCompany, compareMode },
    ) => {
      const dateKey = `company_mind_share_metrics_monthly.date.${granularity}`
      const { keywords } = (currentCompany?.metadata ?? {}) as { keywords: any }
      const categories = [...(keywords?.google?.categories ?? [])]
      const shareOfSearchSuffix = "share_of_search"
      const searchesSuffix = "searches"
      const brands = keywords?.google?.competitors ?? []
      const currentCompanyName = currentCompany?.name
      const alias = keywords?.google?.searchAlias
      const aliases = alias ? [alias] : []

      const lowerBrands = brands.map((b: string) => b.toLowerCase())
      const lowerAliases = [currentCompanyName, ...aliases].map((a: string) =>
        a.toLowerCase(),
      )

      let skippedKeys = [dateKey, "date"]
      if (compareMode) {
        skippedKeys = [...skippedKeys, `compared.${dateKey}`, "compared.date"]
      }

      if (categories.length < 1) {
        return []
      }

      const unknown: {
        brand: string[]
      } = {
        brand: [],
      }

      const metrics = data.map(dataItem => {
        const item: Record<string, any> = {
          [dateKey]: dataItem[dateKey],
          date: dataItem[dateKey],
          [UNKNOWN_DATA_KEY]: {},
        }

        if (compareMode) {
          item[`compared.${dateKey}`] = dataItem[`compared.${dateKey}`]
          item["compared.date"] = dataItem[`compared.${dateKey}`]
          item[`compared.${UNKNOWN_DATA_KEY}`] = {}
        }

        categories.forEach((category: string) => {
          const lowerCategory = category.toLowerCase()

          Object.keys(dataItem).forEach((dataKey: string) => {
            if (skippedKeys.includes(dataKey)) {
              return
            }

            const isCompareKey = dataKey.startsWith("compared.")
            const compareOrMainPrefix = isCompareKey ? "compared." : ""

            const shareOfSearchDataKey = `${dataKey}.${shareOfSearchSuffix}`
            const searchesDataKey = `${dataKey}.${searchesSuffix}`
            const unknownShareOfSearchKey = `${compareOrMainPrefix}${UNKNOWN_DATA_KEY}.${dataKey}.${shareOfSearchSuffix}`
            const unknownSearchesKey = `${compareOrMainPrefix}${UNKNOWN_DATA_KEY}.${dataKey}.${searchesSuffix}`
            const currentCompanyShareOfSearchKey = `${compareOrMainPrefix}${category}.${currentCompanyName}.${shareOfSearchSuffix}`
            const currentCompanySearchesKey = `${compareOrMainPrefix}${category}.${currentCompanyName}.${searchesSuffix}`

            if (
              dataKey
                .toLowerCase()
                .startsWith(`${compareOrMainPrefix}${lowerCategory}.`)
            ) {
              const brand = dataKey
                .toLowerCase()
                .replace(`${compareOrMainPrefix}${lowerCategory}.`, "")

              if (lowerBrands.includes(brand.toLowerCase())) {
                item[shareOfSearchDataKey] =
                  dataItem[dataKey].share_of_search ?? 0
                item[searchesDataKey] = dataItem[dataKey].searches
              } else if (brand === TOTAL_CALCULATED_DATA_KEY) {
                item[dataKey] = dataItem[dataKey]
              } else if (lowerAliases.includes(brand.toLowerCase())) {
                if (!item[currentCompanyShareOfSearchKey]) {
                  item[currentCompanyShareOfSearchKey] =
                    dataItem[dataKey].share_of_search
                  item[currentCompanySearchesKey] = dataItem[dataKey].searches
                } else {
                  item[`${currentCompanyShareOfSearchKey}`] +=
                    dataItem[dataKey].share_of_search
                  item[currentCompanySearchesKey] += dataItem[dataKey].searches
                }
              } else {
                item[unknownShareOfSearchKey] =
                  dataItem[dataKey].share_of_search ?? 0
                item[unknownSearchesKey] = dataItem[dataKey].searches ?? 0
                item[`${compareOrMainPrefix}${UNKNOWN_DATA_KEY}`][dataKey] =
                  dataItem[dataKey].share_of_search ?? 0
                if (!unknown.brand.includes(brand)) {
                  unknown.brand.push(brand)
                }
              }
            }
          })
        })
        return item
      })

      const metricsConfig = {
        category: categories,
        brand: [currentCompanyName, ...brands],
        unknown,
        suffixKey: "share_of_search",
      }

      return {
        metrics,
        metricsConfig,
      }
    },
  },
  instagram_share_of_comments_area_chart: {
    q: {
      measures: ["metrics.instagram_insights_share_of_comments"],
      filters: [
        {
          member: "metrics.metric",
          operator: "equals",
          values: ["instagram_insights_share_of_comments"],
        },
      ],
      order: {
        "metrics.date": "asc",
      },
      timeDimensions: [
        {
          dimension: "metrics.date",
        },
      ],
    },
  },
  instagram_share_of_followers_area_chart: {
    q: {
      measures: ["metrics.instagram_insights_share_of_followers"],
      filters: [],
      order: {
        "metrics.date": "asc",
      },
      timeDimensions: [
        {
          dimension: "metrics.date",
        },
      ],
    },
  },
  instagram_share_of_likes_area_chart: {
    q: {
      measures: ["metrics.instagram_insights_share_of_likes"],
      filters: [],
      order: {
        "metrics.date": "asc",
      },
      timeDimensions: [
        {
          dimension: "metrics.date",
        },
      ],
    },
  },
  total_social_profile_views_stacked_chart: {
    q: {
      measures: [
        "metrics.instagram_business_profile_views",
        "metrics.tiktok_profile_views",
        "metrics.facebook_pages_views",
        "metrics.total_social_profile_views",
      ],
      filters: [],
      order: {
        "metrics.date": "asc",
      },
      timeDimensions: [
        {
          dimension: "metrics.date",
        },
      ],
    },
  },
  total_shares_stacked_chart: {
    q: {
      measures: [
        "metrics.tiktok_videos_shares",
        "metrics.tiktok_ads_shares",
        "metrics.instagram_business_shares",
        "metrics.instagram_ads_shares",
        "metrics.facebook_pages_shares",
        "metrics.facebook_ads_shares",
        "metrics.youtube_channel_shares",
      ],
      filters: [],
      order: {
        "metrics.date": "asc",
      },
      timeDimensions: [
        {
          dimension: "metrics.date",
        },
      ],
    },
  },
  total_saves_stacked_chart: {
    q: {
      measures: [
        "metrics.instagram_business_saves",
        "metrics.instagram_ads_saves",
        "metrics.tiktok_ads_saves",
      ],
      filters: [],
      order: {
        "metrics.date": "asc",
      },
      timeDimensions: [
        {
          dimension: "metrics.date",
        },
      ],
    },
  },
  total_new_subscribers_stacked_chart: {
    q: {
      measures: [
        "metrics.attentive_sms_subscribers",
        "metrics.klaviyo_email_subscribers",
        "metrics.total_new_subscribers",
      ],
      filters: [],
      order: {
        "metrics.date": "asc",
      },
      timeDimensions: [
        {
          dimension: "metrics.date",
        },
      ],
    },
  },
  total_followers_stacked_chart: {
    q: {
      measures: [
        "metrics.tiktok_profile_follows",
        "metrics.tiktok_ads_follows",
        "metrics.instagram_business_follows",
        "metrics.instagram_insights_follows",
        "metrics.facebook_pages_follows",
        "metrics.youtube_channel_follows",
        "metrics.total_new_followers",
        "metrics.facebook_ads_follows",
        "metrics.instagram_ads_follows",
      ],
      filters: [],
      order: {
        "metrics.date": "asc",
      },
      timeDimensions: [
        {
          dimension: "metrics.date",
        },
      ],
    },
  },
  total_likes_stacked_chart: {
    q: {
      measures: [
        "metrics.instagram_business_likes",
        "metrics.instagram_ads_likes",
        "metrics.tiktok_videos_likes",
        "metrics.tiktok_ads_likes",
        "metrics.facebook_pages_likes",
        "metrics.facebook_ads_likes",
        "metrics.youtube_channel_likes",
        "metrics.total_likes",
      ],
      filters: [],
      order: {
        "metrics.date": "asc",
      },
      timeDimensions: [
        {
          dimension: "metrics.date",
        },
      ],
    },
  },
  total_comments_stacked_chart: {
    q: {
      measures: [
        "metrics.instagram_business_comments",
        "metrics.instagram_ads_comments",
        "metrics.tiktok_videos_comments",
        "metrics.tiktok_ads_comments",
        "metrics.facebook_pages_comments",
        "metrics.facebook_ads_comments",
        "metrics.youtube_channel_comments",
        "metrics.total_comments",
      ],
      filters: [],
      order: {
        "metrics.date": "asc",
      },
      timeDimensions: [
        {
          dimension: "metrics.date",
        },
      ],
    },
  },
  facebook_mentions_area_chart: {
    q: {
      measures: ["metrics.facebook_pages_mentions"],
      filters: [],
      order: {
        "metrics.date": "asc",
      },
      timeDimensions: [
        {
          dimension: "metrics.date",
        },
      ],
    },
  },
  branded_search_totals_stacked_chart: {
    q: {
      measures: [
        "metrics.google_search_organic_branded_search_impressions",
        "metrics.google_ads_paid_branded_search_impressions",
        "metrics.google_ads_paid_branded_search_total_spend",
        "metrics.google_search_organic_branded_search_clicks",
        "metrics.google_ads_paid_branded_search_clicks",
      ],
      dimensions: ["metrics.companyid"],
      filters: [
        {
          member: "metrics.metric",
          operator: "equals",
          values: [
            "google_search_organic_branded_search_impressions",
            "google_ads_paid_branded_search_impressions",
            "google_ads_paid_branded_search_total_spend",
            "google_search_organic_branded_search_clicks",
            "google_ads_paid_branded_search_clicks",
          ],
        },
      ],
      order: {
        "metrics.date": "asc",
      },
      timeDimensions: [
        {
          dimension: "metrics.date",
        },
      ],
    },
  },
  ga_revenue_break_down_stacked_chart: {
    q: {
      measures: [
        "metrics.ga_baseline_revenue",
        "metrics.ga_paid_revenue",
        "metrics.ga_triggered_revenue",
        "metrics.total_ad_spend",
      ],
      timeDimensions: [
        {
          dimension: "metrics.date",
        },
      ],
      order: {
        "metrics.date": "asc",
      },
    },
  },
  ga_mp_revenue_break_down_stacked_chart: {
    q: {
      measures: [
        "metrics.ga_multi_property_baseline_revenue",
        "metrics.ga_multi_property_paid_revenue",
        "metrics.ga_multi_property_triggered_revenue",
        "metrics.total_ad_spend",
      ],
      timeDimensions: [
        {
          dimension: "metrics.date",
        },
      ],
      order: {
        "metrics.date": "asc",
      },
    },
  },
  shopify_revenue_break_down_stacked_chart: {
    q: {
      measures: [
        "metrics.shopify_baseline_revenue",
        "metrics.shopify_paid_revenue",
        "metrics.shopify_triggered_revenue",
        "metrics.total_ad_spend",
      ],
      timeDimensions: [
        {
          dimension: "metrics.date",
        },
      ],
      order: {
        "metrics.date": "asc",
      },
    },
  },
  shopify_revenue_with_discount_table: {
    q: {
      measures: [
        "metrics.shopify_baseline_revenue",
        "metrics.shopify_paid_revenue",
        "metrics.shopify_triggered_revenue",
        "metrics.shopify_baseline_discount_revenue",
        "metrics.shopify_paid_discount_revenue",
        "metrics.shopify_triggered_discount_revenue",
      ],
      timeDimensions: [
        {
          dimension: "metrics.date",
        },
      ],
      order: {
        "metrics.date": "asc",
      },
    },
    postProcess(data: any[]) {
      const processed = data.reduce(
        (acc, item) => ({
          shopify_baseline_revenue:
            +acc.shopify_baseline_revenue +
            +(item["metrics.shopify_baseline_revenue"] ?? 0),
          shopify_paid_revenue:
            +acc.shopify_paid_revenue +
            +(item["metrics.shopify_paid_revenue"] ?? 0),
          shopify_triggered_revenue:
            +acc.shopify_triggered_revenue +
            +(item["metrics.shopify_triggered_revenue"] ?? 0),
          shopify_baseline_discount_revenue:
            +acc.shopify_baseline_discount_revenue +
            +(item["metrics.shopify_baseline_discount_revenue"] ?? 0),
          shopify_paid_discount_revenue:
            +acc.shopify_paid_discount_revenue +
            +(item["metrics.shopify_paid_discount_revenue"] ?? 0),
          shopify_triggered_discount_revenue:
            +acc.shopify_triggered_discount_revenue +
            +(item["metrics.shopify_triggered_discount_revenue"] ?? 0),
        }),
        {
          shopify_baseline_revenue: 0,
          shopify_paid_revenue: 0,
          shopify_triggered_revenue: 0,
          shopify_baseline_discount_revenue: 0,
          shopify_paid_discount_revenue: 0,
          shopify_triggered_discount_revenue: 0,
        },
      )

      processed.shopify_baseline_non_discount_revenue =
        processed.shopify_baseline_discount_revenue &&
        processed.shopify_baseline_revenue
          ? processed.shopify_baseline_revenue -
            processed.shopify_baseline_discount_revenue
          : 0
      processed.shopify_paid_non_discount_revenue =
        processed.shopify_paid_discount_revenue &&
        processed.shopify_paid_revenue
          ? processed.shopify_paid_revenue -
            processed.shopify_paid_discount_revenue
          : 0
      processed.shopify_triggered_non_discount_revenue =
        processed.shopify_triggered_discount_revenue &&
        processed.shopify_triggered_revenue
          ? processed.shopify_triggered_revenue -
            processed.shopify_triggered_discount_revenue
          : 0

      const totalRevenue =
        (processed.shopify_baseline_revenue ?? 0) +
        processed.shopify_paid_revenue +
        processed.shopify_triggered_revenue

      const result = Object.values(SHOPIFY_REVENUE_TYPE).reduce(
        (acc: any[], revenueType) => [
          ...acc,
          {
            revenue_type: SHOPIFY_REVENUE_TYPE_TO_TITLE_MAP[revenueType],
            color: SHOPIFY_REVENUE_TYPE_TO_COLOR_MAP[revenueType],
            non_discount_revenue:
              processed[`shopify_${revenueType}_non_discount_revenue`],
            non_discount_revenue_of_type: safeDivide(
              processed[`shopify_${revenueType}_non_discount_revenue`],
              processed[`shopify_${revenueType}_revenue`],
            ),
            non_discount_revenue_of_total: safeDivide(
              processed[`shopify_${revenueType}_non_discount_revenue`],
              totalRevenue,
            ),
            discount_revenue:
              processed[`shopify_${revenueType}_discount_revenue`],
            discount_revenue_of_type: safeDivide(
              processed[`shopify_${revenueType}_discount_revenue`],
              processed[`shopify_${revenueType}_revenue`],
            ),
            discount_revenue_of_total: safeDivide(
              processed[`shopify_${revenueType}_discount_revenue`],
              totalRevenue,
            ),
            total_revenue: processed[`shopify_${revenueType}_revenue`],
            total_revenue_of_total: safeDivide(
              processed[`shopify_${revenueType}_revenue`],
              totalRevenue,
            ),
          },
        ],
        [],
      )
      return [result]
    },
  },
  ga_session_paid_triggered_and_baseline_traffic_data_table: {
    q: [
      {
        measures: [
          "company_revenue_metrics_daily.baseline_sessions",
          "company_revenue_metrics_daily.baseline_revenue",
        ],
        dimensions: ["company_revenue_metrics_daily.companyid"],
        timeDimensions: [
          {
            dimension: "company_revenue_metrics_daily.date",
          },
        ],
      },
      {
        measures: [
          "company_revenue_metrics_daily.triggered_sessions",
          "company_revenue_metrics_daily.triggered_revenue",
        ],
        dimensions: ["company_revenue_metrics_daily.companyid"],
        timeDimensions: [
          {
            dimension: "company_revenue_metrics_daily.date",
          },
        ],
      },
      {
        measures: [
          "company_revenue_metrics_daily.paid_sessions",
          "company_revenue_metrics_daily.paid_revenue",
        ],
        dimensions: ["company_revenue_metrics_daily.companyid"],
        timeDimensions: [
          {
            dimension: "company_revenue_metrics_daily.date",
          },
        ],
      },
    ],
    postProcess(data: any[]) {
      return [
        data.map(dataItem => ({
          ...dataItem,
          "company_revenue_metrics_daily.baseline_revenue_per_session":
            safeDivide(
              +dataItem["company_revenue_metrics_daily.baseline_revenue"],
              +dataItem["company_revenue_metrics_daily.baseline_sessions"],
            ),
          "company_revenue_metrics_daily.triggered_revenue_per_session":
            safeDivide(
              +dataItem["company_revenue_metrics_daily.triggered_revenue"],
              +dataItem["company_revenue_metrics_daily.triggered_sessions"],
            ),
          "company_revenue_metrics_daily.paid_revenue_per_session": safeDivide(
            +dataItem["company_revenue_metrics_daily.paid_revenue"],
            +dataItem["company_revenue_metrics_daily.paid_sessions"],
          ),
        })),
      ]
    },
  },
  ga_mp_session_paid_triggered_and_baseline_traffic_data_table: {
    q: {
      measures: [
        "metrics.ga_multi_property_baseline_sessions",
        "metrics.ga_multi_property_baseline_revenue",
        "metrics.ga_multi_property_triggered_sessions",
        "metrics.ga_multi_property_triggered_revenue",
        "metrics.ga_multi_property_paid_revenue",
        "metrics.ga_multi_property_paid_sessions",
      ],
      dimensions: ["metrics.companyid"],
      timeDimensions: [
        {
          dimension: "metrics.date",
        },
      ],
    },
    postProcess(data: any[]) {
      return [
        data.map(dataItem => ({
          ...dataItem,
          "metrics.ga_multi_property_baseline_revenue_per_session": safeDivide(
            +dataItem["metrics.ga_multi_property_baseline_revenue"],
            +dataItem["metrics.ga_multi_property_baseline_sessions"],
          ),
          "metrics.ga_multi_property_triggered_revenue_per_session": safeDivide(
            +dataItem["metrics.ga_multi_property_triggered_revenue"],
            +dataItem["metrics.ga_multi_property_triggered_sessions"],
          ),
          "metrics.ga_multi_property_paid_revenue_per_session": safeDivide(
            +dataItem["metrics.ga_multi_property_paid_revenue"],
            +dataItem["metrics.ga_multi_property_paid_sessions"],
          ),
        })),
      ]
    },
  },
  ga_baseline_traffic_vs_revenue_stacked_chart: {
    q: {
      measures: ["metrics.ga_baseline_revenue", "metrics.ga_baseline_sessions"],
      timeDimensions: [
        {
          dimension: "metrics.date",
        },
      ],
      order: {
        "metrics.date": "asc",
      },
    },
  },
  ga_mp_baseline_traffic_vs_revenue_stacked_chart: {
    q: {
      measures: [
        "metrics.ga_multi_property_baseline_revenue",
        "metrics.ga_multi_property_baseline_sessions",
      ],
      timeDimensions: [
        {
          dimension: "metrics.date",
        },
      ],
      order: {
        "metrics.date": "asc",
      },
    },
  },
  ga_sessions_from_organic_sessions: {
    q: {
      measures: [
        "metrics.ga_sessions_from_organic_sessions",
        "metrics.ga_revenue_from_organic_sessions",
      ],
      filters: [],
      order: {
        "metrics.date": "asc",
      },
      timeDimensions: [
        {
          dimension: "metrics.date",
        },
      ],
    },
  },
  ga_sessions_from_direct_sessions: {
    q: {
      measures: [
        "metrics.ga_sessions_from_direct_sessions",
        "metrics.ga_revenue_from_direct_sessions",
      ],
      filters: [],
      order: {
        "metrics.date": "asc",
      },
      timeDimensions: [
        {
          dimension: "metrics.date",
        },
      ],
    },
  },
  ga_sessions_from_organic_referal_sessions: {
    q: {
      measures: [
        "metrics.ga_sessions_from_organic_referal_sessions",
        "metrics.ga_revenue_from_organic_referral_sessions",
      ],
      filters: [],
      order: {
        "metrics.date": "asc",
      },
      timeDimensions: [
        {
          dimension: "metrics.date",
        },
      ],
    },
  },
  ga_mp_sessions_from_organic_sessions: {
    q: {
      measures: [
        "metrics.ga_multi_property_sessions_from_organic_sessions",
        "metrics.ga_multi_property_revenue_from_organic_sessions",
      ],
      filters: [],
      order: {
        "metrics.date": "asc",
      },
      timeDimensions: [
        {
          dimension: "metrics.date",
        },
      ],
    },
  },
  ga_mp_sessions_from_direct_sessions: {
    q: {
      measures: [
        "metrics.ga_multi_property_sessions_from_direct_sessions",
        "metrics.ga_multi_property_revenue_from_direct_sessions",
      ],
      filters: [],
      order: {
        "metrics.date": "asc",
      },
      timeDimensions: [
        {
          dimension: "metrics.date",
        },
      ],
    },
  },
  ga_mp_sessions_from_organic_referal_sessions: {
    q: {
      measures: [
        "metrics.ga_multi_property_sessions_from_organic_referal_sessions",
        "metrics.ga_multi_property_revenue_from_organic_referral_sessions",
      ],
      filters: [],
      order: {
        "metrics.date": "asc",
      },
      timeDimensions: [
        {
          dimension: "metrics.date",
        },
      ],
    },
  },
  session_source_and_medium_traffic_data_table: {
    q: {
      measures: [
        "company_revenue_metrics_daily.baseline_sessions",
        "company_revenue_metrics_daily.baseline_engaged_sessions",
        "company_revenue_metrics_daily.baseline_new_sessions",
        "company_revenue_metrics_daily.baseline_revenue",
      ],
      dimensions: [
        "company_revenue_metrics_daily.sessionsource",
        "company_revenue_metrics_daily.sessionmedium",
        "company_revenue_metrics_daily.companyid",
      ],
      timeDimensions: [
        {
          dimension: "company_revenue_metrics_daily.date",
        },
      ],
      filters: [
        {
          member: "company_revenue_metrics_daily.baseline_sessions",
          operator: "gt",
          values: ["0"],
        },
      ],
    },
    postProcess(data: any[]) {
      return [
        data.map(dataItem => ({
          ...dataItem,
          "company_revenue_metrics_daily.baseline_revenue_per_session":
            safeDivide(
              dataItem["company_revenue_metrics_daily.baseline_revenue"],
              dataItem["company_revenue_metrics_daily.baseline_sessions"],
            ),
        })),
      ]
    },
  },
  branded_search_clicks_stacked_chart: {
    q: {
      measures: [
        "metrics.google_search_organic_branded_search_clicks",
        "metrics.google_ads_paid_branded_search_clicks",
        "metrics.google_ads_paid_branded_search_total_spend",
      ],
      filters: [
        // TODO: Delete later if necessary
        {
          member: "metrics.metric",
          operator: "equals",
          values: [
            "google_search_organic_branded_search_clicks",
            "google_ads_paid_branded_search_clicks",
            "google_ads_paid_branded_search_total_spend",
          ],
        },
      ],
      order: {
        "metrics.date": "asc",
      },
      timeDimensions: [
        {
          dimension: "metrics.date",
        },
      ],
    },
  },
  brand_value_cohort_table: {
    q: {
      measures: [
        "company_modeled_metrics_monthly.engagements",
        "company_modeled_metrics_monthly.conversionMonth1",
        "company_modeled_metrics_monthly.conversionMonth2",
        "company_modeled_metrics_monthly.conversionMonth3",
        "company_modeled_metrics_monthly.conversionMonth4",
        "company_modeled_metrics_monthly.conversionMonth5",
        "company_modeled_metrics_monthly.conversionMonth6",
        // "company_modeled_metrics_monthly.conversionMonth7",
        // "company_modeled_metrics_monthly.conversionMonth8",
        // "company_modeled_metrics_monthly.conversionMonth9",
        // "company_modeled_metrics_monthly.conversionMonth10",
        // "company_modeled_metrics_monthly.conversionMonth11",
        // "company_modeled_metrics_monthly.conversionMonth12",
      ],
      filters: [],
      order: {
        "company_modeled_metrics_monthly.engagement_date": "asc",
      },
      timeDimensions: [
        {
          dimension: "company_modeled_metrics_monthly.engagement_date",
        },
      ],
    },
  },
  brand_value_conversions_table: {
    q: {
      measures: [
        "company_modeled_metrics_monthly.engagements",
        "company_modeled_metrics_monthly.revenue90Days",
        "company_modeled_metrics_monthly.revenue180Days",
      ],
      dimensions: ["company_modeled_metrics_monthly.metric"],
      filters: [],
      timeDimensions: [
        {
          dimension: "company_modeled_metrics_monthly.engagement_date",
        },
      ],
    },
    postProcess: (data: any[]) => {
      const engagements = "company_modeled_metrics_monthly.engagements"
      const revenue90Days = "company_modeled_metrics_monthly.revenue90Days"
      const revenue180Days = "company_modeled_metrics_monthly.revenue180Days"
      const rpe90Days = "company_modeled_metrics_monthly.rpe90Days"
      const rpe180Days = "company_modeled_metrics_monthly.rpe180Days"

      return [
        data.map((metric: any) => ({
          ...metric,
          [rpe90Days]: safeDivide(metric[revenue90Days], metric[engagements]),
          [rpe180Days]: safeDivide(metric[revenue180Days], metric[engagements]),
        })),
      ]
    },
  },
  brand_value_conversions_table_summary: {
    q: {
      measures: [
        "company_modeled_metrics_monthly.engagements",
        "company_modeled_metrics_monthly.revenue90Days",
        "company_modeled_metrics_monthly.revenue180Days",
      ],
      dimensions: ["company_modeled_metrics_monthly.metric"],
      filters: [],
    },
    postProcess: (data: any[]) => {
      const engagements = "company_modeled_metrics_monthly.engagements"
      const revenue90Days = "company_modeled_metrics_monthly.revenue90Days"
      const revenue180Days = "company_modeled_metrics_monthly.revenue180Days"
      const rpe90Days = "company_modeled_metrics_monthly.rpe90Days"
      const rpe180Days = "company_modeled_metrics_monthly.rpe180Days"

      return [
        data.map((metric: any) => ({
          ...metric,
          [rpe90Days]: safeDivide(metric[revenue90Days], metric[engagements]),
          [rpe180Days]: safeDivide(metric[revenue180Days], metric[engagements]),
        })),
      ]
    },
  },
  brand_value_stacked_chart: {
    q: ({
      baselineRevenueSourceMetric = DEFAULT_BASELINE_REVENUE_METRIC,
    }: {
      baselineRevenueSourceMetric?: string
    }) => [
      {
        measures: [
          "company_modeled_metrics_monthly.engagements",
          "company_modeled_metrics_monthly.conversions",
        ],
        order: {
          "company_modeled_metrics_monthly.engagement_date": "asc",
        } as TMetric["order"],
        timeDimensions: [
          {
            dimension: "company_modeled_metrics_monthly.engagement_date",
          },
        ],
      },
      {
        measures: [`metrics.${baselineRevenueSourceMetric}`],
        order: {
          "metrics.date": "asc",
        } as TMetric["order"],
        timeDimensions: [
          {
            dimension: "metrics.date",
          },
        ],
      },
    ],
    postProcess: (
      data: any[],
      {
        baselineRevenueSourceMetric = DEFAULT_BASELINE_REVENUE_METRIC,
        granularity = AggregationType.DAILY,
      }: {
        baselineRevenueSourceMetric?: string
        granularity?: string
      },
    ) => {
      const gran = GranularityMapping[granularity]
      const baselineDateKey = `metrics.date.${gran}`
      const baselineValueKey = `metrics.${baselineRevenueSourceMetric}`
      const modeledDateKey = `company_modeled_metrics_monthly.engagement_date.${gran}`
      const modeledConversionsKey =
        "company_modeled_metrics_monthly.conversions"
      const modeledEngagementsKey =
        "company_modeled_metrics_monthly.engagements"

      const newData: brandValueStackedChartDataPoint = {}
      for (const dataPoint of data) {
        const currentKey =
          dataPoint[baselineDateKey] || dataPoint[modeledDateKey]

        if (!newData[currentKey]) {
          newData[currentKey] = { date: currentKey }
        }

        if (dataPoint[baselineDateKey]) {
          newData[currentKey].baseline_revenue = dataPoint[baselineValueKey]
        }
        if (dataPoint[modeledDateKey]) {
          newData[currentKey].engagements = dataPoint[modeledEngagementsKey]
          newData[currentKey].conversions = dataPoint[modeledConversionsKey]
        }
      }

      const result = Object.values(newData)

      const columns = [
        {
          key: "date",
          dataIndex: `date.${gran}`,
        },
        {
          key: "engagements",
          type: "number",
          dataIndex: "engagements",
          title: "Company Modeled Metrics Monthly Engagements",
          shortTitle: "Engagements",
        },
        {
          key: "conversions",
          type: "currency",
          dataIndex: "conversions",
          title: "Company Modeled Metrics Monthly Conversions",
          shortTitle: "Conversions",
        },
        {
          key: "baseline_revenue",
          type: "currency",
          dataIndex: "baseline_revenue",
          title: "Daily Metrics GA Baseline Revenue",
          shortTitle: "GA Baseline Revenue",
        },
      ]

      return [result, columns]
    },
  },
  brand_value_line_chart: {
    q: {
      measures: ["company_modeled_metrics_monthly.conversions"],
      order: {
        "company_modeled_metrics_monthly.engagement_date": "asc",
      },
      timeDimensions: [
        {
          dimension: "company_modeled_metrics_monthly.engagement_date",
        },
      ],
    },
  },
  brand_media_optimization_campaign_table: {
    q: {
      measures: [
        `company_media_metrics_campaigns.spend`,
        `company_media_metrics_campaigns.shares`,
        `company_media_metrics_campaigns.likes`,
        `company_media_metrics_campaigns.comments`,
        `company_media_metrics_campaigns.follows`,
        `company_media_metrics_campaigns.saves`,
        `company_media_metrics_campaigns.purchases`,
        `company_media_metrics_campaigns.brand_value_agg`,
        `company_media_metrics_campaigns.short_term_revenue_roas`,
        `company_media_metrics_campaigns.brand_value_over_spend`,
        `company_media_metrics_campaigns.long_term_revenue_roas`,
        `company_media_metrics_campaigns.facebook_ads_spend`,
        `company_media_metrics_campaigns.facebook_ads_comments`,
        `company_media_metrics_campaigns.facebook_ads_shares`,
        `company_media_metrics_campaigns.facebook_ads_new_fans`,
        `company_media_metrics_campaigns.facebook_ads_reactions`,
        `company_media_metrics_campaigns.facebook_ads_purchases`,
        `company_media_metrics_campaigns.facebook_ads_saves`,
        `company_media_metrics_campaigns.instagram_ads_spend`,
        `company_media_metrics_campaigns.instagram_ads_follows`,
        `company_media_metrics_campaigns.instagram_ads_comments`,
        `company_media_metrics_campaigns.instagram_ads_likes`,
        `company_media_metrics_campaigns.instagram_ads_shares`,
        `company_media_metrics_campaigns.instagram_ads_purchases`,
        `company_media_metrics_campaigns.instagram_ads_saves`,
        `company_media_metrics_campaigns.tiktok_ads_spend`,
        `company_media_metrics_campaigns.tiktok_ads_follows`,
        `company_media_metrics_campaigns.tiktok_ads_comments`,
        `company_media_metrics_campaigns.tiktok_ads_shares`,
        `company_media_metrics_campaigns.tiktok_ads_likes`,
        `company_media_metrics_campaigns.tiktok_ads_purchases`,
        `company_media_metrics_campaigns.long_term_revenue`,
      ],
      dimensions: [
        `company_media_metrics_campaigns.media_channel`,
        `company_media_metrics_campaigns.campaign_name`,
        `company_media_metrics_campaigns.campaign_status`,
        `company_media_metrics_campaigns.campaign_id`,
      ],
      timeDimensions: [
        {
          dimension: "company_media_metrics_campaigns.md_date",
        },
      ],
    },
    postProcess: (data: any[]) => {
      const prefix = "company_media_metrics_campaigns"
      return [
        data.map(item => {
          const itemWithSimpleKeys: Record<string, any> = {}

          Object.keys(item).forEach(key => {
            const shortKey = key.replace(`${prefix}.`, "")
            itemWithSimpleKeys[shortKey] = item[key]
          })
          return {
            ...itemWithSimpleKeys,
            media_channel:
              MEDIA_CHANNEL_TO_SOURCE_ID_MAPPING[
                itemWithSimpleKeys.media_channel
              ] ?? itemWithSimpleKeys.media_channel,
            instagram_ads_follows: "--",
          }
        }),
      ]
    },
  },
  brand_media_optimization_campaign_table_summary: {
    q: {
      measures: [
        `company_media_metrics_campaigns.spend`,
        `company_media_metrics_campaigns.shares`,
        `company_media_metrics_campaigns.likes`,
        `company_media_metrics_campaigns.comments`,
        `company_media_metrics_campaigns.follows`,
        `company_media_metrics_campaigns.saves`,
        `company_media_metrics_campaigns.purchases`,
        `company_media_metrics_campaigns.brand_value_agg`,
        `company_media_metrics_campaigns.facebook_ads_spend`,
        `company_media_metrics_campaigns.facebook_ads_comments`,
        `company_media_metrics_campaigns.facebook_ads_shares`,
        `company_media_metrics_campaigns.facebook_ads_new_fans`,
        `company_media_metrics_campaigns.facebook_ads_reactions`,
        `company_media_metrics_campaigns.facebook_ads_purchases`,
        `company_media_metrics_campaigns.facebook_ads_saves`,
        `company_media_metrics_campaigns.instagram_ads_spend`,
        `company_media_metrics_campaigns.instagram_ads_follows`,
        `company_media_metrics_campaigns.instagram_ads_comments`,
        `company_media_metrics_campaigns.instagram_ads_likes`,
        `company_media_metrics_campaigns.instagram_ads_shares`,
        `company_media_metrics_campaigns.instagram_ads_purchases`,
        `company_media_metrics_campaigns.instagram_ads_saves`,
        `company_media_metrics_campaigns.tiktok_ads_spend`,
        `company_media_metrics_campaigns.tiktok_ads_follows`,
        `company_media_metrics_campaigns.tiktok_ads_comments`,
        `company_media_metrics_campaigns.tiktok_ads_shares`,
        `company_media_metrics_campaigns.tiktok_ads_likes`,
        `company_media_metrics_campaigns.tiktok_ads_purchases`,
        `company_media_metrics_campaigns.long_term_revenue`,
      ],
      timeDimensions: [
        {
          dimension: "company_media_metrics_campaigns.md_date",
        },
      ],
    },
    postProcess: (data: any[]) => {
      const prefix = "company_media_metrics_campaigns"
      return [
        data.map(item => {
          const itemWithSimpleKeys: Record<string, any> = {}

          Object.keys(item).forEach(key => {
            const shortKey = key.replace(`${prefix}.`, "")
            itemWithSimpleKeys[shortKey] = item[key]
          })

          return {
            ...itemWithSimpleKeys,
            brand_value: itemWithSimpleKeys.brand_value_agg,
            short_term_revenue_roas: safeDivide(
              itemWithSimpleKeys.purchases,
              itemWithSimpleKeys.spend,
            ),
            brand_value_over_spend: safeDivide(
              itemWithSimpleKeys.brand_value_agg,
              itemWithSimpleKeys.spend,
            ),
            long_term_revenue_roas: safeDivide(
              itemWithSimpleKeys.long_term_revenue,
              itemWithSimpleKeys.spend,
            ),
          }
        }),
      ]
    },
  },
  brand_media_optimization_adsets_table: {
    q: {
      measures: [
        `company_media_metrics_adsets.spend`,
        `company_media_metrics_adsets.shares`,
        `company_media_metrics_adsets.likes`,
        `company_media_metrics_adsets.comments`,
        `company_media_metrics_adsets.follows`,
        `company_media_metrics_adsets.saves`,
        `company_media_metrics_adsets.purchases`,
        `company_media_metrics_adsets.brand_value_agg`,
        `company_media_metrics_adsets.short_term_revenue_roas`,
        `company_media_metrics_adsets.brand_value_over_spend`,
        `company_media_metrics_adsets.long_term_revenue_roas`,
        `company_media_metrics_adsets.facebook_ads_spend`,
        `company_media_metrics_adsets.facebook_ads_comments`,
        `company_media_metrics_adsets.facebook_ads_shares`,
        `company_media_metrics_adsets.facebook_ads_new_fans`,
        `company_media_metrics_adsets.facebook_ads_reactions`,
        `company_media_metrics_adsets.facebook_ads_purchases`,
        `company_media_metrics_adsets.facebook_ads_saves`,
        `company_media_metrics_adsets.instagram_ads_spend`,
        `company_media_metrics_adsets.instagram_ads_follows`,
        `company_media_metrics_adsets.instagram_ads_comments`,
        `company_media_metrics_adsets.instagram_ads_likes`,
        `company_media_metrics_adsets.instagram_ads_shares`,
        `company_media_metrics_adsets.instagram_ads_purchases`,
        `company_media_metrics_adsets.instagram_ads_saves`,
        `company_media_metrics_adsets.tiktok_ads_spend`,
        `company_media_metrics_adsets.tiktok_ads_follows`,
        `company_media_metrics_adsets.tiktok_ads_comments`,
        `company_media_metrics_adsets.tiktok_ads_shares`,
        `company_media_metrics_adsets.tiktok_ads_likes`,
        `company_media_metrics_adsets.tiktok_ads_purchases`,
        `company_media_metrics_adsets.long_term_revenue`,
      ],
      dimensions: [
        `company_media_metrics_adsets.media_channel`,
        `company_media_metrics_adsets.campaign_name`,
        `company_media_metrics_adsets.campaign_status`,
        `company_media_metrics_adsets.adset_name`,
        `company_media_metrics_adsets.adset_status`,
        `company_media_metrics_adsets.adset_id`,
        `company_media_metrics_adsets.campaign_id`,
      ],
      timeDimensions: [
        {
          dimension: "company_media_metrics_adsets.md_date",
        },
      ],
    },
    postProcess: (data: any[]) => {
      const prefix = `company_media_metrics_adsets`
      return [
        data.map(item => {
          const itemWithSimpleKeys: Record<string, any> = {}

          Object.keys(item).forEach(key => {
            const shortKey = key.replace(`${prefix}.`, "")
            itemWithSimpleKeys[shortKey] = item[key]
          })
          return {
            ...itemWithSimpleKeys,
            media_channel:
              MEDIA_CHANNEL_TO_SOURCE_ID_MAPPING[
                itemWithSimpleKeys.media_channel
              ] ?? itemWithSimpleKeys.media_channel,
            instagram_ads_follows: "--",
          }
        }),
      ]
    },
  },
  brand_media_optimization_adsets_table_summary: {
    q: {
      measures: [
        `company_media_metrics_adsets.spend`,
        `company_media_metrics_adsets.shares`,
        `company_media_metrics_adsets.likes`,
        `company_media_metrics_adsets.comments`,
        `company_media_metrics_adsets.follows`,
        `company_media_metrics_adsets.saves`,
        `company_media_metrics_adsets.purchases`,
        `company_media_metrics_adsets.brand_value_agg`,
        `company_media_metrics_adsets.facebook_ads_spend`,
        `company_media_metrics_adsets.facebook_ads_comments`,
        `company_media_metrics_adsets.facebook_ads_shares`,
        `company_media_metrics_adsets.facebook_ads_new_fans`,
        `company_media_metrics_adsets.facebook_ads_reactions`,
        `company_media_metrics_adsets.facebook_ads_saves`,
        `company_media_metrics_adsets.facebook_ads_purchases`,
        `company_media_metrics_adsets.instagram_ads_spend`,
        `company_media_metrics_adsets.instagram_ads_follows`,
        `company_media_metrics_adsets.instagram_ads_comments`,
        `company_media_metrics_adsets.instagram_ads_likes`,
        `company_media_metrics_adsets.instagram_ads_shares`,
        `company_media_metrics_adsets.instagram_ads_purchases`,
        `company_media_metrics_adsets.instagram_ads_saves`,
        `company_media_metrics_adsets.tiktok_ads_spend`,
        `company_media_metrics_adsets.tiktok_ads_follows`,
        `company_media_metrics_adsets.tiktok_ads_comments`,
        `company_media_metrics_adsets.tiktok_ads_shares`,
        `company_media_metrics_adsets.tiktok_ads_likes`,
        `company_media_metrics_adsets.tiktok_ads_purchases`,
        `company_media_metrics_adsets.long_term_revenue`,
      ],
      timeDimensions: [
        {
          dimension: "company_media_metrics_adsets.md_date",
        },
      ],
    },
    postProcess: (data: any[]) => {
      const prefix = `company_media_metrics_adsets`
      return [
        data.map(item => {
          const itemWithSimpleKeys: Record<string, any> = {}

          Object.keys(item).forEach(key => {
            const shortKey = key.replace(`${prefix}.`, "")
            itemWithSimpleKeys[shortKey] = item[key]
          })
          return {
            ...itemWithSimpleKeys,
            brand_value: itemWithSimpleKeys.brand_value_agg,
            short_term_revenue_roas: safeDivide(
              itemWithSimpleKeys.purchases,
              itemWithSimpleKeys.spend,
            ),
            brand_value_over_spend: safeDivide(
              itemWithSimpleKeys.brand_value_agg,
              itemWithSimpleKeys.spend,
            ),
            long_term_revenue_roas: safeDivide(
              itemWithSimpleKeys.long_term_revenue,
              itemWithSimpleKeys.spend,
            ),
          }
        }),
      ]
    },
  },
  brand_media_optimization_ads_table: {
    q: {
      measures: [
        `company_media_metrics_ads.spend`,
        `company_media_metrics_ads.shares`,
        `company_media_metrics_ads.likes`,
        `company_media_metrics_ads.comments`,
        `company_media_metrics_ads.follows`,
        `company_media_metrics_ads.saves`,
        `company_media_metrics_ads.purchases`,
        `company_media_metrics_ads.brand_value_agg`,
        `company_media_metrics_ads.short_term_revenue_roas`,
        `company_media_metrics_ads.brand_value_over_spend`,
        `company_media_metrics_ads.long_term_revenue_roas`,
        `company_media_metrics_ads.facebook_ads_spend`,
        `company_media_metrics_ads.facebook_ads_comments`,
        `company_media_metrics_ads.facebook_ads_shares`,
        `company_media_metrics_ads.facebook_ads_new_fans`,
        `company_media_metrics_ads.facebook_ads_reactions`,
        `company_media_metrics_ads.facebook_ads_saves`,
        `company_media_metrics_ads.facebook_ads_purchases`,
        `company_media_metrics_ads.instagram_ads_spend`,
        `company_media_metrics_ads.instagram_ads_follows`,
        `company_media_metrics_ads.instagram_ads_comments`,
        `company_media_metrics_ads.instagram_ads_likes`,
        `company_media_metrics_ads.instagram_ads_shares`,
        `company_media_metrics_ads.instagram_ads_purchases`,
        `company_media_metrics_ads.instagram_ads_saves`,
        `company_media_metrics_ads.tiktok_ads_spend`,
        `company_media_metrics_ads.tiktok_ads_follows`,
        `company_media_metrics_ads.tiktok_ads_comments`,
        `company_media_metrics_ads.tiktok_ads_shares`,
        `company_media_metrics_ads.tiktok_ads_likes`,
        `company_media_metrics_ads.tiktok_ads_purchases`,
        `company_media_metrics_ads.long_term_revenue`,
      ],
      dimensions: [
        `company_media_metrics_ads.media_channel`,
        `company_media_metrics_ads.campaign_name`,
        `company_media_metrics_ads.campaign_status`,
        `company_media_metrics_ads.adset_name`,
        `company_media_metrics_ads.adset_status`,
        `company_media_metrics_ads.ad_name`,
        `company_media_metrics_ads.ad_status`,
        `company_media_metrics_ads.ad_id`,
        `company_media_metrics_ads.adset_id`,
        `company_media_metrics_ads.campaign_id`,
        `company_media_metrics_ads.ad_link`,
      ],
      timeDimensions: [
        {
          dimension: "company_media_metrics_ads.md_date",
        },
      ],
    },
    postProcess: (data: any[]) => {
      const prefix = "company_media_metrics_ads"
      return [
        data.map(item => {
          const itemWithSimpleKeys: Record<string, any> = {}

          Object.keys(item).forEach(key => {
            const shortKey = key.replace(`${prefix}.`, "")
            itemWithSimpleKeys[shortKey] = item[key]
          })
          return {
            ...itemWithSimpleKeys,
            media_channel:
              MEDIA_CHANNEL_TO_SOURCE_ID_MAPPING[
                itemWithSimpleKeys.media_channel
              ] ?? itemWithSimpleKeys.media_channel,
            instagram_ads_follows: "--",
          }
        }),
      ]
    },
  },
  brand_media_optimization_ads_table_summary: {
    q: {
      measures: [
        `company_media_metrics_ads.spend`,
        `company_media_metrics_ads.shares`,
        `company_media_metrics_ads.likes`,
        `company_media_metrics_ads.comments`,
        `company_media_metrics_ads.follows`,
        `company_media_metrics_ads.saves`,
        `company_media_metrics_ads.purchases`,
        `company_media_metrics_ads.brand_value_agg`,
        `company_media_metrics_ads.facebook_ads_spend`,
        `company_media_metrics_ads.facebook_ads_comments`,
        `company_media_metrics_ads.facebook_ads_shares`,
        `company_media_metrics_ads.facebook_ads_new_fans`,
        `company_media_metrics_ads.facebook_ads_reactions`,
        `company_media_metrics_ads.facebook_ads_saves`,
        `company_media_metrics_ads.facebook_ads_purchases`,
        `company_media_metrics_ads.instagram_ads_spend`,
        `company_media_metrics_ads.instagram_ads_follows`,
        `company_media_metrics_ads.instagram_ads_comments`,
        `company_media_metrics_ads.instagram_ads_likes`,
        `company_media_metrics_ads.instagram_ads_shares`,
        `company_media_metrics_ads.instagram_ads_purchases`,
        `company_media_metrics_ads.instagram_ads_saves`,
        `company_media_metrics_ads.tiktok_ads_spend`,
        `company_media_metrics_ads.tiktok_ads_follows`,
        `company_media_metrics_ads.tiktok_ads_comments`,
        `company_media_metrics_ads.tiktok_ads_shares`,
        `company_media_metrics_ads.tiktok_ads_likes`,
        `company_media_metrics_ads.tiktok_ads_purchases`,
        `company_media_metrics_ads.long_term_revenue`,
      ],
      timeDimensions: [
        {
          dimension: "company_media_metrics_ads.md_date",
        },
      ],
    },
    postProcess: (data: any[]) => {
      const prefix = `company_media_metrics_ads`
      return [
        data.map(item => {
          const itemWithSimpleKeys: Record<string, any> = {}

          Object.keys(item).forEach(key => {
            const shortKey = key.replace(`${prefix}.`, "")
            itemWithSimpleKeys[shortKey] = item[key]
          })
          return {
            ...itemWithSimpleKeys,
            brand_value: itemWithSimpleKeys.brand_value_agg,
            short_term_revenue_roas: safeDivide(
              itemWithSimpleKeys.purchases,
              itemWithSimpleKeys.spend,
            ),
            brand_value_over_spend: safeDivide(
              itemWithSimpleKeys.brand_value_agg,
              itemWithSimpleKeys.spend,
            ),
            long_term_revenue_roas: safeDivide(
              itemWithSimpleKeys.long_term_revenue,
              itemWithSimpleKeys.spend,
            ),
          }
        }),
      ]
    },
  },
  brand_media_organic_social_posts_table: {
    q: {
      dimensions: [
        "company_media_metrics_organic_posts.post_id",
        "company_media_metrics_organic_posts.media_channel",
        "company_media_metrics_organic_posts.post_preview",
        "company_media_metrics_organic_posts.post_caption",
        "company_media_metrics_organic_posts.post_link",
      ],
      measures: [
        "company_media_metrics_organic_posts.brand_value_agg",
        "company_media_metrics_organic_posts.comments",
        "company_media_metrics_organic_posts.follows",
        "company_media_metrics_organic_posts.likes",
        "company_media_metrics_organic_posts.reach",
        "company_media_metrics_organic_posts.saves",
        "company_media_metrics_organic_posts.shares",
        "company_media_metrics_organic_posts.brand_value_reach",
      ],
      timeDimensions: [
        {
          dimension: "company_media_metrics_organic_posts.md_date",
        },
      ],
    },
    postProcess: (data: any[]) => {
      const savesKey = "company_media_metrics_organic_posts.saves"
      const followsKey = "company_media_metrics_organic_posts.follows"
      const mediaChannelKey =
        "company_media_metrics_organic_posts.media_channel"
      const brandValueKey =
        "company_media_metrics_organic_posts.brand_value_agg"
      const brandValueReachKey =
        "company_media_metrics_organic_posts.brand_value_reach"
      const reachKey = "company_media_metrics_organic_posts.reach"

      return [
        data.map(item => ({
          ...item,
          [brandValueReachKey]:
            item[brandValueKey] === null || item[reachKey] === 0
              ? null
              : item[brandValueReachKey],
          [savesKey]:
            item[mediaChannelKey] === "tiktok" ? "--" : item[savesKey],
          [followsKey]:
            item[mediaChannelKey] === "tiktok" ? "--" : item[followsKey],
        })),
      ]
    },
  },
  brand_media_organic_social_posts_table_summary: {
    q: {
      measures: [
        "company_media_metrics_organic_posts.brand_value_agg",
        "company_media_metrics_organic_posts.comments",
        "company_media_metrics_organic_posts.follows",
        "company_media_metrics_organic_posts.likes",
        "company_media_metrics_organic_posts.reach",
        "company_media_metrics_organic_posts.saves",
        "company_media_metrics_organic_posts.shares",
      ],
      timeDimensions: [
        {
          dimension: "company_media_metrics_organic_posts.md_date",
        },
      ],
    },
    postProcess: (data: any[]) => {
      const brandValueKeyAgg =
        "company_media_metrics_organic_posts.brand_value_agg"
      const brandValueReachKey =
        "company_media_metrics_organic_posts.brand_value_reach"
      const reachKey = "company_media_metrics_organic_posts.reach"

      return [
        data.map(item => ({
          ...item,
          [brandValueReachKey]: safeDivide(
            item[brandValueKeyAgg],
            item[reachKey],
          ),
        })),
      ]
    },
  },
  brand_media_media_mentions_table: {
    q: {
      dimensions: [
        "company_media_metrics_mentions.post_id",
        "company_media_metrics_mentions.media_channel",
        "company_media_metrics_mentions.post_caption",
        "company_media_metrics_mentions.post_link",
        "company_media_metrics_mentions.md_date",
        "company_media_metrics_mentions.creator_name",
        "company_media_metrics_mentions.display_url",
        "company_media_metrics_mentions.avatar_url",
      ],
      measures: [
        "company_media_metrics_mentions.comments",
        "company_media_metrics_mentions.followers",
        "company_media_metrics_mentions.likes",
        "company_media_metrics_mentions.reach",
        "company_media_metrics_mentions.saves",
        "company_media_metrics_mentions.shares",
        "company_media_metrics_mentions.brand_value_reach",
        "company_media_metrics_mentions.brand_value_agg",
      ],
      timeDimensions: [
        {
          dimension: "company_media_metrics_mentions.md_date",
        },
      ],
    },
    postProcess: (data: any[]) => {
      const savesKey = "company_media_metrics_mentions.saves"
      const followersKey = "company_media_metrics_mentions.followers"
      const mediaChannelKey = "company_media_metrics_mentions.media_channel"
      const brandValueReachKey =
        "company_media_metrics_mentions.brand_value_reach"
      const reachKey = "company_media_metrics_mentions.reach"
      const brandValueKey = "company_media_metrics_mentions.brand_value_agg"

      return [
        data.map(item => ({
          ...item,
          [brandValueReachKey]:
            item[brandValueKey] === null || item[reachKey] === 0
              ? null
              : item[brandValueReachKey],
          [savesKey]:
            item[mediaChannelKey] === "tiktok" ? "--" : item[savesKey],
          [followersKey]:
            item[mediaChannelKey] === "tiktok" ? "--" : item[followersKey],
        })),
      ]
    },
  },
  brand_media_media_mentions_table_summary: {
    q: {
      measures: [
        "company_media_metrics_mentions.brand_value_agg",
        "company_media_metrics_mentions.comments",
        "company_media_metrics_mentions.followers",
        "company_media_metrics_mentions.likes",
        "company_media_metrics_mentions.reach",
        "company_media_metrics_mentions.saves",
        "company_media_metrics_mentions.shares",
      ],
      timeDimensions: [
        {
          dimension: "company_media_metrics_mentions.md_date",
        },
      ],
    },
  },
  subscribe_page_paid_media_card: {
    q: {
      measures: ["company_media_metrics_monthly_campaigns.brand_value_agg"],
      dimensions: [
        "company_media_metrics_monthly_campaigns.media_channel",
        "company_media_metrics_monthly_campaigns.campaign_name",
      ],
      limit: 4,
      order: {
        "company_media_metrics_monthly_campaigns.brand_value_agg": "desc",
      },
    },
  },
  subscribe_page_organic_media_card: {
    q: {
      measures: ["company_media_metrics_organic_posts.brand_value_agg"],
      dimensions: [
        "company_media_metrics_organic_posts.media_channel",
        "company_media_metrics_organic_posts.post_caption",
      ],
      limit: 4,
      order: {
        "company_media_metrics_organic_posts.brand_value_agg": "desc",
      },
    },
  },
  subscribe_page_brand_value_card: {
    q: {
      measures: ["company_modeled_metrics_monthly.conversions"],
      timeDimensions: [
        {
          dimension: "company_modeled_metrics_monthly.conversion_start",
          dateRange: "last 12 months",
        },
      ],
      limit: 1,
    },
  },
}
