import { useEffect } from "react"
import WithLoader from "~/ui-rtk/components/layout/WithLoader/WithLoader"
import useConnect from "./connect"
import BrandHealthOverviewDashboard from "~/ui-rtk/components/features/dashboards/BrandHealthOverviewDashboard/BrandHealthOverviewDashboard"
import WithSources from "~/ui-rtk/components/layout/WithSources/WithSources"
import useReconnectSourcesBanner from "~/ui-rtk/hooks/reconnect-sources-banner"
import ReconnectBanner from "~/ui-rtk/components/layout/ReconnectBanner/ReconnectBanner"

export default function BrandsHealthOverview() {
  const { content, isLoading, requiredSources } = useConnect()

  const { connectorsToReconnect, setRequiredSources } =
    useReconnectSourcesBanner()

  useEffect(() => {
    setRequiredSources(requiredSources)
  }, [requiredSources])

  return (
    <WithLoader isLoading={isLoading}>
      <WithSources>
        <ReconnectBanner connectors={connectorsToReconnect} />
        <BrandHealthOverviewDashboard
          slug={content.slug}
          charts={content.content.charts}
          title={content.content.title}
          parent={content.parent}
          periodPicker={content.content.periodPicker}
        />
      </WithSources>
    </WithLoader>
  )
}
