import React, { useMemo } from "react"
import { ColumnOrderState, Row } from "@tanstack/react-table"
import {
  SortableContext,
  horizontalListSortingStrategy,
} from "@dnd-kit/sortable"

import { BodyCell } from "./BodyCell"
import { TMetric } from "~/ui-rtk/constants/table-visualization-types"
import { cn } from "~/ui-rtk/utils/tailwind-utils"

type TTableBodyProps = {
  rows: Row<TMetric>[]
  colsLength: number
  countPerPage: number
  columnOrder: ColumnOrderState
  disableHover?: boolean
  onRowClick: (data: TMetric) => void
  id?: string
  demoMode?: boolean
}

export const TableBody: React.FC<TTableBodyProps> = ({
  rows,
  colsLength,
  countPerPage,
  columnOrder,
  disableHover,
  onRowClick,
  id,
  demoMode,
}) => {
  const emptyRows = useMemo(
    () => new Array(countPerPage - (rows.length || 0)).fill("&nbsp;"),
    [rows.length, countPerPage],
  )

  const hoverClass = disableHover ? "" : "hover:bg-gray-dark"

  return (
    <tbody className="w-full" id={id}>
      {rows.map(row => (
        <tr
          key={row.id}
          className={`bg-basic-black ${hoverClass} border-b border-b-basic-blue`}
          onClick={() => onRowClick(row.original)}
        >
          {row.getVisibleCells().map((cell, idx) => (
            <SortableContext
              key={cell.id}
              items={columnOrder}
              strategy={horizontalListSortingStrategy}
            >
              <BodyCell
                demoMode={demoMode}
                key={cell.id}
                cell={cell}
                className={cn(
                  `bg-basic-black ${hoverClass} border-s-basic-blue`,
                  idx && "border-s",
                )}
              />
            </SortableContext>
          ))}
        </tr>
      ))}
      {emptyRows.map((_, idx) => (
        <tr
          key={`empty-${idx}`}
          className={cn("bg-basic-black border-b border-b-basic-blue")}
        >
          {new Array(colsLength).fill(null).map((_null, idy) => (
            <td
              key={idy}
              className={cn("px-5 py-4 border-s-basic-blue", idy && "border-s")}
            >
              &nbsp;
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  )
}
