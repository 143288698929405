import React from "react"

import { H5 } from "~/ui-rtk/components/ui/typography"
import { Button } from "~/ui-rtk/components/ui/controls"
import { CaptionPreview, Card, FlipCard } from "~/ui-rtk/components/ui/common"

import { formatValue } from "~/ui-rtk/components/ui/charts/TableVisualization/utils"
import { renderMediaIcon, renderSpinner } from "../../utils"

type PaidMediaCardProps = {
  data: any
  isLoading: boolean
  onCheckout: CallableFunction
}

const PaidMediaCard: React.FC<PaidMediaCardProps> = ({
  data: paidMedia,
  isLoading,
  onCheckout,
}) => {
  const renderPaidMedia = () => {
    if (!paidMedia || paidMedia.length < 1) {
      return
    }

    return (
      <div>
        <table className="table text-sm rounded-2xl border-b rounded-t-lg border-b-basic-blue">
          <thead>
            <tr className="text-white lg:text-base border border-basic-blue">
              <th className="text-wrap lg:max-w-30 max-w-20 rounded-2xl">
                Media Channel
              </th>
              <th className="p-2 text-wrap lg:max-w-30 max-w-20 rounded-t-lg">
                Campaign Name
              </th>
              <th className="p-2 text-wrap lg:max-w-30 max-w-20 rounded-t-lg">
                180d Brand Value
              </th>
            </tr>
          </thead>
          <tbody>
            {paidMedia.map(
              (row: Record<string, string | number>, idx: number) => (
                <tr key={idx}>
                  <td className="lg:max-w-30 max-w-20 bg-basic-black">
                    {renderMediaIcon(
                      row[
                        "company_media_metrics_monthly_campaigns.media_channel"
                      ] as string,
                    )}
                  </td>
                  <td className="lg:max-w-30 max-w-20 bg-basic-black">
                    <CaptionPreview
                      text={
                        row[
                          "company_media_metrics_monthly_campaigns.campaign_name"
                        ] as string
                      }
                      maxLength={20}
                    />
                  </td>
                  <td className="lg:max-w-30 max-w-20 bg-basic-black">
                    <span className="blur-sm">
                      {formatValue(
                        row[
                          "company_media_metrics_monthly_campaigns.brand_value"
                        ] ??
                          row[
                            "company_media_metrics_monthly_campaigns.brand_value_agg"
                          ],
                        "currency",
                      )}
                    </span>
                  </td>
                </tr>
              ),
            )}
          </tbody>
        </table>
      </div>
    )
  }

  const handleClick = (event: React.MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()
    onCheckout()
  }

  const cardClassName = `space-y-6 overflow-hidden relative mx-auto w-full flex flex-col justify-between md:basis-1/3 md:grow-0 h-120 px-4 py-4 bg-basic-dark-blue border border-basic-blue text-center`

  return (
    <FlipCard>
      <Card rounded className={cardClassName}>
        <div>
          <H5 className="text-brand text-center uppercase text-base">
            Paid Media
          </H5>
        </div>
        <div className="text-2xl text-center leading-8 relative">
          <p className="text-2xl leading-8">
            Track your brand's performance over the past year.
          </p>
          <p className="opacity-30 text-2xl leading-8">
            How much have you grown?
          </p>
        </div>
        <div>&nbsp;</div>
      </Card>

      <Card rounded className={cardClassName}>
        <div>
          <H5 className="text-brand text-center uppercase text-base">
            Paid Media
          </H5>
          <H5 className="text-white opacity-30 text-center uppercase text-base">
            Last 6 Month
          </H5>
        </div>
        <div className="text-2xl text-center leading-8 relative overflow-hidden">
          {isLoading ? renderSpinner() : renderPaidMedia()}
        </div>
        <div className="p-3 absolute bottom-0 left-0 right-0">
          <Button className="w-full" onClick={handleClick}>
            Track my Best Campaigns
          </Button>
        </div>
        <div>&nbsp;</div>
      </Card>
    </FlipCard>
  )
}

export default PaidMediaCard
