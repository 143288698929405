import { ReactNode, useState } from "react"
import { H3, P } from "~/ui-rtk/components/ui/typography"
import Table, {
  TCellItem,
  THandleAction,
} from "~/ui-rtk/components/ui/common/Table"
import { Card, Chip, Dialog } from "~/ui-rtk/components/ui/common"
import UserForm from "~/ui-rtk/components/features/forms/UserForm"
import { UserFormValues } from "~/ui-rtk/components/features/forms/UserForm/validation"

import useConnect from "./connect"
import {
  DataSaverSvg,
  EditSvg,
  TrashSvg,
} from "~/ui-rtk/components/ui/svg/essentials"
import { Button } from "~/ui-rtk/components/ui/controls"
import { DeleteUserDialog } from "~/ui-rtk/components/features/settings"

import useDate from "~/ui-rtk/hooks/date"
const dayjs = useDate()

const EDIT_ACTION = "EDIT"
const DELETE_ACTION = "DELETE"

const renderStatusChipCell = (item: TCellItem) => {
  const color = item.isActive
    ? "text-states-success"
    : "text-basic-grey-inactive"
  const text = item.isActive ? "Active" : "Invited"
  return (
    <Chip
      className={`bg-basic-blue text-3 leading-4 w-14 rounded-1 px-3 py-1.5 justify-center ${color} px-3`}
    >
      {text}
    </Chip>
  )
}

const renderLastActivity = (item: TCellItem) => {
  const date =
    item.lastActivity === "Never"
      ? "Never"
      : dayjs(item.lastActivity).format("MM/DD/YYYY")
  return <p>{date}</p>
}

const renderActionCell = (item: TCellItem, handleAction: THandleAction) => {
  const size = 18

  const handleEdit = () => {
    void handleAction(EDIT_ACTION, item)
  }

  const handleDelete = () => {
    void handleAction(DELETE_ACTION, item)
  }

  return (
    <>
      <div className="flex items-center gap-2">
        <Button
          onClick={handleEdit}
          variant={{ variant: "text" }}
          className="p-0"
        >
          <EditSvg size={size} />
        </Button>
        <div className={`w-[1px] h-[${size}px] bg-basic-blue`} />
        <Button
          onClick={handleDelete}
          variant={{ variant: "text" }}
          className="p-0"
        >
          <TrashSvg size={size} />
        </Button>
      </div>
    </>
  )
}

export default function SettingsUsersPage() {
  const {
    users,
    currentCompany,
    isLoading,
    handleInviteUser,
    handleUpdateUserRole,
    handleDeleteUser,
    showDataSourceInfo,
  } = useConnect()
  const [isFormOpen, setIsFormOpen] = useState(false)
  const [isFormLoading, setIsFormLoading] = useState(false)
  const [isDeleteInProgress, setIsDeleteInProgress] = useState(false)
  const [userToEdit, setUserToEdit] = useState<UserFormValues | null>(null)

  const [deleteUserDialog, setDeleteUserDialog] = useState<ReactNode | null>(
    null,
  )

  const closeDeleteUserDialog = () => {
    void setDeleteUserDialog(null)
  }

  const deleteUserAsync = (userId: string) => async () => {
    try {
      setIsDeleteInProgress(true)
      await handleDeleteUser(userId)
      setIsDeleteInProgress(false)
    } finally {
      void setDeleteUserDialog(null)
    }
  }

  const handleAction = (actionId: string, user: TCellItem) => {
    if (actionId === EDIT_ACTION) {
      setUserToEdit({
        name: user?.name,
        email: user?.email,
        role: user?.role,
        id: user?.id,
        dataSource: user?.dataSource,
      })
      setIsFormOpen(true)
    } else if (actionId === DELETE_ACTION) {
      setDeleteUserDialog(() => (
        <DeleteUserDialog
          isOpen
          onCancel={closeDeleteUserDialog}
          onConfirm={deleteUserAsync(user.id)}
        />
      ))
    }
  }

  const handleAddUser = () => {
    setUserToEdit(null)
    setIsFormOpen(true)
  }

  const handleSubmit = async (value: UserFormValues) => {
    setIsFormLoading(true)

    if (!userToEdit) {
      await handleInviteUser(value)
    } else {
      await handleUpdateUserRole(value, userToEdit.id)
    }
    setIsFormLoading(false)
    setIsFormOpen(false)
  }

  const userForm = (
    <Dialog
      closeOnClickOutside={true}
      isOpen={isFormOpen}
      handleClose={() => setIsFormOpen(false)}
      width={500}
      height="auto"
    >
      <Card
        className="w-full h-full border bg-basic-black border-basic-blue"
        rounded
      >
        <UserForm
          isLoading={isFormLoading}
          user={userToEdit}
          submit={handleSubmit}
          showDataSourceInfo={showDataSourceInfo}
        />
      </Card>
    </Dialog>
  )

  let content
  if (isLoading || isDeleteInProgress) {
    content = (
      <div className="mx-auto">
        <DataSaverSvg className="animate-spin" />
      </div>
    )
  } else {
    const tableUsers = users?.map(user => ({
      name: user.name,
      email: user.email,
      role:
        user.companies.find(company => company.companyId === currentCompany?.id)
          ?.role ?? "Unknown",
      isActive: user.isActive,
      lastActivity: user.lastLoggedInAt ?? "Never",
      id: user.id,
      dataSource: user.dataSource,
    }))
    content = (
      <Table
        headers={[
          {
            label: "Name",
            value: "name",
          },
          {
            label: "Email",
            value: "email",
          },
          {
            label: "Role",
            value: "role",
          },
          {
            label: "Status",
            customRender: renderStatusChipCell,
          },
          {
            label: "Last Activity",
            customRender: renderLastActivity,
          },
          ...(showDataSourceInfo
            ? [
                {
                  label: "Data Source",
                  value: "dataSource",
                },
              ]
            : []),
          {
            label: "Actions",
            customRender: (item: TCellItem, handleAction?: THandleAction) =>
              renderActionCell(item, handleAction ?? (() => null)),
            cellClassName: "relative",
          },
        ]}
        items={tableUsers ?? []}
        handleAction={handleAction}
        classes={{
          thead: "",
          tbody: "",
          th: "text-3.5",
        }}
      />
    )
  }
  return (
    <div className="py-6">
      <H3>Users</H3>
      <P className="my-3">Manage the users of your organization.</P>
      <div className="flex justify-end mt-2 mb-10">
        <Button
          variant={{ variant: "solid", color: "pink" }}
          size="sm"
          onClick={handleAddUser}
        >
          Add User
        </Button>
      </div>
      {content}
      {userForm}
      {deleteUserDialog}
    </div>
  )
}
