import { useURLSearchParams } from "./url"
import { AggregationType } from "../constants/charts"
import useDate from "./date"

const dayjs = useDate()

export const DATE_FORMAT = "YYYY-MM-DD"

const formatDate = (date?: Date) =>
  date ? dayjs(date).startOf("day").format(DATE_FORMAT) : date

const todayDate = new Date()
const today = formatDate(todayDate)
const startOfPrevWeek = dayjs(todayDate)
  .subtract(1, "week")
  .weekday(0)
  .format(DATE_FORMAT)
const endOfPrevWeek = dayjs(todayDate)
  .subtract(1, "week")
  .weekday(6)
  .format(DATE_FORMAT)
const startOfMonth = dayjs(todayDate).startOf("month").format(DATE_FORMAT)
const endOfMonth = dayjs(todayDate).endOf("month").format(DATE_FORMAT)
const sixMonthsAgo = dayjs(todayDate)
  .startOf("day")
  .subtract(6, "month")
  .format(DATE_FORMAT)

export const QUERY_PARAMS = {
  DATE_START: "p_ds",
  DATE_END: "p_de",
  COMPARE_DATE_START: "p_cds",
  COMPARE_DATE_END: "p_cde",
  GRANULARITY: "p_g",
}

const getDateWithFallback = (date?: string) =>
  date && dayjs(date).isValid() ? dayjs(date).format(DATE_FORMAT) : undefined

function getDefaultPeriodForGranularity(granularity?: AggregationType) {
  switch (granularity) {
    case AggregationType.WEEKLY:
      return [startOfPrevWeek, endOfPrevWeek]
    case AggregationType.MONTHLY:
      return [startOfMonth, endOfMonth]
    default:
      return [sixMonthsAgo, today]
  }
}
export function usePeriod(
  defaultGranularity = AggregationType.DAILY,
  resetPeriodOnGranularityChange = false,
) {
  const { params, setParams } = useURLSearchParams()

  const startPeriod = getDateWithFallback(params[QUERY_PARAMS.DATE_START])
  const endPeriod = getDateWithFallback(params[QUERY_PARAMS.DATE_END])
  const compareStartPeriod = getDateWithFallback(
    params[QUERY_PARAMS.COMPARE_DATE_START],
  )
  const compareEndPeriod = getDateWithFallback(
    params[QUERY_PARAMS.COMPARE_DATE_END],
  )

  const granularity =
    params[QUERY_PARAMS.GRANULARITY] in { ...AggregationType }
      ? params[QUERY_PARAMS.GRANULARITY]
      : defaultGranularity

  const setPeriod = (dateStart?: string, dateEnd?: string) => {
    setParams({
      [QUERY_PARAMS.DATE_START]: dateStart,
      [QUERY_PARAMS.DATE_END]: dateEnd,
    })
  }

  const setComparePeriod = (dateStart?: string, dateEnd?: string) => {
    setParams({
      [QUERY_PARAMS.COMPARE_DATE_START]: dateStart,
      [QUERY_PARAMS.COMPARE_DATE_END]: dateEnd,
    })
  }

  const setGranularity = (granularity: AggregationType) => {
    setParams({
      [QUERY_PARAMS.GRANULARITY]: granularity,
    })
    if (resetPeriodOnGranularityChange) {
      const [startDate, endDate] = getDefaultPeriodForGranularity(granularity)
      setPeriod(startDate, endDate)
    }
  }

  const defaultPeriod = getDefaultPeriodForGranularity(
    resetPeriodOnGranularityChange
      ? (granularity as AggregationType)
      : undefined,
  )

  return {
    period: [startPeriod ?? defaultPeriod[0], endPeriod ?? defaultPeriod[1]],
    comparePeriod:
      compareStartPeriod && compareEndPeriod
        ? [compareStartPeriod, compareEndPeriod]
        : null,
    granularity: granularity as AggregationType,
    setPeriod,
    setComparePeriod,
    setGranularity,
  }
}
