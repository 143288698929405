import { useMemo } from "react"
import { type Query as TCubeQuery, ResultSet } from "@cubejs-client/core"

import { CUBE_QUERY_KEYS_MAP, getCubeQueryBase } from "../../constants"
import useCampaignAdSetAdDetails, {
  prepareAllItemsDetails,
  useAllCampaignsAdSetsAdsDetails,
} from "~/ui-rtk/hooks/campaign-adset-ad-details"
import {
  getPivotData,
  polyfillCubeQueryWithTimeDimension,
} from "~/ui-rtk/utils/cube"

import { MetricsMapping } from "~/ui-rtk/constants/metrics-mapping"
import useCurrentCompany from "~/ui-rtk/hooks/current-company"
import { useYearDateRange } from "~/ui-rtk/hooks/date-range"

const defaultPrefixKey = "company_media_metrics_campaigns"

const requests: Record<string, TCubeQuery> = {}
const results: Record<string, any> = {}

export const useAllCampaignsCubeQuery = () => {
  const { postProcess } = MetricsMapping.brand_media_optimization_campaign_table
  const prefixKey = defaultPrefixKey
  const currentCompany = useCurrentCompany()
  const currentCompanyId = currentCompany?.id ?? "-company-not-set-"
  const cacheKey = `${prefixKey}.${currentCompanyId}`
  const queryAllCampaignKey = `${cacheKey}.all`
  const campaignIdKey = `${prefixKey}.campaign_id`
  const purchaseKey = `${prefixKey}.purchases`
  const dateRange = useYearDateRange()

  const campaignQuery = getCubeQueryBase(prefixKey)

  const allCampaignsQuery = useMemo(() => {
    if (!requests[queryAllCampaignKey]) {
      requests[queryAllCampaignKey] = {
        ...campaignQuery,
        measures: [...campaignQuery.measures, purchaseKey],
        dimensions: [campaignIdKey],
      }
    }
    return polyfillCubeQueryWithTimeDimension(requests[queryAllCampaignKey], {
      dateRange,
    })
  }, [
    queryAllCampaignKey,
    campaignQuery,
    purchaseKey,
    campaignIdKey,
    dateRange,
  ])

  const { [CUBE_QUERY_KEYS_MAP.ALL]: allCampaignsSet, isLoading } =
    useAllCampaignsAdSetsAdsDetails(allCampaignsQuery)

  if (!results[cacheKey]) {
    results[cacheKey] = {}
  }

  if (allCampaignsSet) {
    const pivotData = getPivotData(
      allCampaignsSet as ResultSet<any>,
      allCampaignsQuery,
    )
    const processedPivotData = postProcess
      ? postProcess(pivotData, {})[0]
      : pivotData
    results[cacheKey].allCampaignsDetails =
      prepareAllItemsDetails(processedPivotData)
  }

  const { allCampaignsDetails } = results[cacheKey]

  return { allCampaignsDetails, isLoading }
}

export const useCampaignQubeQuery = () => {
  const { postProcess } = MetricsMapping.brand_media_optimization_campaign_table
  const prefixKey = defaultPrefixKey
  const currentCompany = useCurrentCompany()
  const currentCompanyId = currentCompany?.id ?? "-company-not-set-"
  const cacheKey = `${prefixKey}.${currentCompanyId}`
  const queryAllCampaignKey = `${cacheKey}.all`
  const dateRange = useYearDateRange()

  const campaignIdKey = `${prefixKey}.campaign_id`
  const purchaseKey = `${prefixKey}.purchases`

  const campaignQuery = getCubeQueryBase(prefixKey)

  const allCampaignsQuery = useMemo(() => {
    if (!requests[queryAllCampaignKey]) {
      requests[queryAllCampaignKey] = {
        ...campaignQuery,
        measures: [...campaignQuery.measures, purchaseKey],
        dimensions: [campaignIdKey],
      }
    }
    return polyfillCubeQueryWithTimeDimension(requests[queryAllCampaignKey], {
      dateRange,
    })
  }, [
    queryAllCampaignKey,
    purchaseKey,
    campaignIdKey,
    campaignQuery,
    dateRange,
  ])

  const { [CUBE_QUERY_KEYS_MAP.ALL]: allCampaignsSet, isLoading } =
    useCampaignAdSetAdDetails({
      [CUBE_QUERY_KEYS_MAP.ALL]: allCampaignsQuery,
    })

  if (!results[cacheKey]) {
    results[cacheKey] = {}
  }

  if (allCampaignsSet && !isLoading) {
    const pivotData = getPivotData(
      allCampaignsSet as ResultSet<any>,
      allCampaignsQuery,
    )
    const processedData = postProcess
      ? postProcess(pivotData, {})[0]
      : pivotData
    results[cacheKey].allCampaignsDetails =
      prepareAllItemsDetails(processedData)
  }

  const { allCampaignsDetails } = results[cacheKey]

  return { allCampaignsDetails, isLoading }
}
