import { useMemo } from "react"

import {
  type Query as TCubeQuery,
  Filter,
  ResultSet,
} from "@cubejs-client/core"

import { CUBE_QUERY_KEYS_MAP, getCubeQueryBase } from "../../constants"
import useCampaignAdSetAdDetails, {
  prepareAllItemsDetails,
  useAllCampaignsAdSetsAdsDetails,
} from "~/ui-rtk/hooks/campaign-adset-ad-details"
import {
  getPivotData,
  polyfillCubeQueryWithTimeDimension,
} from "~/ui-rtk/utils/cube"

import { MetricsMapping } from "~/ui-rtk/constants/metrics-mapping"
import useCurrentCompany from "~/ui-rtk/hooks/current-company"
import { useYearDateRange } from "~/ui-rtk/hooks/date-range"

const defaultPrefixKey = "company_media_metrics_adsets"

const requests: Record<string, TCubeQuery> = {}
const results: Record<string, any> = {}

const ALL_CAMPAIGNS = "ALL"

export const useAllAdsetsCubeQuery = () => {
  const { postProcess } = MetricsMapping.brand_media_optimization_adsets_table
  const prefixKey = defaultPrefixKey
  const currentCompany = useCurrentCompany()
  const currentCompanyId = currentCompany?.id ?? "-company-not-set-"
  const campaignId = ALL_CAMPAIGNS
  const cacheKey = `${prefixKey}.${currentCompanyId}.${campaignId}`
  const queryAllCampaignKey = `${cacheKey}.all`
  const campaignIdKey = `${prefixKey}.campaign_id`
  const purchaseKey = `${prefixKey}.purchases`
  const dateRange = useYearDateRange()

  const campaignQuery = getCubeQueryBase(prefixKey)

  const allAdSetsQuery = useMemo(() => {
    if (!requests[queryAllCampaignKey]) {
      requests[queryAllCampaignKey] = {
        ...campaignQuery,
        measures: [...campaignQuery.measures, purchaseKey],
        dimensions: [campaignIdKey],
      }
    }
    return polyfillCubeQueryWithTimeDimension(requests[queryAllCampaignKey], {
      dateRange,
    })
  }, [
    queryAllCampaignKey,
    campaignQuery,
    purchaseKey,
    campaignIdKey,
    dateRange,
  ])

  const { [CUBE_QUERY_KEYS_MAP.ALL]: allAdSetsSet, isLoading } =
    useAllCampaignsAdSetsAdsDetails(allAdSetsQuery)

  if (!results[cacheKey]) {
    results[cacheKey] = {}
  }

  if (allAdSetsSet) {
    const pivotData = getPivotData(
      allAdSetsSet as ResultSet<any>,
      allAdSetsQuery,
    )
    const processedPivotData = postProcess
      ? postProcess(pivotData, {})[0]
      : pivotData
    results[cacheKey].allAdSetsDetails =
      prepareAllItemsDetails(processedPivotData)
  }

  const { allAdSetsDetails } = results[cacheKey]

  return { allAdSetsDetails, isLoading }
}

export const useAdSetQubeQuery = (campaignId = ALL_CAMPAIGNS) => {
  const { postProcess } = MetricsMapping.brand_media_optimization_adsets_table
  const prefixKey = defaultPrefixKey
  const currentCompany = useCurrentCompany()
  const currentCompanyId = currentCompany?.id ?? "-company-not-set-"
  const cacheKey = `${prefixKey}.${currentCompanyId}.${campaignId}`
  const queryAllAdSetsKey = `${cacheKey}.all`
  const dateRange = useYearDateRange()

  const campaignIdKey = `${prefixKey}.campaign_id`
  const adSetIdKey = `${prefixKey}.adset_id`

  const campaignQuery = getCubeQueryBase(prefixKey)

  const allAdSetsFilters = useMemo(() => {
    if (campaignId !== ALL_CAMPAIGNS) {
      return {
        and: [
          {
            dimension: campaignIdKey,
            operator: "equals",
            values: [campaignId],
          },
        ],
      }
    }
    return undefined
  }, [prefixKey, campaignId, campaignIdKey])

  const allAdSetsQuery = useMemo(() => {
    if (!requests[queryAllAdSetsKey]) {
      requests[queryAllAdSetsKey] = {
        ...campaignQuery,
        dimensions: [adSetIdKey],
        filters: [allAdSetsFilters as Filter],
      }
    }
    return polyfillCubeQueryWithTimeDimension(requests[queryAllAdSetsKey], {
      dateRange,
    })
  }, [
    queryAllAdSetsKey,
    campaignQuery,
    adSetIdKey,
    allAdSetsFilters,
    dateRange,
  ])

  const { [CUBE_QUERY_KEYS_MAP.ALL]: allAdSetsSet, isLoading } =
    useCampaignAdSetAdDetails({
      [CUBE_QUERY_KEYS_MAP.ALL]: allAdSetsQuery,
    })

  if (!results[cacheKey]) {
    results[cacheKey] = {}
  }

  if (allAdSetsSet) {
    const pivotData = getPivotData(
      allAdSetsSet as ResultSet<any>,
      allAdSetsQuery,
    )
    const processedPivotData = postProcess
      ? postProcess(pivotData, {})[0]
      : pivotData
    results[cacheKey].allAdSetsDetails =
      prepareAllItemsDetails(processedPivotData)
  }

  const { allAdSetsDetails } = results[cacheKey]

  return { allAdSetsDetails, isLoading }
}
