import { selectIsEnabled } from "~/ui-rtk/app/selectors/demo.selector"
import { useAppDispatch, useAppSelector } from "~/ui-rtk/app/hooks"
import { updateDemo } from "~/ui-rtk/app/slices/demo.slice"

export default function useDemoMode() {
  const { isEnabled } = useAppSelector(selectIsEnabled)
  const dispatch = useAppDispatch()

  const toggleEnabled = () => {
    dispatch(updateDemo(!isEnabled))
  }

  return {
    isEnabled,
    toggleEnabled,
  }
}
