import React, { useCallback } from "react"

import { H5 } from "~/ui-rtk/components/ui/typography"
import { CaptionPreview, Card, FlipCard } from "~/ui-rtk/components/ui/common"

import { renderMediaIcon, renderSpinner } from "../../utils"
import { Button } from "~/ui-rtk/components/ui/controls"
import { formatValue } from "~/ui-rtk/components/ui/charts/TableVisualization/utils"
import { cn } from "~/ui-rtk/utils/tailwind-utils"

type OrganicMediaCardProps = {
  data: any
  isLoading: boolean
  onCheckout: CallableFunction
}

const OrganicMediaCard: React.FC<OrganicMediaCardProps> = ({
  data: organicMedia,
  isLoading,
  onCheckout,
}) => {
  const renderOrganicMedia = useCallback(() => {
    if (!organicMedia || organicMedia.length < 1) {
      return
    }

    return (
      <table className="table rounded-2xl border-b rounded-t-lg border-b-basic-blue">
        <thead>
          <tr className="text-white text-base border border-basic-blue">
            <th className="p-4 text-wrap max-w-30 rounded-t-lg">
              Media Channel
            </th>
            <th className="p-4 text-wrap max-w-30">Caption</th>
            <th className="p-4 text-wrap max-w-30">180d Brand Value</th>
          </tr>
        </thead>
        <tbody>
          {organicMedia.map(
            (row: Record<string, string | number>, idx: number) => (
              <tr
                key={idx}
                className={cn(
                  "border-b border-b-basic-blue",
                  idx === 0 && "border-t border-t-basic-blue",
                )}
              >
                <td className="max-w-30 bg-basic-black">
                  {renderMediaIcon(
                    row[
                      "company_media_metrics_organic_posts.media_channel"
                    ] as string,
                  )}
                </td>
                <td className="max-w-30 bg-basic-black">
                  <CaptionPreview
                    text={
                      row["company_media_metrics_posts.post_caption"] as string
                    }
                    maxLength={20}
                  />
                </td>
                <td className="max-w-30 bg-basic-black px-4 overflow-hidden">
                  <span className="max-w-30 blur-sm">
                    {formatValue(
                      row["company_media_metrics_posts.brand_value"] ??
                        row["company_media_metrics_posts.brand_value_agg"],
                      "currency",
                    )}
                  </span>
                </td>
              </tr>
            ),
          )}
        </tbody>
      </table>
    )
  }, [organicMedia])

  const handleClick = (event: React.MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()
    onCheckout()
  }

  const cardClassName = `mx-auto w-full flex flex-col justify-between md:basis-1/3 md:grow-0 h-120 px-4 py-4 bg-basic-dark-blue border border-basic-blue text-center`

  return (
    <FlipCard>
      <Card rounded className={cardClassName}>
        <div>
          <H5 className="text-brand text-center uppercase text-base">
            Organic Media
          </H5>
        </div>
        <div className="text-2xl text-center leading-8 relative">
          <p className="text-2xl leading-8">
            Which organic posts are delivering the highest returns?
          </p>
          <p className="opacity-30 text-2xl leading-8">Let’s dive in</p>
        </div>
        <div>&nbsp;</div>
      </Card>

      <Card rounded className={cardClassName}>
        <div>
          <H5 className="text-brand text-center uppercase text-base">
            Organic Media
          </H5>
          <H5 className="text-white opacity-30 text-center uppercase text-base">
            Last 6 Month
          </H5>
        </div>
        <div className="text-2xl text-center leading-8 relative overflow-hidden">
          {isLoading ? renderSpinner() : renderOrganicMedia()}
        </div>
        <div className="p-3 absolute bottom-0 left-0 right-0">
          <Button className="w-full" onClick={handleClick}>
            Discover the Impact
          </Button>
        </div>
        <div>&nbsp;</div>
      </Card>
    </FlipCard>
  )
}

export default OrganicMediaCard
