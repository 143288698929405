import { useRef, useEffect, useMemo, useState, CSSProperties } from "react"
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"
import useTailwindTheme from "~/ui-rtk/hooks/tailwind-theme"
import { useResizeObserver } from "~/ui-rtk/utils/resize-observer"
import { formatNumber } from "~/ui-rtk/utils/format"
import ChartTooltipContent from "./ChartTooltipContent"
import {
  AGG_TYPES,
  AggregationType,
  CHARTS_LOADING_DATA,
} from "~/ui-rtk/constants/charts"
import { useDateRanges } from "~/ui-rtk/utils/date-utils"
import H3 from "../../typography/H3"
import SubTitle from "../../typography/SubTitle"
import pluralize from "pluralize"
import SourceTooltipContent from "../SourceTooltipContent"
import Card from "~/ui-rtk/components/ui/common/Card"
import RichTooltip from "~/ui-rtk/components/ui/common/RichTooltip"
import { InfoSvg } from "~/ui-rtk/components/ui/svg/essentials/InfoSvg"
import Button from "~/ui-rtk/components/ui/controls/Button"
import { cn } from "~/ui-rtk/utils/tailwind-utils"

import useDate from "~/ui-rtk/hooks/date"
const dayjs = useDate()

export type TAreaChartProps = {
  metrics: any[]
  state?: string
  compareMode?: boolean
  title?: string
  unit?: string
  dataAggType?: AggregationType
  dataAggTypeOnly?: AggregationType
  numberType?: "default" | "currency"
  pluralizeUnit?: boolean
  requiredSources?: string[]
  subTitle?: string
  sources?: Map<
    string,
    {
      name: string
      icon: string
    }
  >
  setDataAggType?: (agg: AggregationType) => void
  labelAggType?: "sum" | "avg" | "percent-change"
  isCompareMetricMain?: boolean
  color?: string
  className?: string
}

const aggFunc = (
  data: Array<{ date: number; value: number }>,
  aggType: "sum" | "avg" | "percent-change",
) => {
  const length = data.length
  const sum = ["sum", "avg"].includes(aggType)
    ? data.reduce(
        (sum: number, { value }) => (isNaN(value) ? sum : sum + value),
        0,
      )
    : 0

  switch (aggType) {
    case "avg":
      return sum / length
    case "percent-change":
      return data.length > 1
        ? (data[length - 1].value / data[0].value - 1) * 100
        : 0
    default:
      return sum
  }
}

let prevTick: any = null
const formatTick = (value: any, index: number) => {
  const date = dayjs(value)
  const tick = date.format(
    date.month() !== dayjs(prevTick).month() || index === 0 ? `DD MMM` : "DD",
  )
  prevTick = value
  return tick
}

export default function AreaChart({
  metrics,
  state = "idle",
  compareMode = false,
  title,
  unit,
  dataAggType: dataAggTypeProp,
  dataAggTypeOnly,
  numberType = "default",
  pluralizeUnit = true,
  requiredSources,
  subTitle,
  sources,
  setDataAggType: setDataAggTypeProp = () => null,
  labelAggType = "sum",
  isCompareMetricMain = false,
  color,
  className,
}: TAreaChartProps) {
  const { minAggType, maxAggType } = useDateRanges()
  const [dataAggType, setDataAggType] = useState(
    dataAggTypeProp ?? AggregationType.DAILY,
  )

  const refEl = useRef<HTMLElement>(document.body)
  const size = useResizeObserver({ ref: refEl })
  const noData =
    metrics.length === 0 || !metrics.some(({ value }) => Boolean(value))
  const theme = useTailwindTheme()
  const chartColor = color ?? theme.colors.charts["cyan"]

  const dataSources: string[] =
    requiredSources ??
    (metrics?.length > 0 && metrics[0]?.metadata?.source
      ? Array.from(metrics[0].metadata.source)
      : [])

  const aggregationGroups = useMemo(
    () =>
      AGG_TYPES.filter(({ type }, index) => {
        // if data agg type is set in config, hide all other options
        if (dataAggTypeOnly) {
          return dataAggTypeOnly === type
        }
        // if min agg type is weekly, hide daily
        if (minAggType === AggregationType.WEEKLY && index === 0) {
          return false
        }
        // if max agg type is daily, hide weekly and monthly
        if (maxAggType === AggregationType.DAILY && index > 0) {
          return false
        }
        // if max agg type is weekly, hide monthly
        if (maxAggType === AggregationType.WEEKLY && index === 2) {
          return false
        }
        return true
      }),
    [minAggType, maxAggType, dataAggTypeOnly],
  )

  const filteredData = metrics.filter(({ value, comparedValue }) =>
    compareMode && isCompareMetricMain
      ? Boolean(comparedValue)
      : Boolean(value),
  )
  const aggValue = aggFunc(filteredData, labelAggType) ?? 0
  const compareAggValue = compareMode
    ? (aggFunc(
        filteredData.map(({ compareDate, value, comparedValue }) => ({
          date: compareDate,
          value: isCompareMetricMain ? value : comparedValue,
        })),
        labelAggType,
      ) ?? 0)
    : null
  const allTimePct =
    compareMode && compareAggValue ? aggValue / compareAggValue - 1 : null

  const handleAggregationGroup = (type: AggregationType) => {
    setDataAggType(type)
    setDataAggTypeProp(type)
  }

  const composedChartStyle = useMemo(
    () =>
      state !== "idle" || noData
        ? { opacity: 0.15, filter: "grayscale(1)" }
        : undefined,
    [state, noData],
  )

  const cartesianGridStyles = useMemo(
    () => ({ stroke: theme.colors["gray-dark"] }),
    [theme],
  )

  const yAxisStyle = useMemo(
    () => ({
      fontSize: `${theme.fontSize.sm}`,
      textAnchor: "end" as CSSProperties["textAnchor"],
      transform: "translate(-10px, 10px)",
    }),
    [theme],
  )

  useEffect(() => {
    if (dataAggTypeOnly) {
      return
    }
    if (
      minAggType !== AggregationType.DAILY &&
      dataAggType === AggregationType.DAILY
    ) {
      setDataAggType(minAggType)
    } else if (
      maxAggType !== AggregationType.MONTHLY &&
      dataAggType === AggregationType.MONTHLY
    ) {
      setDataAggType(maxAggType)
    }
  }, [minAggType, maxAggType, dataAggType, dataAggTypeOnly])

  return (
    <div
      className={cn(
        "relative flex flex-col justify-between area-chart",
        className,
      )}
    >
      <div className="z-10">
        <div className="flex justify-between px-2 pt-2">
          <div>
            <div className="inline-flex mb-5">
              <H3>{title}</H3>
              {dataSources.length < 2 && (
                <div className="ms-1">
                  <RichTooltip
                    content={
                      <Card>
                        <SourceTooltipContent>
                          {noData ? null : (
                            <div className="flex px-2 h-9.5 flex-col">
                              {aggValue ? (
                                <span
                                  className={`${compareMode ? "text-lg" : "text-xl"} text-channel-pink-light text-nowrap`}
                                >
                                  {formatNumber(aggValue, {
                                    compact: true,
                                    currency: numberType === "currency",
                                    decimals: 2,
                                  })}
                                  <span className="text-xs font-bold">
                                    {unit ? (
                                      <>{` ${pluralizeUnit ? pluralize(unit, aggValue) : unit}`}</>
                                    ) : null}
                                  </span>
                                </span>
                              ) : null}
                              <div className="flex items-baseline gap-1">
                                {compareAggValue ? (
                                  <span className="font-bold text-channel-blue-light text-nowrap">
                                    {formatNumber(compareAggValue, {
                                      compact: true,
                                      currency: numberType === "currency",
                                      decimals: 2,
                                    })}
                                  </span>
                                ) : null}
                                {allTimePct != null && !isNaN(allTimePct) ? (
                                  <span
                                    className={`${allTimePct ? "text-error" : "text-success"}`}
                                  >
                                    {formatNumber(allTimePct * 100, {
                                      compact: true,
                                      decimals: 1,
                                    })}
                                    %
                                  </span>
                                ) : null}
                              </div>
                            </div>
                          )}
                        </SourceTooltipContent>
                      </Card>
                    }
                  >
                    <InfoSvg size={24} fill="blue" />
                  </RichTooltip>
                </div>
              )}
              {subTitle ? (
                <SubTitle className="font-bold max-h-16 text-gray-light">
                  {subTitle}
                </SubTitle>
              ) : null}
            </div>
          </div>
          <div className="relative">
            {dataSources.length > 0 ? (
              <div className="flex gap-1">
                {dataSources.map(source =>
                  sources?.has(source) ? (
                    <img
                      key={source}
                      src={sources.get(source)?.icon}
                      alt={sources.get(source)?.name}
                      title={sources.get(source)?.name}
                      width={24}
                      height={24}
                      className="object-cover"
                    />
                  ) : null,
                )}
              </div>
            ) : null}
            <div className="join absolute -bottom-9 right-0">
              {aggregationGroups.length > 1
                ? aggregationGroups.map(({ type, long, short }) => (
                    <Button
                      variant={{ variant: "solid", color: "blue" }}
                      key={type}
                      title={long}
                      className={`join-item border text-3 px-2 py-1 rounded-sm ${type === (dataAggTypeOnly ?? dataAggType) ? "bg-basic-blue" : "bg-basic-dark-blue"}`}
                      onClick={() => handleAggregationGroup(type)}
                    >
                      {size.width < 550 ? short : long}
                    </Button>
                  ))
                : null}
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-end grow shrink-0 basis-auto">
        <ResponsiveContainer
          key={`chart-${size.width}-${size.height}-${noData ? "no-data" : ""}`}
          width="100%"
          minHeight={50}
        >
          <ComposedChart
            data={state !== "idle" || noData ? CHARTS_LOADING_DATA : metrics}
            margin={{
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            }}
            style={composedChartStyle}
          >
            <CartesianGrid
              strokeDasharray="3 0"
              vertical={false}
              style={cartesianGridStyles}
              verticalPoints={[]}
            />
            {metrics
              .filter(({ date }) =>
                dayjs(date).isSame(dayjs(date).startOf("quarter")),
              )
              .map(({ date }, index) => (
                <ReferenceLine
                  key={index}
                  x={date}
                  strokeDasharray="2 2"
                  stroke={theme.colors["gray-light"]}
                  label={{
                    value: `Q${dayjs(date).quarter()}`,
                    position: "bottom",
                    style: {
                      transform: "translateY(14px)",
                      fontSize: `${theme.fontSize.sm}`,
                    },
                  }}
                />
              ))}
            <XAxis
              xAxisId={0}
              dataKey="date"
              tickFormatter={formatTick}
              interval="preserveStart"
              minTickGap={30}
              className="text-sm"
            />
            <XAxis xAxisId={1} dataKey="comparedDate" tick={false} hide />
            <YAxis
              domain={[0, "auto"]}
              orientation="right"
              interval="preserveEnd"
              padding={{ top: 0, bottom: 0 }}
              width={0.01}
              axisLine={false}
              tickLine={false}
              style={yAxisStyle}
              tickFormatter={(value, index) =>
                index !== 0 || value > 0
                  ? `${formatNumber(value, { compact: true })}`
                  : ""
              }
            />

            <Area
              type="monotone"
              dataKey="value"
              strokeWidth={2}
              stroke={chartColor}
              fill={chartColor}
              fillOpacity={0.25}
              animationDuration={0}
            />
            {compareMode ? (
              <Line
                type="monotone"
                dataKey="comparedValue"
                strokeDasharray="6 6"
                dot={{ r: 1 }}
                strokeWidth={2}
                stroke={chartColor}
                fill={chartColor}
                fillOpacity={0.25}
                isAnimationActive={true}
                animationDuration={1500}
                animationBegin={0}
              />
            ) : null}
            {state === "idle" && !noData ? (
              <Tooltip
                animationDuration={0}
                content={({ active, payload }) => {
                  if (!active || !payload || !payload.length) {
                    return null
                  }
                  return (
                    <ChartTooltipContent
                      payload={payload}
                      title={title}
                      unit={unit}
                      dataAggType={dataAggType}
                      numberType={numberType}
                      pluralizeUnit={pluralizeUnit}
                      compareMode={compareMode}
                      isCompareMetricMain={isCompareMetricMain}
                    />
                  )
                }}
              />
            ) : null}
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}
