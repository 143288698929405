import { type BinaryFilter } from "@cubejs-client/core"
import useDate from "~/ui-rtk/hooks/date"

const dayjs = useDate()

export const getBaseCubeYearlyFilters = (
  queryPrefix: string,
  startOfPeriod: Date,
) =>
  ({
    member: `${queryPrefix}.year`,
    operator: "equals",
    values: [dayjs(startOfPeriod).isoWeekYear().toString()],
  }) as BinaryFilter

export const getBaseCubeQuarterlyFilters = (
  queryPrefix: string,
  startOfPeriod: Date,
) => ({
  and: [
    getBaseCubeYearlyFilters(queryPrefix, startOfPeriod),
    {
      member: `${queryPrefix}.quarter`,
      operator: "equals",
      values: [`${dayjs(startOfPeriod).quarter()}`],
    } as BinaryFilter,
  ],
})

export const getBaseCubeMonthlyFilters = (
  queryPrefix: string,
  startOfPeriod: Date,
) => ({
  and: [
    getBaseCubeYearlyFilters(queryPrefix, startOfPeriod),
    {
      member: `${queryPrefix}.month`,
      operator: "equals",
      values: [`${dayjs(startOfPeriod).month() + 1}`],
    } as BinaryFilter,
  ],
})

export const getBaseCubeWeelklyFilters = (
  queryPrefix: string,
  startOfPeriod: Date,
) => ({
  and: [
    getBaseCubeYearlyFilters(queryPrefix, startOfPeriod),
    {
      member: `${queryPrefix}.week`,
      operator: "equals",
      values: [dayjs(startOfPeriod).isoWeek().toString()],
    } as BinaryFilter,
  ],
})

export const getBaseCubeFilters = (
  queryPrefix: string,
  filters?: Record<string, string | undefined | null>,
) =>
  filters
    ? Object.keys(filters)
        .map(key => {
          if (!filters[key]) {
            return undefined
          }
          return {
            member: `${queryPrefix}.${key}`,
            operator: "equals",
            values: [filters[key]],
          }
        })
        .filter(f => !!f)
    : []
