import Dashboard from "~/ui-rtk/components/features/dashboards/Dashboard"
import useConnect from "./connect"
import { TDashboardItemsSet } from "~/ui-rtk/constants/dashboard"
import WithLoader from "~/ui-rtk/components/layout/WithLoader/WithLoader"

const excludeDataKeyFromTotal = ["metrics.total_ad_spend"]

export default function RevenueShopifyPage() {
  const { content, visualizations, isLoading, props } = useConnect()

  return (
    <WithLoader isLoading={isLoading}>
      <Dashboard
        slug={content.slug}
        props={props}
        visualizations={visualizations}
        items={content.content.items as TDashboardItemsSet}
        title={content.content.title}
        parent={content.parent}
        periodPicker={content.content.periodPicker}
        excludeDataKeyFromTotal={excludeDataKeyFromTotal}
      />
    </WithLoader>
  )
}
