import { useMemo, useState } from "react"
import useConnectedServices from "./connected-services"
import { ConnectionStatus, ConnectorMetadata } from "../api/types"
import { ConnectorGroupItem } from "../pages/Onboarding/ConnectSources/types"
import {
  isVisibleToCompany,
  mapConnectorStatus,
} from "../utils/connector-utils"
import useConnectors from "./connectors"
import { CONNECTORS_TO_SOURCES_MAP } from "../constants/sources"
import useCurrentCompany from "./current-company"

export default function useReconnectSourcesBanner() {
  const [requiredSources, setRequiredSources] = useState<string[]>([])
  const { connectedServices, isGetCompanyConnectorsLoading } =
    useConnectedServices()
  const { getConnectorById, isGetAllConnectorsLoading } = useConnectors()

  const currentCompany = useCurrentCompany()

  const connectorsToReconnect = useMemo(() => {
    if (
      requiredSources.length < 1 ||
      isGetCompanyConnectorsLoading ||
      isGetAllConnectorsLoading
    ) {
      return []
    }
    return connectedServices
      ?.filter(service => service.status === ConnectionStatus.PENDING_RECONNECT)
      .reduce((acc: ConnectorGroupItem[], service) => {
        if (!currentCompany) {
          return acc
        }

        const connector = getConnectorById(service.serviceName)

        if (!connector) {
          return acc
        }

        const connectorSources = CONNECTORS_TO_SOURCES_MAP[connector.id] ?? []

        if (
          !connectorSources.some(source => requiredSources.includes(source))
        ) {
          return acc
        }

        if (!isVisibleToCompany(connector, currentCompany.id)) {
          return acc
        }

        const connectedService = connectedServices?.find(
          s => s.serviceName === connector.id,
        )

        const status = mapConnectorStatus(connectedService?.status)

        acc.push({
          id: connector.id,
          name: connector.name,
          icon: connector.icon,
          category: connector.category,
          confirmedAt: connectedService?.confirmedAt,
          metadata: connectedService?.metadata as ConnectorMetadata,
          status,
        } as ConnectorGroupItem)
        return acc
      }, [])
  }, [
    requiredSources,
    connectedServices,
    getConnectorById,
    isGetCompanyConnectorsLoading,
    isGetAllConnectorsLoading,
  ])

  return {
    connectorsToReconnect: connectorsToReconnect ?? [],
    requiredSources,
    setRequiredSources,
  }
}
