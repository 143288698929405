import { DataSourceEnum } from "./connect"

export const dataSourceOptions = [
  {
    label: "Snapshot",
    value: DataSourceEnum.SNAPSHOT,
  },
  {
    label: "Snapshot ECS",
    value: DataSourceEnum.SNAPSHOT_ECS,
  },
  {
    label: "Latest",
    value: DataSourceEnum.LATEST,
  },
]
