export const SHOPIFY_REVENUE_TYPE = {
  BASELINE: "baseline",
  TRIGGERED: "triggered",
  PAID: "paid",
}

export const SHOPIFY_REVENUE_TYPE_TO_TITLE_MAP = {
  [SHOPIFY_REVENUE_TYPE.BASELINE]: "Baseline",
  [SHOPIFY_REVENUE_TYPE.TRIGGERED]: "Triggered",
  [SHOPIFY_REVENUE_TYPE.PAID]: "Paid",
}

export const SHOPIFY_REVENUE_TYPE_TO_COLOR_MAP = {
  [SHOPIFY_REVENUE_TYPE.BASELINE]: "#318936",
  [SHOPIFY_REVENUE_TYPE.TRIGGERED]: "#EE8434",
  [SHOPIFY_REVENUE_TYPE.PAID]: "#E2323E",
}
