import { PropsWithChildren } from "react"
import { useLastUpdateDate } from "~/ui-rtk/hooks/cube"
import useDate from "~/ui-rtk/hooks/date"
import P from "~/ui-rtk/components/ui/typography/P"
import { Loader } from "~/ui-rtk/components/ui/common/Loader"
const dayjs = useDate()

type TLastUpdateChipProps = {
  lastDate?: string
  isLoading?: boolean
}

export function LastUpdatedChip(
  props: PropsWithChildren<TLastUpdateChipProps>,
) {
  const { lastDate, isLoading, children } = props

  return isLoading || lastDate ? (
    <div className="gap-2 justify-center py-2 px-4 rounded-md bg-basic-dark-blue border border-basic-blue flex items-center text-4 line-height-4 font-semibold leading-5">
      {isLoading ? (
        <Loader size={20} />
      ) : (
        <P className="font-bold text-3">
          {children} {dayjs(lastDate).format("MMM D, YYYY")}
        </P>
      )}
    </div>
  ) : null
}

type TLastUpdateChipStandaloneProps = {
  dateMetric?: string
  filterMetrics?: string[]
}

export default function LastUpdatedChipStandalone(
  props: PropsWithChildren<TLastUpdateChipStandaloneProps>,
) {
  const { dateMetric, filterMetrics, children } = props
  const lastUpdate = useLastUpdateDate(
    dateMetric ? { dateMetric, filterMetrics } : undefined,
  )

  return <LastUpdatedChip {...lastUpdate}>{children}</LastUpdatedChip>
}
