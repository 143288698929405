import { TDashboardItemsSet } from "~/ui-rtk/constants/dashboard"
import useSources from "~/ui-rtk/hooks/sources"
import { getContentWidgetIds } from "~/ui-rtk/utils/content"
import { useVisualizationsMap } from "~/ui-rtk/hooks/visualizations"
import { brandRoutes } from "~/ui-rtk/constants/routes"
import { useGaMultiPropertyEnabled } from "~/ui-rtk/hooks/ga-multi-property-access"

const content = {
  id: "clrtjgjl7000kgl54esksbn0k",
  slug: brandRoutes.REVENUE.GOOGLE_ANALYTICS,
  icon: null,
  title: "Google Analytics",
  parent: {
    slug: brandRoutes.REVENUE.ROOT,
    title: "Revenue",
  },
  metadata: {
    visibleToRoles: ["SUPER_ADMIN"],
  },
  order: 1.9,
  content: {
    ga_items: [
      {
        className: "h-120",
        grid: 1,
        items: [
          {
            widget: "ga_revenue_break_down_stacked_chart",
          },
        ],
      },
      {
        className: "h-10",
        grid: 1,
        items: [
          {
            widget: "SPACER",
          },
        ],
      },
      {
        className: "min-h-64",
        grid: 1,
        items: [
          {
            widget: "ga_session_paid_triggered_and_baseline_traffic_data_table",
          },
        ],
      },
      {
        className: "h-10",
        grid: 1,
        items: [
          {
            widget: "SPACER",
          },
        ],
      },
      {
        className: "h-120",
        grid: 1,
        items: [
          {
            widget: "ga_baseline_traffic_vs_revenue_stacked_chart",
          },
        ],
      },
      {
        className: "h-10",
        grid: 1,
        items: [
          {
            widget: "SPACER",
          },
        ],
      },
      {
        className: "h-50",
        grid: 3,
        items: [
          {
            widget: "ga_sessions_from_organic_sessions",
          },
          {
            widget: "ga_sessions_from_direct_sessions",
          },
          {
            widget: "ga_sessions_from_organic_referal_sessions",
          },
        ],
      },
      {
        className: "h-10",
        grid: 1,
        items: [
          {
            widget: "SPACER",
          },
        ],
      },
      {
        grid: 1,
        items: [
          {
            className: "min-h-200",
            widget: "session_source_and_medium_traffic_data_table",
          },
        ],
      },
    ],
    ga_mp_items: [
      {
        className: "h-120",
        grid: 1,
        items: [
          {
            widget: "ga_mp_revenue_break_down_stacked_chart",
          },
        ],
      },
      {
        className: "h-10",
        grid: 1,
        items: [
          {
            widget: "SPACER",
          },
        ],
      },
      {
        className: "min-h-64",
        grid: 1,
        items: [
          {
            widget:
              "ga_mp_session_paid_triggered_and_baseline_traffic_data_table",
          },
        ],
      },
      {
        className: "h-10",
        grid: 1,
        items: [
          {
            widget: "SPACER",
          },
        ],
      },
      {
        className: "h-120",
        grid: 1,
        items: [
          {
            widget: "ga_mp_baseline_traffic_vs_revenue_stacked_chart",
          },
        ],
      },
      {
        className: "h-10",
        grid: 1,
        items: [
          {
            widget: "SPACER",
          },
        ],
      },
      {
        className: "h-50",
        grid: 3,
        items: [
          {
            widget: "ga_mp_sessions_from_organic_sessions",
          },
          {
            widget: "ga_mp_sessions_from_direct_sessions",
          },
          {
            widget: "ga_mp_sessions_from_organic_referal_sessions",
          },
        ],
      },
    ],
    title: "Google Analytics",
    periodPicker: {
      visible: true,
    },
    commonGranularityPicker: true,
  },
  items: [],
}

export default function useConnect() {
  const isGAMultiPropertyEnabled = useGaMultiPropertyEnabled()
  const { sources, isLoading: isSourcesDataLoading } = useSources()

  const items = isGAMultiPropertyEnabled
    ? content.content.ga_mp_items
    : content.content.ga_items

  const widgetIds = getContentWidgetIds(items as TDashboardItemsSet)
  const { visualizations } = useVisualizationsMap(widgetIds)

  const props = {
    commonGranularityPicker: content.content.commonGranularityPicker,
  }

  return {
    content: {
      ...content,
      content: {
        ...content.content,
        items,
      },
    },
    sources,
    visualizations,
    props,
    isLoading: isSourcesDataLoading,
  }
}
