import { toast } from "react-toastify"
import { useMemo } from "react"
import { isFuture } from "date-fns"

import { useLazyCompanyControllerGetCompanyByIdQuery } from "~/ui-rtk/api/companyApi"
import { useConnectorControllerGetAllConnectorsQuery } from "~/ui-rtk/api/connectorApi"
import { useConnectionLinkControllerGetConnectionLinksByCompanyQuery } from "~/ui-rtk/api/connectionLinkApi"
import {
  useCompanyConnectorControllerCreateCompanyConnectorMutation as useCreateCompanyConnectorMutation,
  useCompanyConnectorControllerGetCompanyConnectorsQuery,
} from "~/ui-rtk/api/companyConnectorApi"
import usePostHogFeatureFlags from "~/ui-rtk/hooks/posthog-feaures"
import { POSTHOG_FEATURES } from "~/ui-rtk/constants/feature-flags"
import {
  CompanySetupStatus,
  ConnectionStatus,
  ConnectorMetadata,
} from "~/ui-rtk/api/types"
import useCompanyUpdater from "~/ui-rtk/hooks/company-updater"
import type {
  TConnectorId,
  Connector,
  ConnectionLink,
} from "~/ui-rtk/constants/sources"
import { ESSENTIAL_CONNECTION_FLOW_CONNECTORS_IDS } from "~/ui-rtk/pages/Onboarding/constants"
import { mapConnectorStatus } from "~/ui-rtk/utils/connector-utils"
import useCurrentCompany from "./current-company"

export const useCurrentCompanyAsync = (companyId: string) => {
  const [queryCompanyByIdAsync] = useLazyCompanyControllerGetCompanyByIdQuery()

  if (!companyId) {
    throw new Error()
  }

  return async () => {
    try {
      const company = await queryCompanyByIdAsync({
        id: companyId,
      }).unwrap()

      if (!company) {
        throw new Error()
      }

      return company
    } catch (error) {
      throw new Error("Failed to get company")
    }
  }
}

export const connectCompanyConnector = async (id: string) => {
  const [connectAsync] = useCreateCompanyConnectorMutation()

  try {
    const { uri, companyConnectorId } = await connectAsync({
      createCompanyConnectorDto: {
        service: id,
      },
    }).unwrap()

    return { uri, companyConnectorId }
  } catch (error) {
    throw new Error("Failed to connect to the service.")
  }
}

export const useCompleteConnectionFlow = () => {
  const { updateCompany, inProgress } = useCompanyUpdater()

  const completeConnectionFlow = async () => {
    try {
      await updateCompany({
        setupStatus: CompanySetupStatus.COMPLETE,
      })
    } catch (error) {
      toast.error("Unknown issue occured", {
        toastId: "complete-connection-flow-error",
      })
    }
  }

  return {
    completeConnectionFlow,
    inProgress,
  }
}

export function useOnboardingComplete() {
  const currentCompany = useCurrentCompany()

  return currentCompany.setupStatus === CompanySetupStatus.COMPLETE
}

export function useOnboardingEnabled() {
  return usePostHogFeatureFlags(
    POSTHOG_FEATURES.ONBOARDING_CONNECTION_FLOW_ENABLED,
  )
}

export function useConnectionFlowIgnored() {
  const currentCompany = useCurrentCompany()

  return currentCompany?.metadata?.skipOnboardingFlow ?? false
}

export function useOnboardingFlowState(hasCompany = true) {
  const {
    data: connectionLinks,
    isLoading: isConnectionLinksLoading,
    refetch: refetchConnectionLinks,
  } = useConnectionLinkControllerGetConnectionLinksByCompanyQuery(undefined, {
    skip: !hasCompany,
  })

  const {
    data: dataConnectors = [],
    isError: isGetAllConnectorsError,
    isLoading: isGetAllConnectorsLoading,
    refetch: refetchDataConnectors,
  } = useConnectorControllerGetAllConnectorsQuery(undefined, {
    skip: !hasCompany,
  })

  const {
    data: connectedServices = [],
    isError: isGetCompanyConnectorsError,
    isLoading: isGetCompanyConnectorsLoading,
    refetch: refetchConnectedServices,
  } = useCompanyConnectorControllerGetCompanyConnectorsQuery(undefined, {
    skip: !hasCompany,
  })

  const isDataError = isGetAllConnectorsError || isGetCompanyConnectorsError
  const isDataLoading =
    isConnectionLinksLoading ||
    isGetAllConnectorsLoading ||
    isGetCompanyConnectorsLoading

  const refetch = () => {
    void refetchConnectionLinks()
    void refetchDataConnectors()
    void refetchConnectedServices()
  }

  return {
    connectedServices,
    dataConnectors,
    connectionLinks,
    isDataError,
    isDataLoading,
    refetch,
  }
}

export function useSkippedConnectorIds() {
  const currentCompany = useCurrentCompany()
  if (!currentCompany?.metadata?.skippedServices) {
    return []
  }
  return currentCompany.metadata.skippedServices
}

export function useAllConnectors(hasCompany = true) {
  const {
    isDataLoading,
    isDataError,
    connectedServices,
    connectionLinks,
    dataConnectors,
    refetch,
  } = useOnboardingFlowState(hasCompany)
  const skippedConnectorIds = useSkippedConnectorIds()

  const allConnectors = useMemo(
    () =>
      (dataConnectors ?? []).reduce<Record<TConnectorId, Connector>>(
        (acc, connector) => {
          if (ESSENTIAL_CONNECTION_FLOW_CONNECTORS_IDS.includes(connector.id)) {
            const connectedService = connectedServices.find(
              service => service.serviceName === connector.id,
            )

            let connectionLink: ConnectionLink | undefined
            if (connectionLinks) {
              const link = connectionLinks.find(
                ({ expiresAt, connectorId }) =>
                  isFuture(new Date(expiresAt)) && connectorId === connector.id,
              )

              if (link) {
                connectionLink = {
                  recipientEmail: link.recipientEmail,
                  createdAt: new Date(link.createdAt),
                  expiresAt: new Date(link.expiresAt),
                }
              }
            }

            const isSkipped = Boolean(
              skippedConnectorIds.includes(connector.id),
            )

            acc[connector.id] = {
              id: connector.id,
              name: connector.name,
              icon: connector.icon,
              isSkipped,
              connectionLink,
              metadata: connectedService?.metadata as ConnectorMetadata,
              status: mapConnectorStatus(connectedService?.status),
            }
          }
          return acc
        },
        {} as Record<TConnectorId, Connector>,
      ),
    [dataConnectors, connectionLinks, skippedConnectorIds, connectedServices],
  )

  return {
    isLoading: isDataLoading,
    allConnectors,
    isError: isDataError,
    refetch,
  }
}

export function useAllConnectorsSkipped() {
  const { isLoading, isError, allConnectors } = useAllConnectors()

  const isEveryConnectorSkipped = useMemo(
    () =>
      allConnectors
        ? ESSENTIAL_CONNECTION_FLOW_CONNECTORS_IDS.every(
            (connectorId: TConnectorId) => {
              const connector = allConnectors[connectorId]
              if (!connector) {
                return true
              }
              return connector.isSkipped
            },
          )
        : false,
    [allConnectors],
  )

  return {
    isLoading,
    isError,
    isEveryConnectorSkipped,
  }
}

export function useConnectorsTouched(
  connectors: TConnectorId[],
  hasCompany = true,
) {
  const { isLoading, isError, allConnectors } = useAllConnectors(hasCompany)

  const isEveryConnectorTouched = useMemo(
    () =>
      (connectors ?? []).length > 0
        ? connectors.every((connectorId: TConnectorId) => {
            const connector = allConnectors[connectorId]
            if (!connector) {
              return false
            }
            return (
              connector.status === ConnectionStatus.CONNECTED ||
              !!connector.connectionLink ||
              connector.isSkipped
            )
          })
        : false,
    [connectors],
  )

  return {
    isLoading,
    isError,
    isEveryConnectorTouched,
  }
}

export function useAllConnectorsTouched(hasCompany = true) {
  return useConnectorsTouched(
    ESSENTIAL_CONNECTION_FLOW_CONNECTORS_IDS,
    hasCompany,
  )
}
