import {
  TOTAL_CALCULATED_DATA_KEY,
  UNKNOWN_DATA_KEY,
  type AggregationType,
} from "~/ui-rtk/constants/charts"
import ComposedChart, {
  type TGraphType,
  type TGraphProps,
} from "../ComposedChart/ComposedChart"
import AreaChart from "../AreaChart"
import {
  BRAND_COLORS_MAP,
  getColorFromPaletteByIdx,
  getLightColor,
} from "~/ui-rtk/utils/chart-utils"
import { startCase } from "lodash"

type TMultyChartItemProps = {
  metrics: {
    metrics: any[]
    metricsConfig: any
  }
  groupKey: string
  state?: string
  groupStackKey?: string
  title?: string
  dataAggType?: AggregationType
  unit?: string
  pluralizeUnit?: boolean
  groupStackLabelFormat?: string
  compareMode?: boolean
  labelAggType?: "sum" | "avg" | "percent-total" | "percent-change"
  className?: string
  highlightKey?: string
  groupValue: string
  totalLabelProps?: TGraphProps
  manualTotal?: boolean
}

export function MultiChartItem({
  groupStackKey,
  metrics: metricsProp,
  groupKey: groupKeyProp,
  state = "idle",
  dataAggType,
  compareMode = false,
  highlightKey,
  groupStackLabelFormat,
  unit,
  pluralizeUnit,
  labelAggType,
  title,
  groupValue,
  totalLabelProps,
  manualTotal = false,
  ...props
}: TMultyChartItemProps) {
  const { metrics, metricsConfig } = metricsProp
  const unknownStackGroup: string[] = groupStackKey
    ? (metricsConfig.unknown[groupStackKey] ?? [])
    : []
  const stackedChartIds: string[] | null =
    groupStackKey && state === "idle" && metricsConfig[groupStackKey]
      ? metricsConfig[groupStackKey].concat(unknownStackGroup)
      : null

  return (
    <div className="ui-rtk-chart-multichart__chart">
      {groupStackKey && stackedChartIds ? (
        <ComposedChart
          metrics={metrics}
          dataAggType={dataAggType}
          compareMode={compareMode}
          highlightKey={`${groupValue}.${highlightKey}`}
          totalLabelProps={{
            ...totalLabelProps,
            dataKey: `${groupValue}.${TOTAL_CALCULATED_DATA_KEY}`,
          }}
          manualTotal={manualTotal}
          graphs={stackedChartIds.map((stackKey, idx) => {
            const isUnknown =
              metricsConfig.unknown[groupStackKey].includes(stackKey)
            const dataKey = isUnknown
              ? `${UNKNOWN_DATA_KEY}.${groupValue}.${stackKey}`
              : `${groupValue}.${stackKey}`
            const label = groupStackLabelFormat
              ? groupStackLabelFormat
                  .replace(/{stackKey}/, startCase(stackKey))
                  .replace(/{groupStackKey}/, groupKeyProp)
              : undefined

            const color = isUnknown
              ? BRAND_COLORS_MAP.UNKNOWN
              : getLightColor(getColorFromPaletteByIdx(idx))

            return {
              dataKey: metricsConfig.suffixKey
                ? `${dataKey}.${metricsConfig.suffixKey}`
                : dataKey,
              type: "area" as TGraphType,
              color,
              stackId: "default",
              unit,
              pluralizeUnit,
              label: label && (isUnknown ? `Unknown: ${label}` : label),
              labelAggType,
              hideLabels: true,
            }
          })}
          {...props}
        />
      ) : (
        <AreaChart
          title={title?.replace(
            /{groupKey}/,
            metrics?.length ? groupKeyProp : "-",
          )}
          subTitle={metrics?.length ? groupKeyProp : "-"}
          metrics={metrics.map(metric => ({
            ...metric,
            value: metric[`${groupKeyProp}.${groupKeyProp}`],
          }))}
          dataAggType={dataAggType}
          labelAggType={labelAggType === "percent-total" ? "sum" : labelAggType}
          {...props}
        />
      )}
    </div>
  )
}
