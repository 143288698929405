import React, { useState } from "react"
import { CompanyDto } from "~/ui-rtk/api/types"

import { Logo } from "~/ui-rtk/components/ui/common"
import useDate from "~/ui-rtk/hooks/date.ts"
import { Title, ProfileMenu } from "./components"
import { useStableNavigate } from "~/ui-rtk/utils/StableNavigateContext"
import { DEFAULT_LOGGED_ROUTE, guestRoutes } from "~/ui-rtk/constants/routes"

import { WarnSvg, PlusSvg } from "~/ui-rtk/components/ui/svg/essentials"
import P from "~/ui-rtk/components/ui/typography/P"
import { useAppSelector } from "~/ui-rtk/app/hooks"
import { selectUserSessionRole } from "~/ui-rtk/app/selectors/user.selector"
import { SessionRole } from "~/api/dto/auth/session-role"
import { CreateCompanyDialog } from "./components/AddCompanyDialog"
import { Button } from "~/ui-rtk/components/ui/controls"
import LastUpdatedChipStandalone from "~/ui-rtk/components/ui/common/LastUpdatedChip/LastUpdatedChip"
import { toast } from "react-toastify"
import { copyTextToClipboard } from "~/ui-rtk/utils/clipboard"
import { URL_COMPANY_ID } from "~/ui-rtk/constants/url-params"
import { LinkIcon, ShieldExclamationIcon } from "@heroicons/react/24/outline"
import useDemoMode from "~/ui-rtk/hooks/demo-mode"

type TTopbarProps = {
  onCompanyChange: (companyId: string) => void
  currentCompany: CompanyDto
}

const dayjs = useDate()

const Topbar: React.FC<TTopbarProps> = ({
  onCompanyChange,
  currentCompany,
}) => {
  const navigate = useStableNavigate()
  const { isEnabled: isDemoEnabled, toggleEnabled } = useDemoMode()

  const role = useAppSelector(selectUserSessionRole)

  const enableDemoMode = () => {
    toggleEnabled()
  }

  const [isOpen, setIsOpen] = useState(false)

  const redirectToHomePage = () => {
    void navigate(DEFAULT_LOGGED_ROUTE)
  }

  const redirectToSubscribePage = () => {
    void navigate(guestRoutes.SUBSCRIBE)
  }

  const openDialog = () => {
    if (role !== SessionRole.SUPER_ADMIN) {
      return
    }

    setIsOpen(true)
  }

  const closeDialog = () => {
    setIsOpen(false)
  }

  const copyLinkToPage = () => {
    const currentLink = new URL(window.location.href)
    currentLink.searchParams.set(URL_COMPANY_ID, currentCompany.id)
    void copyTextToClipboard(currentLink.href)
      .then(() =>
        toast.success("Copied url successfully!", {
          toastId: "clipboard-url-copy-success",
        }),
      )
      .catch(() =>
        toast.error("Failed to copy id to clipboard", {
          toastId: "clipboard-url-copy-error",
        }),
      )
  }

  const today = dayjs()

  let FreeAccessTag = null
  if (currentCompany.metadata?.freeAccessEndsAt) {
    const freeAccess = dayjs(currentCompany.metadata?.freeAccessEndsAt)

    if (freeAccess.isBefore(today)) {
      FreeAccessTag = (
        <div
          className="gap-2 justify-center py-2 px-4 cursor-pointer rounded-md bg-brand-dark border border-basic-blue flex items-center text-4 line-height-4 font-semibold leading-5"
          onClick={redirectToSubscribePage}
        >
          <WarnSvg size={24} fill="background.light" />
          <P className="font-bold text-3">
            Free Access ended {dayjs().to(freeAccess)}
          </P>
        </div>
      )
    } else {
      FreeAccessTag = (
        <div className="gap-2 justify-center py-2 px-4 rounded-md bg-basic-dark-blue border border-basic-blue flex items-center text-4 line-height-4 font-semibold leading-5">
          <WarnSvg size={20} fill="background.light" />
          <P className="font-bold text-3">
            Free Access ends {dayjs().to(freeAccess)}
          </P>
        </div>
      )
    }
  }

  let TrialTag = null
  if (
    currentCompany.subscription?.trialEndsAt &&
    dayjs().isBefore(currentCompany.subscription?.trialEndsAt)
  ) {
    TrialTag = (
      <div className="gap-2 justify-center py-2 px-4 rounded-md bg-basic-dark-blue border border-basic-blue flex items-center text-4 line-height-4 font-semibold leading-5">
        <WarnSvg size={20} fill="background.light" />
        <P className="font-bold text-3">
          Trial ends {dayjs().to(currentCompany.subscription?.trialEndsAt)}
        </P>
      </div>
    )
  }

  return (
    <div className="fixed top-0 left-0 right-0 z-[1001] flex items-center justify-between px-5 py-2 border-b border-b-basic-blue bg-basic-black">
      <div className="flex items-center gap-16">
        <Logo onClick={redirectToHomePage} cursor="pointer" />
        <div className="flex gap-2">
          <Title
            onCompanyChange={onCompanyChange}
            currentCompany={currentCompany}
          />

          {role === SessionRole.SUPER_ADMIN && (
            <React.Fragment>
              <Button
                onClick={openDialog}
                size="sm"
                variant={{ variant: "solid", color: "blue" }}
                className="rounded-md"
              >
                <PlusSvg />
              </Button>

              <CreateCompanyDialog isOpen={isOpen} onClose={closeDialog} />
            </React.Fragment>
          )}
          {role === SessionRole.SUPER_ADMIN && (
            <React.Fragment>
              <Button
                onClick={enableDemoMode}
                size="sm"
                variant={{
                  variant: "solid",
                  color: isDemoEnabled ? "red" : "blue",
                }}
                className="rounded-md text-sm"
              >
                <ShieldExclamationIcon className="size-5" />
                Demo Mode
              </Button>

              <Button
                onClick={copyLinkToPage}
                size="sm"
                variant={{
                  variant: "solid",
                  color: "blue",
                }}
                className="rounded-md text-sm"
              >
                <LinkIcon className="size-4" />
                Link to Page
              </Button>

              <CreateCompanyDialog isOpen={isOpen} onClose={closeDialog} />
            </React.Fragment>
          )}
        </div>
      </div>
      <div className="flex items-center gap-3">
        <LastUpdatedChipStandalone key={currentCompany?.id}>
          Max date available in Platform:
        </LastUpdatedChipStandalone>
        {TrialTag ? TrialTag : FreeAccessTag}
        <ProfileMenu />
      </div>
    </div>
  )
}

export default Topbar
