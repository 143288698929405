import React from "react"
import {
  ColumnOrderState,
  ColumnSort,
  HeaderGroup,
  SortingState,
} from "@tanstack/react-table"
import {
  SortableContext,
  horizontalListSortingStrategy,
} from "@dnd-kit/sortable"

import { HeadCell } from "./HeadCell"

import { TMetric } from "~/ui-rtk/constants/table-visualization-types"
import { cn } from "~/ui-rtk/utils/tailwind-utils"

type TTableHeadProps = {
  headerGroups: HeaderGroup<TMetric>[]
  sorting?: SortingState
  columnOrder: ColumnOrderState
}

export const TableHead: React.FC<TTableHeadProps> = ({
  headerGroups,
  sorting,
  columnOrder,
}) => (
  <thead className="w-full border-b border-b-basic-blue sticky top-0 z-20">
    {headerGroups.map(headerGroup => (
      <tr key={headerGroup.id}>
        <SortableContext
          items={columnOrder}
          strategy={horizontalListSortingStrategy}
        >
          {headerGroup.headers.map((header, idx) => {
            let sortingOption: "asc" | "desc" | undefined = undefined
            let sortingItem: ColumnSort
            const sortingItemIdx = (sorting ?? ([] as SortingState)).findIndex(
              sortingItem => sortingItem.id === header.column.columnDef.id,
            )
            if (sortingItemIdx >= 0) {
              sortingItem = (sorting ?? [])[sortingItemIdx]
              sortingOption = sortingItem.desc ? "desc" : "asc"
            }
            return (
              <HeadCell
                key={header.id}
                header={header}
                sorting={sortingOption}
                sortingOrder={sortingItemIdx}
                className={cn(
                  "border-s-basic-blue",
                  idx > 0 ? "border-s" : null,
                )}
              />
            )
          })}
        </SortableContext>
      </tr>
    ))}
  </thead>
)
