import { CONNECTORS_IDS } from "../constants/sources"
import { useCheckServiceIsConnected } from "./connected-services"

export default function useShopifyAccess() {
  const isConnectorConnected = useCheckServiceIsConnected(
    CONNECTORS_IDS.SHOPIFY,
  )

  return isConnectorConnected
}
