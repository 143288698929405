import React, { ReactNode, useEffect, useMemo, useState } from "react"
import qs from "qs"
import { toast } from "react-toastify"
import { Navigate, useLocation, useSearchParams } from "react-router-dom"
import useCurrentCompany from "~/ui-rtk/hooks/current-company"

import ConfirmConnectionDialog from "../ConfirmConnectionDialog"
import SuccessConnectionDialog from "../SuccessConnectionDialog"
import { H4 } from "~/ui-rtk/components/ui/typography"
import { Loader } from "~/ui-rtk/components/ui/common"
import { Topbar } from "~/ui-rtk/pages/Onboarding/ConnectSources/components"

import { useConnect } from "./connect"
import { confirmationDialogPropsMap, ConnectorTypeEnum } from "./utils"
import { useCompanyConnectorControllerCompleteCompanyConnectorOnboardingMutation } from "~/ui-rtk/api/companyConnectorApi"
import {
  isBadRequestError,
  isInternalServerError,
  isUnauthorizedError,
} from "~/ui-rtk/utils/http-utils"
import useLoadingState from "~/ui-rtk/hooks/loading-state"
import { useAppSelector } from "~/ui-rtk/app/hooks"
import SubscribeDialog from "../SubscribeDialog/SubscribeDialog"
import { selectUserSessionRole } from "~/ui-rtk/app/selectors/user.selector"
import { SessionRole } from "~/api/dto/auth/session-role"
import { CompanySetupStatus } from "~/ui-rtk/api/types"
import SourceItems from "./components/SourceItems/SourceItems"

const ConnectorsTable: React.FC = () => {
  const currentCompany = useCurrentCompany()

  const [Dialog, setDialog] = useState<ReactNode | null>(null)
  const role = useAppSelector(selectUserSessionRole)
  const isSuperAdmin = role === SessionRole.SUPER_ADMIN

  const [completeAsync, { isError: isCompleteAsyncError }] =
    useCompanyConnectorControllerCompleteCompanyConnectorOnboardingMutation()

  const [searchParams] = useSearchParams()
  const fivetranId = searchParams.get("id")
  const { setIsLoading } = useLoadingState()

  const location = useLocation()
  const params = qs.parse(location.search, { ignoreQueryPrefix: true }) as {
    state?: { companyConnectorId?: string; type?: ConnectorTypeEnum }
  }

  const [stateParam, setStateParam] = useState(params?.state ?? null)
  const [searchTerm, setSearchTerm] = useState("")
  const [activeCategory, setActiveCategory] = useState<string>("Essential")
  const [showSubscriptionDialog, setShowSubscriptionDialog] = useState(false)

  const {
    categories,
    connectorsGroup,
    connectors,
    allEssentialConnectorsEnabled,
    isLoading,
    isError,
    getCompanyConnectorByService,
    refetchConnectors,
  } = useConnect()

  const canShowSubscribeDialog = useMemo(
    () =>
      currentCompany?.metadata?.isBillingEnabled &&
      allEssentialConnectorsEnabled &&
      !isSuperAdmin &&
      !currentCompany.subscription &&
      currentCompany.setupStatus === CompanySetupStatus.INITIAL_SETUP,
    [currentCompany, allEssentialConnectorsEnabled],
  )

  const closeDialog = () => {
    setDialog(null)
  }

  const showSuccessMessage = () => {
    toast.success("Connection was successfully established!", {
      toastId: `onboarding-success-${fivetranId}`,
    })
  }

  const showErrorMessage = () => {
    toast.error("Failed to complete the onboarding", {
      toastId: `onboarding-failed-${fivetranId}`,
    })
  }

  useEffect(() => {
    if (isCompleteAsyncError) {
      void showErrorMessage()
      void refetchConnectors()
    }
  }, [isCompleteAsyncError])

  useEffect(() => {
    const completeOnboarding = async (id: string) => {
      setIsLoading(true)
      try {
        const result = await completeAsync({
          completeOnboardingDto: { fivetranId: id },
        })

        if ("data" in result) {
          void showSuccessMessage()
          await refetchConnectors()
        }
      } catch (error) {
        if (
          (isBadRequestError(error) ||
            isUnauthorizedError(error) ||
            isInternalServerError(error)) &&
          isCompleteAsyncError
        ) {
          void showErrorMessage()
          await refetchConnectors()
        }

        console.error(error)
      } finally {
        setIsLoading(false)
      }
    }

    if (fivetranId) {
      void completeOnboarding(fivetranId)
    }
  }, [fivetranId])

  const connectorsByCategory = useMemo(() => {
    const connectors = connectorsGroup[activeCategory] || []
    if (!searchTerm) {
      return connectors
    }

    return connectors.filter(c => c.name.toLowerCase().includes(searchTerm))
  }, [activeCategory, searchTerm, connectorsGroup])

  const openConfirmDialog = (id: string, service: string) => {
    const props = confirmationDialogPropsMap[service]
    if (props) {
      setDialog(() => (
        <ConfirmConnectionDialog
          isOpen
          companyConnectorId={id}
          onClose={closeDialog}
          {...props}
        />
      ))
    }
  }

  const openSuccessDialog = (companyConnectorId: string, service: string) => {
    const companyConnector = getCompanyConnectorByService(service)
    if (!companyConnector) {
      toast.error(`Oops... Seems like this service is not ready yet.`)
      return
    }

    const props = confirmationDialogPropsMap[service]
    if (props) {
      const details: Omit<typeof props, "title"> & { title?: string } = {
        ...props,
      }
      delete details.title
      setDialog(() => (
        <SuccessConnectionDialog
          title={`Your connection to ${details.label} is pending!`}
          isOpen
          onClose={closeDialog}
          companyConnectorId={companyConnectorId}
          {...details}
        />
      ))
    }
  }

  const handleSearch = (value: string) => {
    const normalizedSearchTerm = value.toLocaleLowerCase().trim()
    setSearchTerm(normalizedSearchTerm)
  }

  useEffect(() => {
    if (isError) {
      toast.error("Failed to load Data Connectors. Please, try again later", {
        toastId: "connectors-table-toast",
      })
    }
  }, [isError])

  useEffect(() => {
    if (canShowSubscribeDialog) {
      setShowSubscriptionDialog(true)
    }
  }, [canShowSubscribeDialog])

  useEffect(() => {
    const beginAction = (
      id: string,
      type: ConnectorTypeEnum = ConnectorTypeEnum.FIVETRAN,
    ) => {
      const companyConnector = connectors.find(c => c.id === id)
      if (companyConnector && type === ConnectorTypeEnum.CUSTOM) {
        void openConfirmDialog(companyConnector.id, companyConnector.service)
      } else if (companyConnector && type === ConnectorTypeEnum.FIVETRAN) {
        void openSuccessDialog(companyConnector.id, companyConnector.service)
      }

      void setStateParam(null)
      window.history.replaceState({}, "", `${location.pathname}`)
    }

    const connectorType = stateParam?.type
    const companyConnectorId = stateParam?.companyConnectorId
    if (companyConnectorId && connectors?.length) {
      void beginAction(companyConnectorId, connectorType)
    }
  }, [connectors])

  const subscriptionDialog = showSubscriptionDialog ? (
    <SubscribeDialog onClose={() => setShowSubscriptionDialog(false)} />
  ) : null

  if (isError) {
    return <Navigate to="/" />
  }

  return (
    <div className="md:px-6 md:py-8 space-y-6">
      <div className="flex items-center justify-between">
        <H4 className="font-bold text-marketing-warroom-tw-light">
          Select a connection below
        </H4>
      </div>

      <Topbar
        value={activeCategory}
        categories={categories}
        onSearch={handleSearch}
        onSelect={setActiveCategory}
      />

      {!isLoading ? (
        <SourceItems connectors={connectorsByCategory} />
      ) : (
        <Loader size="large" />
      )}
      {Dialog}
      {subscriptionDialog}
      <div className="inline-block text-base text-left text-gray-700 leading-relaxed mt-6 mb-4 border border-yellow-info rounded-lg p-3">
        <p>
          <span className="font-bold">Just a heads up: </span>
          We're working hard to process all your data as quickly as possible.
          However, each platform we connect to has limits on how much data we
          can access and how fast we can download it. This means the process can
          vary, typically taking a day or two, but sometimes up to a week for
          larger datasets.
        </p>
        <br />
        <p>
          Once you've connected your essential connectors, we'll get started on
          building your customer brand model! This process involves analyzing
          your data and may take a few days to complete. In the meantime, you
          can continue adding more connections and keywords to enhance your
          model. We'll be sure to reach out and let you know when it's ready. We
          appreciate your patience and look forward to helping you build more
          resilient baseline revenue!
        </p>
      </div>
    </div>
  )
}

export default ConnectorsTable
